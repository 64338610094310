/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { spacing, colors } from "../../config";
import { NonTranslatedText, Text } from "../../components/text/text";
import { ProfilePhoto } from "../../components/profile-photo/profile-photo";
import { BirdTutorialScreen } from "../../components/onboarding/bird-tutorial-text";
import { useHistory, useLocation } from "react-router-dom";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";
import defaultIcon2 from "../../assets/images/profile/DefaultUserIcon2.png";
import { userSelector, useStore } from "../../models";
import { useTrackScreenEvent } from "../../services";

const winImage = "./win.webp";

const PARTICIPANTCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
});

const RESULTCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-around",
    alignSelf: "stretch"
});

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: 200,
    backgroundImage: `url(${winImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
});

const BIRD = css({
    bottom: spacing[4],
});

const TITLEIMAGE = css({
    flex: 1,
    maxWidth: 278,
});

const PROFILEIMAGES = css({
    marginLeft: spacing[1],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const POINTBOXES = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
});

const POINTBOX = css({
    display: "flex",
    flexDirection: "column",
    height: 90,
    flex: 1,
    background: "rgba(0, 0, 0, 0.8)",
    borderRadius: spacing[2],
    borderWidth: 2,
    borderColor: colors.primary,
    borderStyle: "solid",
    marginLeft: spacing[4],
    marginRight: spacing[4],
});

const SELFPOINTBOX = css({
    display: "flex",
});

const USERNAME = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    borderBottomColor: colors.primary,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
});

const POINTTEXT = css({
    display: "flex",
    marginLeft: spacing[3],
});

const CURRENTPOINTSCONTAINER = css({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
});

export interface OnboardingResultProps {
    styles?: SerializedStyles;
    status: number;
    winPoints: string;
}

const point = "BATTLE_TEXT_POINT";

const ParticipantInfo = ({
    participant,
    score,
    showCrown,
}: {
    participant: { name: string, photo: string };
    score: number;
    showCrown: boolean;
}) => {
    return (
        <div css={PARTICIPANTCONTAINER}>
            <div css={PROFILEIMAGES}>
                <ProfilePhoto image={participant.photo} crownImage={showCrown} />
            </div>
            <div css={POINTBOXES}>
                <div css={[SELFPOINTBOX, POINTBOX]}>
                    <Text size={12} color={colors.yellowText} styles={USERNAME}>
                        {participant.name}
                    </Text>
                    <div css={CURRENTPOINTSCONTAINER}>
                        <NonTranslatedText size={28} color={colors.yellowText}>
                            {score}
                        </NonTranslatedText>
                        <Text size={20} color={colors.yellowText} styles={POINTTEXT}>
                            {point}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

const you = { name: "You", photo: defaultIcon1 };
const other = { name: "Opponent", photo: defaultIcon2 }

type GameResult = { score: number };
const tutorialEvent = "tutorial_complete";
const onboardingText = "ONBOARDING_VICTORY_TEXT";
const onboardingButtonText = "WALLET_BUTTON_NEXT";

export const OnboardingResult = () => {

    const history = useHistory();
    const location = useLocation<GameResult>();
    const score = location?.state?.score ?? 100;
    let otherScore = score - 1;
    if (otherScore < 0) {
        otherScore = 0;
    }

    const user = useStore(userSelector);

    const moveToGetCoinsPage = () => {
        if (user) {
            history.push("/");
        } else {
            history.push("/onboardinggetcoins");
        }
    };

    useTrackScreenEvent(tutorialEvent);

    return (
        <div css={CONTAINER}>
            <div css={TITLEIMAGE}></div>
            <div css={RESULTCONTAINER}>
                <ParticipantInfo participant={you} score={score} showCrown={true} />
                <ParticipantInfo participant={other} score={otherScore} showCrown={false} />
            </div>
            <BirdTutorialScreen
                styles={BIRD}
                onBoardingText={onboardingText}
                hasButton={true}
                buttonText={onboardingButtonText}
                onClick={moveToGetCoinsPage}
            />
        </div>
    );
};
