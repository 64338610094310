/** @jsxImportSource @emotion/react */
import PulseLoader from "react-spinners/PulseLoader";
import FadeLoader from "react-spinners/FadeLoader";

export const LoadingIndicator = () => {
    return <PulseLoader color="white" />
};

export const CircleIndicator = () => {
    return <FadeLoader color="white" />
};
