/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import ticketImage from "../../assets/images/info/Ticket.png";
import { Button } from "../button/button";
import { useStore, currencySelector } from "../../models";
import { CheckoutModal } from "../payments/checkout";
import { NonTranslatedText } from "..";
import { Image } from "../image/image";
import numeral from "numeral";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between',
    flex: 1,
    background: "rgba(0, 0, 0, 0.8)",
    alignSelf: "stretch",
    borderRadius: spacing[3],
    borderColor: colors.primary,
    borderStyle: "solid",
    borderWidth: 2,
    padding: spacing[5],
    height: 56,
    marginBottom: spacing[3],
});

const TICKET = css({
    width: 62,
    height: 46,
    marginRight: spacing[2],
});

const TICKETCONTAINER = css({
    display: "flex",
    flexDirection: "row",
});

const TICKETINFOCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
});

const TICKETTEXT = css({
    lineHeight: "150%",
    color: colors.lightText
});


export interface ShopTicketProps {
    id: string;
    ticket: number;
    amount: number;
}

export const ShopTicket = ({ ticket = 0, amount, id }: ShopTicketProps) => {
    const currency = useStore(currencySelector);
    const [showModal, setShowModal] = useState(false);

    const displayCount = numeral(ticket).format('0.00a');

    return (
        <div css={CONTAINERSTYLE}>
            <div css={TICKETCONTAINER}>
                <Image styles={TICKET} src={ticketImage} />
                <div css={TICKETINFOCONTAINER}>
                    <NonTranslatedText styles={TICKETTEXT}>
                        {displayCount}
                    </NonTranslatedText>
                </div>
            </div>
            <Button label={`${currency} ${amount}`} onPress={() => setShowModal(true)} />
            <CheckoutModal show={showModal} setShow={setShowModal} tickets={ticket} amount={amount} id={id} />
        </div>
    );
};
