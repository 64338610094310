/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { css } from '@emotion/react'
import { Screen, TextInput, Button } from '../../components';
import { spacing } from '../../config';
import backgroundImage from '../../assets/images/background.png';
import { LinkProcessState, linkStateSelector, processLinkSelector, useStore } from '../../models';
import { Redirect, useHistory } from 'react-router-dom';

const CONTAINERSTYLE = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: spacing[12],
});

const BUTTONSTYLE = css({
    marginTop: "84px"
});

const label = "Email";
const inputType = "email";
const buttonLabel = "Sign In";
const placeholderText = "Email ID";

export const FinishLoginScreen = () => {

    const processLink = useStore(processLinkSelector);
    const linkState = useStore(linkStateSelector);
    const [error, setError] = useState(null);
    const [value, setValue] = useState('');
    const history = useHistory();

    useEffect(() => {
        const fn = async () => {
            try {
                await processLink(window.location.href);
            }
            catch (ex) {
                console.log(ex);
                // show some toast here as well
                history.replace('/login');
            }
        };

        fn();
    }, [processLink, history]);

    useEffect(() => {
        if (linkState === LinkProcessState.DONE) {
            const url = window.location.href.split("?")[0];
            window.history.replaceState({}, '', url);
            history.replace('/');
        }
    }, [linkState, history]);

    const loading = linkState === LinkProcessState.PROCESSING;

    const onEmailConfirm = async () => {
        try {
            await processLink(window.location.href, value);
        } catch (ex: any) {
            console.log(ex);
            setError(ex);
        }
    };

    if (error !== null) {
        return <Redirect to='/error' />;
    }

    return (
        <Screen backgroundImage={backgroundImage} styles={CONTAINERSTYLE}>
            {linkState === LinkProcessState.EMAIL_NEEDED ? (
                <>
                    <TextInput placeholder={placeholderText} label={label} type={inputType} value={value} onChangeText={setValue} />
                    <Button styles={BUTTONSTYLE} label={buttonLabel} onPress={onEmailConfirm} disabled={loading} />
                </>
            ) : null}
            {linkState === LinkProcessState.PROCESSING ?? "Loading"}
        </Screen>
    );
}
