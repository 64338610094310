const adjectives = [
    "Supreme",
    "Proud",
    "Humorous",
    "Complex",
    "Ritzy",
    "Staking",
    "Prickly",
    "Willing",
    "Polite",
    "Level",
];

const animals = [
    "Mandrill",
    "Newt",
    "Hartebeest",
    "Stallion",
    "Aoudad",
    "Fish",
    "Lemur",
    "Muskrat",
    "Basilisk",
    "Finch",
];

export const getRandomName = () => {
    const firstName = adjectives[Math.floor(Math.random() * adjectives.length)];
    const lastName = animals[Math.floor(Math.random() * animals.length)];

    return `${firstName} ${lastName}`;
};
