export const colors = {
    primary: '#AD00FF',
    background: 'black',
    lightText: 'white',
    darkText: 'black',
    blueText: '#0283FB',
    yellowText: '#F6FD09',
    button: 'pink',
    dialogBorder: '#AF37FB',
    placeholder: '#737373',
    buttonGradient: "linear-gradient(90deg, #D253FC 0%, #7B0FFB 100%)",
    bottomNavGradient: "linear-gradient(0deg, #000000 43.52%, rgba(0, 0, 0, 0) 100%)",
    ticketBackgroundGradient: "linear-gradient(270deg, #3000B0 0%, #8405AC 100%)",
    redText: '#FF0000',
    levelGradient: "linear-gradient(90deg, #C837FB 0%, #49E7FD 100%)",
    saveButtonGradient: "linear-gradient(90deg, #FF6EED 0%, #FD496C 100%)",
    disableButtonGradient: "linear-gradient(90deg, #A6A6A6 0%, #7B7B7B 100%)",
    victoryBox: "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)",
    disableBoxGradient: 'linear-gradient(90deg, #6B6B6B 0%, #535353 100%)',
    battleUsernameGradient: 'linear-gradient(148.17deg, #082D59 37.33%, #000000 49.08%, #0B2953 61.29%)',
    whiteGradient: 'linear-gradient(270deg, #C4C4C4 85.11%, rgba(196, 196, 196, 0) 100%)',
    textBorder: "2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000, -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000"
};
