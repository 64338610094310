/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { css } from "@emotion/react";
import { TextInput } from "../input/text-input";
import { Button } from "../button/button";
import { Text } from "../../components";
import { textSizes, textWeight, spacing } from "../../config";
import {
  useStore,
  signInSelector,
  loginStateSelector,
  LoginState,
  // signInWithGoogleSelector,
  // signInWithFacebookSelector,
} from "../../models";
import { Redirect } from "react-router-dom";
// import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Logo.png";
import { VerifyOtp } from "./verify-otp";
// import { ExistingAccountError } from "../../services";
import { Image } from "../image/image";

const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const CONTAINERSTYLE = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  margin: spacing[5],
});

const BUTTONSTYLE = css({
  marginTop: 40,
});

const MANUALLOGINCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  alignSelf: "stretch",
  alignItems: "center",
});

// const SOCIALLOGINBUTTONS = css({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "flex-end",
//   marginTop: spacing[8],
// });

// const GOOGLE = css({
//   width: 250,
// });

const LOGO = css({
  width: "100%",
});

const WRAPPER = css({
  position: 'relative',
  textAlign: 'center',
  marginBottom: 100
})

const PROTOTYPE = css({
  position: 'absolute',
  bottom: 30,
  fontWeight: 800,
  color: 'white',
  textAlign: 'center',
  width: '100%'
})


const inputType = "email";
const loginTitleText = "AUTH_TITLE_SIGN_IN";
const buttonLabel = "AUTH_BUTTON_NEXT";
const placeholderText = "AUTH_PLACEHOLDER_EMAIL";
const emailSubject = "LOGIN_EMAIL_SUBJECT_TEXT";
const emailBody = "LOGIN_EMAIL_BODY_TEXT";

// const notifyError = (msg: string) => toast.error(msg);

export const SignIn = () => {
  const loginState = useStore(loginStateSelector);
  const signIn = useStore(signInSelector);
  // const signInWithGoogle = useStore(signInWithGoogleSelector);
  // const signInWithFacebook = useStore(signInWithFacebookSelector);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [otpId, setOtpId] = useState<string | null>(null);
  const { t } = useTranslation();

  const emailOtp = async () => {
    if (!validateEmail(value)) {
      return;
    }
    try {
      const id = await signIn(t(emailSubject), t(emailBody), value);
      setOtpId(id);
    } catch (ex: any) {
      setError(ex);
    }
  };

  const loading = loginState === LoginState.LOGGING_IN;
  const done = loginState === LoginState.EMAIL_SENT;
  const loggedIn = loginState === LoginState.LOGGED_IN;

  // const loginWithGoogle = async () => {
  //   try {
  //     await signInWithGoogle();
  //   } catch (ex: any) {
  //     if (ex instanceof ExistingAccountError) {
  //       notifyError(`Account already exists, sign in with ${ex.method} instead.`);
  //     } else {
  //       notifyError(ex.message);
  //     }
  //     // TODO:  show toast here for what went wrong
  //     console.log(ex);
  //   }
  // };


  // const loginWithFacebook = async () => {
  //   try {
  //     await signInWithFacebook();
  //   } catch (ex: any) {
  //     if (ex instanceof ExistingAccountError) {
  //       notifyError(`Account already exists, sign in with ${ex.method} instead.`);
  //     } else {
  //       notifyError(ex.message);
  //     }
  //     // TODO:  show toast here for what went wrong
  //     console.log(ex);
  //   }
  // };

  if (error) {
    return <Redirect to="/error" />;
  }

  if (done && otpId) {
    return <VerifyOtp id={otpId} onCancel={() => { }} />;
  }

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div css={CONTAINERSTYLE}>
      <div css={WRAPPER}>
        <Image src={logo} styles={LOGO} />
        <p css={PROTOTYPE}>prototype</p>
      </div>
      <div css={MANUALLOGINCONTAINER}>
        <Text size={textSizes.h1} weight={textWeight.bold}>
          {loginTitleText}
        </Text>
        <TextInput
          placeholder={t(placeholderText)}
          type={inputType}
          value={value}
          onChangeText={setValue}
        />
        <Button
          styles={BUTTONSTYLE}
          label={buttonLabel}
          onPress={emailOtp}
          disabled={loading}
        />
      </div>
    </div>
  );
};
