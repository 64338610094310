import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const auth = getAuth();
const storage = getStorage(getApp());

export const uploadProfileImage = async (image: File) => {
    var userId = auth.currentUser?.uid;
    if (!userId) {
        throw new Error("image upload not allowed without authentication");
    }

    const imgRef = ref(storage, `profile-image/${userId}`);
    const upload = await uploadBytes(imgRef, image);
    const url = await getDownloadURL(upload.ref);
    console.log("uploaded" + url);
    return url;
};

export const getImageUrl = async (id: string) => {
    const gsReference = ref(storage, id);
    return getDownloadURL(gsReference);
};
