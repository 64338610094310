/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../config";
import { Text } from "../text/text";
import { TicketBox } from "../cards/tournament/ticketBox";
import ticketImage from "../../assets/images/info/Ticket.png";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    width: 144,
    height: 58,
    marginLeft: spacing[3],
    marginRight: spacing[3],
    borderRadius: spacing[1],
    borderWidth: 1,
    borderColor: colors.darkText,
    borderStyle: "solid",
});

const BUTTON = css({
    display: "flex",
    flexDirection: "column",
    height: 52,
    borderRadius: spacing[1],
    alignItems: "center",
    justifyContent: "center",
});

const TICKETBOX = css({
    width: 45,
    height: 19,
    borderRadius: spacing[2],
    background: colors.darkText,
    marginLeft: spacing[3],
    color: colors.yellowText,
    paddingRight: spacing[6],
});

const TICKETIMAGE = css({
    marginLeft: -7,
    height: 22,
    width: 27
});

const TICKETTEXT = css({
    textShadow: colors.textBorder
});

const TICKETBOXTEXT = css({
    fontSize: 12,
});

const participate = "BATTLE_TICKET_TEXT";


export interface TicketButtonProps {
    styles?: SerializedStyles;
    enabled: boolean;
    cost?: number;
    onClick: () => void;
}

export const TicketButton = ({
    styles,
    enabled,
    onClick,
    cost = 0,
}: TicketButtonProps) => {
    const buttonOverride = css({
        background: enabled ? colors.buttonGradient : colors.disableButtonGradient,
    });

    const backgroundOverride = css({
        background: enabled
            ? colors.ticketBackgroundGradient
            : colors.disableBoxGradient,
    });

    let audio = new Audio("/click.mp3")

    const buttonClick = () => {
        audio.play();
        onClick();
    }

    return (
        <div css={[CONTAINERSTYLE, styles, backgroundOverride]} onClick={enabled ? buttonClick : () => { }}>
            <div css={[BUTTON, buttonOverride]}>
                <Text size={16} styles={TICKETTEXT}>
                    {participate}
                </Text>
                <TicketBox
                    ticketImage={ticketImage}
                    ticketCost={cost}
                    styles={TICKETBOX}
                    ticketImageStyle={TICKETIMAGE}
                    fontStyle={TICKETBOXTEXT}
                />
            </div>
        </div>
    );
};
