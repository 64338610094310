/** @jsxImportSource @emotion/react */
import './App.css';
import './i18n';
import { MainNavigation } from './navigation/main-navigation';
import { PaymentsProvider, Text } from './components';
import { Toaster } from 'react-hot-toast';
import { useMobileOrientation, useDeviceSelectors } from 'react-device-detect';
import { useEffect } from 'react';
import { css } from '@emotion/react';

const toastOptions = {
    duration: 5000,
    style: {
        background: 'rgba(0,0,0,0.8)',
        color: '#fff',
    },
};

const CONTAINER = css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center'
});

const ORIENTATIONERROR = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const MOBILEDEVICEERROR = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const MAINNAVIGATION = css({
    height: '100vh'
});

const deviceNotSupported = "DEVEICE_NOT_SUPPORTED_TEXT";

function App() {
    document.addEventListener('contextmenu', (event) => {
        event.preventDefault();
    });

    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isLandscape } = useMobileOrientation()

    useEffect(() => {
        const loader = document.getElementById("loading");
        if (loader) {
            loader.style.display = "none";
        }
    }, []);

    const { isMobile } = selectors;

    return (
        <PaymentsProvider>
            <Toaster
                position="bottom-center"
                toastOptions={toastOptions}
            />
            {isMobile ? <div css={CONTAINER}>
                {isLandscape ? <div id="orientationErrorDisplay" css={ORIENTATIONERROR}>
                    <Text>Rotate your phone to open the app</Text>
                </div> : <div id="mainNavigation" css={MAINNAVIGATION}>
                        <MainNavigation />
                    </div>}
            </div> : <div css={CONTAINER}>
                    <div css={MOBILEDEVICEERROR}>
                        <Text>{deviceNotSupported}</Text>
                    </div>
                </div>}
        </PaymentsProvider>
    );
}

export default App;
