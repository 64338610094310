/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Game } from "../../services";

const IFRAMESTYLE = css({
    flex: 1
});

const hasTutorial = (id: string): boolean => {
    return !!localStorage.getItem(`game-${id}`);
};

const doneTutorial = (id: string) => localStorage.setItem(`game-${id}`, "done");

interface GameResult {
    score: number,
    command: string
}

const isGameResult = (result: any): result is GameResult => {
    return (result as GameResult).command === "result";
};

export const GameContainer = ({ game, onEnd }: { game: Game, onEnd: (score: number) => void }) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const tutorialDone = hasTutorial(game.id);
    const url = tutorialDone ? game.url : `${game.url}?isTutorial=1`;

    useEffect(() => {
        const listener = (msg: MessageEvent) => {
            const data = msg.data;
            if (data) {
                try {
                    const obj = JSON.parse(data);
                    if (isGameResult(obj)) {
                        onEnd(obj.score);
                        doneTutorial(game.id);
                    }
                } catch (ex) {
                    // switch to error screen
                }
            }


        };
        window.addEventListener("message", listener);
        return () => window.removeEventListener("message", listener);
    }, [game.url, onEnd, game.id]);

    return <iframe src={url} css={IFRAMESTYLE} ref={ref} title="battle-dream" />
};
