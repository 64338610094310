/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Text } from "../../components/text/text";
import { spacing, colors } from "../../config";
import { Button } from "../../components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CONTAINERSTYLE = css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: colors.darkText,
    padding: spacing[5],
});

const BOX = css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    borderRadius: spacing[4],
    borderColor: colors.primary,
    borderWidth: 2,
    borderStyle: "solid",
    overflow: "hidden",
    marginBottom: spacing[8]
});

const TOPCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    marginTop: spacing[2],
    paddingTop: spacing[2],
    overflow: "scroll",
});

const BOTTOMCONTAINER = css({
    padding: spacing[4],
    borderTopColor: colors.primary,
    borderTopWidth: 2,
    borderTopStyle: "solid",
    marginBottom: spacing[4],
    color: colors.lightText,
});

const BUTTONCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: spacing[5],
});

const BUTTON = css({
    display: "flex",
    flex: 1,
    marginLeft: spacing[2],
    marginRight: spacing[2],
});

const TERMS = css({
    display: "flex",
    alignSelf: "stretch",
    marginTop: spacing[2],
    marginBottom: spacing[2],
    overflow: "scroll",
    paddingRight: spacing[5],
    paddingLeft: spacing[5],
    lineHeight: "130%",
    flexDirection: "column",
});

const LABELTEXT = css({
    lineHeight: 0,
    marginLeft: spacing[2],
    userSelect: 'none',
});


const TEXT = css({
    color: 'white',
});

const agree = "ONBOARDING_BUTTON_AGREE";
const termsText = "TERMS_CHECKBOX";
const privacyText = "PRIVACY_CHECKBOX";
// const termsContent = "TERMS_TEXT";
// const privacyContent = "PRIVACY_POLICY_TEXT";

export const TermsAndConditionsScreen = () => {
    const history = useHistory();
    const { t } = useTranslation()

    const [termsCheckbox, settermsCheckbox] = useState(false);
    const [privacyCheckbox, setprivacyCheckbox] = useState(false);
    const [buttonState, setbuttonState] = useState(false);

    const agreeTerms = () => {
        if (buttonState) {
            history.push("/onboardingbattle");
        }
    };

    useEffect(() => {
        setbuttonState(termsCheckbox && privacyCheckbox);
    }, [termsCheckbox, privacyCheckbox]);

    const toggleCheckboxTerms = () => {
        var terms: HTMLInputElement = document.getElementById(
            "terms"
        ) as HTMLInputElement;
        settermsCheckbox(terms.checked);
    };

    const toggleCheckboxPrivacy = () => {
        var privacy: HTMLInputElement = document.getElementById(
            "privacy"
        ) as HTMLInputElement;
        setprivacyCheckbox(privacy.checked);
    };

    return (
        <div css={CONTAINERSTYLE}>
            <div css={BOX}>
                <div css={TOPCONTAINER}>
                    <Text size={20}>Akiverse</Text>
                    <div css={TERMS}>
                        <p css={TEXT}>
                        {`Welcome to Akiverse (“AkiverseAKIVERSE”, “we”, “us”, or “our”)! These terms and conditions are entered into Vict Pte. Ltd., a Singaporean private company limited by shares, and you or the legal entity you represent (“you” or “your”).
By using the Services, whether the AKIVERSE mobile application (“AKIVERSE App”), or through the associated websites (collectively, the “Site”), application programming interfaces, or any of our other desktop or mobile applications (collectively, the “Services”), you-
a. acknowledge that you have read these terms and conditions of service, our Privacy Policy, and all obligations and rules that may be included within each Competition (as defined in these Terms) in which you participate (“Rules”) (these terms and conditions of service, the terms of any policy incorporated herein, and the Rules are collectively referred to as the “Terms”) in their entirety;
b. agree to be bound by the Terms; and
c. are authorised and able to accept these Terms. If you do not wish to be bound by the Terms, do not click “I accept” and do not register with AKIVERSE or otherwise use the Services. Declining to accept these Terms means you will be unable to participate in use of the Services.
THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION IN SECTION 17.8. YOU AGREE AND UNDERSTAND THAT IF WE CANNOT RESOLVE A DISPUTE THROUGH OUR OPERATIONS TEAM, ALL DISPUTES ARISING UNDER THESE TERMS SHALL BE SETTLED IN BINDING ARBITRATION. YOU ALSO AGREE AND UNDERSTAND THAT ENTERING INTO THIS AGREEMENT CONSTITUTES A WAIVER OF YOUR RIGHT TO A PARTICIPATION IN A CLASS ACTION LAWSUIT.
DEFINITIONS
“NFT Marketplace”: OpenSea, or an online NFT marketplace service provided by AKIVERSE.
“NFT (Digital Art)“: Art works purchased within the NFT Marketplace which was issued by AKIVERSE.
1. GENERAL USE

1.1 Changes to the Terms
We may amend, change, modify or revise the Terms at any time, and we may post a notice on the Site or when you use the Services, and you can see when these Terms were last revised by referring to the “Last Updated” legend above. Your continued use of Services means you accept any new or modified Terms. You are responsible for reviewing the Terms for any changes, so please check back here from time to time.
1.2 Eligibility
By accepting these Terms you represent and agree that you-
a. are a natural person who is at least 18 years of age or the age of majority in your jurisdiction, and who is personally assigned to the phone number or email address submitted during your registration of an Account, if you have registered an Account;
b. have the power to enter into a contract with AKIVERSE;
c. are physically located in a jurisdiction in which participation in any Service you use is permitted and unrestricted by that country or state’s laws; and
d. at all times will abide by these Terms. If any one of these requirements is not met at any time, we may suspend or close your Account with or without notice. If you believe that a child under 18 or the age of majority in your jurisdiction has gained access to the Services, please contact us at general_corp@vict.sg;. We have taken commercially reasonable steps to restrict use of Services to those who are at least years old or the age of majority in their jurisdiction. We do not sell products or services for purchase by minors. In the event that you are under the age of 18, you must have your parent or guardian’s consent to this Agreement and AKIVERSE may require them to enter into this Agreement on your behalf based on your jurisdiction.
2. ACCOUNTS

2.1 Registration
To be eligible access certain features of our Services, you may be required to register an account for Services associated with a verified identity approved in accordance with these terms (“Account”). When you create an Account, we may request certain personally identifiable information, including but not limited to your name and a legitimate email address that you control and to create a username and password. The password and username may be needed to access some of the Services. As a registered user, you can update your Account settings, including your email address, by logging into your Account inside of the AKIVERSE App or Site by navigating to “Account Settings” from the home screen. Also, if you forget either your password or username, you can visit the Site or email us for help. Please keep your username and password secret because you are responsible for all activity taken through your Account. Although we may offer a feature that allows you to “save” or “remember” your password, this feature makes it possible for third parties to access your Account, so please use that feature prudently, because such use is at your own risk. We may, in our sole discretion, reject, change, suspend and/or terminate an Account.
2.2 Your Account
You, as the holder of your Account, are solely responsible for complying with these Terms, and only you are entitled to all benefits accruing thereto. You may not allow any other person to-
i. access your Account;
ii. access Services through your Account; and
iii. accept or use prizes, rewards and other representative of value (including without limitation digital trophies or virtual goods) (collectively “Rewards”). Your Account is not transferable to any other person or account. You must immediately notify us of any unauthorized use of your password or identification or any other breach or threatened breach of our security or the security of your Account.
2.3 Personal Location Data
As a part of our Services, we may collect location information from your device. If you would like to opt out of having this information collected, you can disable location access to AKIVERSE through the Settings menu on your mobile device.
2.4 Use of Information Collected
By upgrading an Account and providing an email address and phone number, users authorize AKIVERSE to provide them with important announcements, relevant promotions, and other related communications. Users will always have the opportunity to opt out of these communications at any time, and the use of such information is governed by our Privacy Policy.
2.5 Access
To access our Services, you must have the necessary equipment (such as a smartphone or laptop) and the associated telecommunication service subscriptions to access the Internet. Our Services can be accessed directly using the AKIVERSE App or the Site. Access to our Services may become degraded or unavailable during times of significant data congestion or in the event of a network failure. This could result in the inability to access some of our Services. Although we strive to provide you with excellent service, we do not represent that the Services will be available without interruption and we do not guarantee that game matching and result submissions for Competitions (as defined below) will be successful in all circumstances. AKIVERSE shall not be liable for any losses resulting from or arising out of transaction delays or a failure of network protocols.
2.6
You are responsible for your Ethereum wallets and the private key to access it.
3. SERVICES AND SOFTWARE

3.1 General
3.1.1 Our Services
AKIVERSE permits users to interact with and participate in skill-based games, online tournaments and competitions (collectively, “Competitions”) with other AKIVERSE users via a portal. Users who have created Accounts can compete in Competitions via our Services offered from time to time. We may, with or without notice to you-:
1. modify, suspend or terminate your access to the Site, Services and/or Software for any reason without liability; and
2. interrupt the operation of the Site, Services and/or Software as necessary to perform maintenance, error correction, or other work. We may suspend and/or close the account of any user who violates, or whom we reasonably believe may be in violation of or will violate, these Terms, at any time without notice and without liability.
3.1.2 Software
If you wish to participate in Competitions or receive Services, you may be required to first download certain proprietary AKIVERSE software and/or mobile applications, including the AKIVERSE App (together with the content included therein, any associated documentation, and any application program interfaces, license keys, and patches, updates, upgrades, improvements, enhancements, fixes and revised versions of any of the foregoing, is collectively “Software”). If you do not download the Software, you may not be able to participate in Competitions or receive relevant Services. Sometimes you will download the Software directly from us, and sometimes you will download Software from a third party, such as a game developer or Apple’s App Store, but in either case your use of the Software is subject to these Terms, in addition to any other applicable terms from a third party. We license the Software to you under Section 11.2.
3.1.3 Remote Access and Updates
We choose to offer technical support for Software from time to time in our discretion. Such technical support may require that we remotely access your device on which the Software is installed (“Device”). Also, if and when we update the Software or deploy patches, updates, and modifications to AKIVERSE, we may do so through remote access of your Device without your knowledge. You hereby consent to these activities. You acknowledge that if we cannot remotely access your Device, then the Software may no longer work, and this may prevent you from participating in Competitions or otherwise receiving Services. Our access to your Device will be limited solely to-
i. providing support;
ii. updating the Software; and
iii. determining your location for regulatory purposes only, and is governed by the terms of our Privacy Policy.
3.1.4 Beta Releases
For any Service that is identified by us as a “beta” version (“Beta Service”), you acknowledge and agree that a Beta Service may contain more or fewer features than the final release of the Service. We reserve the right, in our sole discretion, not to release a final release of a Beta Service or to alter its features, specifications, capabilities, functions, licensing terms, release dates, general availability or other characteristics. Beta Services may not be suitable for production use and may contain errors affecting proper operation and functionality. You acknowledge and agree that in no event shall AKIVERSE be liable for damages, including any losses as a result of a failure of any Beta Service.
3.1.5 Third Party Sites
You may be able to access third-party websites or services via the Software, Services or the Site. We are not responsible for third-party websites, services, or content available through those third-party services. You are solely responsible for your dealings with third-parties (including advertisers and game developers). Your use of third- party software, websites or services may be subject to that third-party’s terms and conditions and privacy policy.
4. COMPLIANCE WITH LAWS

You acknowledge that various rules, regulations and laws addressing sweepstakes, contests, and tournaments with entry fees and/or prizes govern your participation in Competitions (“Gaming Laws”), and that Gaming Laws are set up by each country, state, territory, or jurisdiction.
In addition to Gaming Laws, you are also subject to all laws, rules and regulations of the city, state and country in which you reside and from which you access and use Services(together with Gaming Laws, the “Applicable Laws”). You are solely responsible for your compliance with all Applicable Laws. Access to some or all of the Competitions may not be legal for some or all residents of, or persons present in, certain jurisdictions.
SERVICES AND COMPETITIONS ARE VOID WHERE PROHIBITED OR RESTRICTED BY APPLICABLE LAWS. YOUR PARTICIPATION IN COMPETITIONS IS AT YOUR OWN RISK, AND YOU AGREE NOT TO HOLD US RESPONSIBLE OR LIABLE IF APPLICABLE LAWS RESTRICT OR PROHIBIT YOUR ACCESS OR PARTICIPATION. WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE LAWFULNESS OF YOUR PARTICIPATING IN ANY COMPETITION OR USE OF SERVICES, NOR SHALL ANY PERSON AFFILIATED, OR CLAIMING AFFILIATION, WITH US HAVE AUTHORITY TO MAKE ANY SUCH REPRESENTATIONS OR WARRANTIES.
5. REPRESENTATIONS, WARRANTIES AND RISKS

5.1 Risk of Regulatory Actions in One or More Jurisdictions
You represent that you understand that the Services may be impacted by one or more regulatory inquiries or regulatory action, which could impede or limit our ability to continue to develop or offer some or all of the Services in your jurisdiction, or which could impede or limit your ability to access or use the Services, and that you understand that we shall not be liable to you in such events except for any requirement to provide you with a refund under applicable law.
5.2 Authority; Compliance with Terms; Accuracy of Information
You represent and warrant to us that-
1. you have the right, authority, and capacity to agree to these Terms, to register for an Account, and to participate in those Competitions for which you register;
2. you will comply with these Terms when participating in Competitions, receiving Services, and/or using Software; and
3. all information you supply to us is complete, accurate and current (and knowingly submitting incomplete or inaccurate information, or failing to update and maintain current, complete and accurate information, may result, without limitation, in immediate termination of your Account and forfeiture of Rewards.
6. OUR INDEMNIFICATION OF US

6.1 Indemnification
You will, at your own cost and expense, indemnify and hold us and our directors, officers, employees and agents harmless from and against any and all claims, disputes, liabilities, judgments, settlements, actions, debts or rights of action, losses of whatever kind, and all costs and fees, including reasonable legal and attorneys’ fees, arising out of or relating to-
i. your breach of these Terms;-
ii. any use of your Account, the Site, the Software and the Services by any person including yourself;
iii. your violation of Applicable Laws;
iv. the breach or non-fulfillment of any representation and warranty or covenant made by you in these Terms;
v. your provision of false, incomplete or misleading information (including, without limitation, citizenship, residency, nationality or email address); and/or
vi. your negligence, fraud or misconduct. AKIVERSE reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of AKIVERSE. AKIVERSE will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
7. PRIVACY

7.1
We are committed to your privacy, and our Privacy Policy, the terms of which are incorporated into these Terms as if set forth in their entirety, explains the policies put in place and used by us to protect your privacy as you visit the Site, participate in Competitions, download and use the Software or otherwise use the Services. We receive, store and use all information that you submit to the Site and all information you submit in registering for and participating in Services in accordance with the Privacy Policy, so please read it carefully.
7.2
Using the Software and Services requires an Internet connection to our servers, and we may need to collect certain information from you and your Internet-enabled Device in order to make the Software and Services available to you, such as hardware system profile data, internet connection data and any other data related to the operation of the Service from any Device that logs onto the Service using your Account. We will use this information in accordance with the Privacy Policy.
7.3
By registering for an Account, you agree to allow us to publicly display your username and tournament records, and to use this information for any purpose in accordance with our Privacy Policy. By using the Services, you agree to allow us to print, publish, broadcast and use, worldwide, in any media and at any time, your name, picture, voice, likeness, and/or any biographical information that you submit to us (“Biographical Information”) for promotional, marketing or related business purposes, without compensation to you. However, we will never sell your Biographical Information without your prior written consent, and our use of your personally identifiable information is always governed by our Privacy Policy.
8. Fees and Payment

8.1 Fees and Rewards
As long as you follow these Terms and the details in the whitepaper we published (the “Whitepaper”), you will be able to play our games for free, and you may also receive AKI, a utility token, as rewards, as well as AKV, a governance token, for top ranking in our games (AKI and AKV are collectively referred to as the “Token(s)“). The Tokens are ERC-20 standard tokens, the detailed functionality of which can be found in the Whitepaper. If you purchase the Tokens from any external exchange, the Tokens are only for sophisticated purchasers who are knowledgeable and experienced in the features and risks of digital platforms, digital assets, blockchain technology and smart contracts. Potential purchasers should determine for themselves the relevance of the information contained in the Whitepaper and related materials, in each case as supplemented from time to time, and the necessity for additional enquiry, research and professional advice. These Terms should not be considered as a recommendation by any person to participate in any token sale or digital platform.
Meanwhile, you can also purchase NFTs (non-fungible tokens) in AKIVERSE issued by us directly from us or on external marketplaces such as Open Sea for a fee. The price of these NFTs will be as indicated on our sales site or on external marketplaces.
These NFTs fall into three categories: Game Center NFT, Arcade Machine NFT and Arcade Parts NFT (collectively referred to as “AKI NFTs”). Specifically, you can purchase Game Center NFT in our sales or in external marketplaces. On the other hand, as we do not sell Arcade Machine NFTs and Arcade Parts NFTs, the only way to get these is by purchasing them from external marketplaces or by using the designated mechanism within AKIVERSE such as Craft or Recycle. More information about AKI NFTs can be found in the Whitepaper.
You are fully responsible and liable for all charges, deposits and withdrawals made under your Account, including any unauthorized charges, deposits or withdrawals. The price of Services may change at any time, but no price change will affect your past purchases. You agree to pay all processing fees and any transaction costs (e.g., standard gas fees) that AKIVERSE may impose in connection with the Fees.
8.2 Billing
We may change Fees and billing procedures by updating our billing procedures with or without direct notice to you, but such changes will be reflected on the Site, the AKIVERSE App, or the billing application in effect at that time. If you do not agree to the new Fees or billing procedures, you will not be able to continue using our Services. By providing a payment method, you-
1. represent that you are authorized to use the payment method that you provided and that any payment information you provide is true and accurate;
2. authorize us to charge you for the Services using your payment method; and
3. authorize us to charge you for any paid feature of the Services that you choose to sign up for. We may bill you (a) in advance; (b) at the time of purchase; or (c) shortly after purchase, in our sole discretion as described when you access our Services. You must tell us within 60 days after an error first appears on your bill for an investigation of the charge to occur promptly. After 60 days from the first appearance of the error, we (i) will not be liable for any losses resulting from the error and (ii) will not be required to correct the error or provide a refund. If we identify a billing error, it will be corrected within 60 days. You agree to pay for all reasonable costs we incur to collect any past due amounts, including without limitation reasonable attorneys’ fees and other legal fees and costs.
8.3 Taxes
All paid prices exclude any possible duties or charges. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, “Taxes”) associated with your use of the App (including, without limitation, any Taxes that may become payable as the result of your ownership of AKI NFTs. Except for income and net-wealth taxes levied on The Company, you remain solely responsible for paying all federal, state and other taxes in accordance with all applicable laws.
8.4 Deposits
You will be able to purchase the Tokens on external exchanges and top up the Account from the Token Player’s blockchain wallet. Depositing and top up of Tokens to the Account may be subject to transaction costs (e.g., standard gas fees and credit card fees) and you agree to pay such transaction costs.
8.5 Withdrawals
You may request a withdrawal of funds in the Tokens from your available Account balance at any time. Processing of requested funds is made by refund to the payment method used to make your deposit and may take up to ninety (90) days; provided, however, that we may freeze your Account and/or delay a request for withdrawal of funds pending completion of any investigation of reported or suspected Abuse, verification of eligibility or to comply with Applicable Laws. You agree to pay all withdrawal request processing fee and any transaction costs (e.g., standard gas fees) that we may impose. When you withdraw funds from your account, you may be required to submit your identifying information. Failure to provide requested identifying information at that time may result in inability to process your withdrawal for any rewards.
8.6 AML/KYC
Pursuant to any deposits or withdrawal of funds in accordance with Sections 8.4 and 8.5, you shall provide all documentation required and requested by us to undertake and pass all due diligence, know-your-customer and anti-money laundering checks (“AML/CFT Checks”). You agree and acknowledge that each deposit or withdrawal of funds in accordance with Sections 8.4 and 8.5 is subject to the requirement that you pass all AML/CFT Checks.
8.7 Return of Funds Upon Account Closure
If you close your Account, funds in your Account will be returned subject to the terms of Section 8.5. If your Account is unilaterally closed by us or terminated for cause as allowed in these Terms, we will provide you with two (2) months’ written notice via email and your Account on the AKIVERSE App prior to the Account closure date. You shall withdraw all funds in your Account prior to the Account closure date. If you do not withdraw all funds in your Account prior to the Account closure date, any remaining funds in your Account will be subject to applicable laws regarding unclaimed monies.
8.8 Inactive Accounts
If your Account is inactive for twelve (12) consecutive months or more, we may, in our sole discretion, close your Account and any remaining funds in your Account will be subject to applicable laws regarding unclaimed monies.
8.9 Eligibility for Rewards
If you are eligible to receive rewards, we may require that you provide proof that you are, or were at the time of your participation in the subject games, eligible to participate in accordance with these Terms and that your participation was in accordance with these Terms. If you do not provide such proof to our reasonable satisfaction, then you will not receive the relevant rewards. If you receive a payment in error, we may reverse or require return of the payment. You agree to cooperate with our efforts to do this. We may also reduce payment to you without notice to adjust for any previous overpayment.
8.10 Credit Card/Simplex Use
When you pay for any charges by credit card, you represent to us that you are the authorized user of such credit card. You must promptly notify us of any changes to your credit card account number, its expiration date and/or your billing address, or if your credit card expires or is canceled for any reason. We are not liable for any loss caused by any unauthorized use of your credit card or other method of payment by a third party in connection with the Services. Any attempt to defraud through the use of credit cards or other methods of payment, regardless of the outcome, or any failure by you to honor legitimate charges or requests for payment, will result in immediate termination of your Account, forfeiture of Rewadrs, and pursuit of civil litigation and/or criminal prosecution.
8.11 Refunds
We do not grant any refund unless otherwise required by law.
9. RESTRICTIONS

By using the App and/or purchasing AKI NFTs, you confirm to not be listed, or associated with any person or entity being listed, on any of the US Department of Commerce’s Denied Persons or Entity List, the US Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, the US Department of State’s Debarred Parties List, the EU Consolidated List of Persons, or Groups and Entities Subject to EU Financial Sanctions. You further confirm not to be resident of, citizen of or located in a geographic area that is subject to UN-, US-, EU embargoes.
10. ACCEPTABLE USE POLICY

10.1 Rules of Conduct
You are personally responsible for your use of Services and Software, and while using Services and Software you must conduct yourself in a lawful and respectful manner in accordance with our rules of conduct below. We may temporarily or permanently ban users who violate these rules, or who abuse email communications, support communications, or the community purpose of any message board areas, as determined by us in our sole discretion. We reserve the right to disable a player’s ability to upload profile photos or edit their username at any time.
・Service usernames will be displayed as ‘Your Name’; impersonating other players is not allowed.
・Do not share personal information (your name, phone number, home address, and password) with other users
・Do not transmit or upload any copyrighted, or trademarked materials in messages or postings.
・Information disclosed in chat rooms, message boards, gameplay dialogue or via electronic message is revealed to the public, and we are not responsible for information you choose to disclose to others.
・You may not commit fraud with regard to any Service.
・You may not attempt to impersonate or deceive another user for the purposes of illicitly obtaining private keys, passwords, account information etc. (“scamming”).
You may not make any commercial use of any of the information provided on the Website or through the Services nor make any use of the Website or Services for the benefit of a business.
10.2 Your Content
You acknowledge that the Service is a passive conduit for user content and that we do not pre-screen user content or communications. We do not control, verify or pay for any user content or communications. We do not endorse, and specifically disclaim any responsibility or liability for, any publicly posted content. In addition, we may terminate your access to any public forums at any time, without notice, for any reason whatsoever, and/or delete, move or edit content submitted publicly, in whole or in part. You may only upload, send, and receive messages and material that is related to the subject matter of the public forums, complies with Applicable Laws, and conforms to any additional terms of service we post in the public forums. You may not upload to, distribute, or otherwise publish any content, information, or other material that-
a. violates or infringes the copyrights, patents, trademarks, service marks, trade secrets, or other proprietary rights of any person;
b. is libelous, threatening, defamatory, obscene, indecent, pornographic, or could give rise to any civil or criminal liability under U.S. or international law; or
c. includes any bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties. Submissions or opinions expressed by users are that of the individual expressing such submission or opinion and may not reflect our opinions. Subject to the foregoing, we may edit, refuse to post, or to remove any information or materials that you submit to us, in our sole discretion. You may not use a false email address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of your submissions or content. We may, but shall not be obligated to, remove or edit any of your submissions or content for any reason.
10.3 Cheating, Fraud, and Abuse
In accessing or participating in Services or using the Software, you represent and warrant to us that you will not engage in any activity that interrupts or attempts to interrupt the operation of the Services or Software. Anyone who engages in, participates in, or displays behavior that may be interpreted, in our sole discretion, as unfair methods in participating in Services or using the Software, including but not limited to, the use of unauthorized or altered software or hardware to assist play (e.g., bots, bot nets, and collusion with bots), intentionally poor play in certain games to achieve competitive advantage, collusion with other players (e.g. intentionally losing rematches in Token Competitions), money laundering, harassment of other participants, posting objectionable material, breach of these Terms, breach of security of your Account or, or any other act (whether through the use of automated technology or otherwise) that unfairly alters your chance of winning or constitutes the commission of fraud (collectively, “Abuse”), you will be subject to immediate sanction (as determined by us in our sole discretion), which may include, without limitation:-
1. immediate termination of your Account and blocking of your access to the Site and Services;
2. any Rewards that you may otherwise have been entitled to receive shall be void and forfeited; and
3. any Rewards received by you may be subject to disgorgement and/or recoupment. In addition to the foregoing, we reserve the right to disclose or report any money laundering similar illegal activity to law enforcement and regulatory authorities. Without limiting our other available remedies, we may institute or seek any injunctive relief, civil and/or criminal proceedings against you and/or any of your co-conspirators arising out of or related to your commission of Abuse, including without limitation recovering all of our fees and expenses (including reasonable attorneys’ fees) in connection with such efforts.
10.4 Hacking, Tampering, or Unauthorized Access
Any attempt to gain unauthorized access to our systems or any other user’s account, interfere with procedures or performance of Services, Software or the Website, or deliberately damage or undermine the Services or Software is subject to civil and/or criminal prosecution and will result in immediate termination of your Account and forfeiture of your Rewards. You acknowledge that we are not responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of Services or your Account.
10.5 Limitations
Except with respect to open-source software AKIVERSE has made available publicly, any use, reproduction or redistribution of the Service, Software, or related products or services not expressly authorized by these Terms is expressly prohibited. You may not engage in, or assist others to engage in, conduct that would damage or impair our property including, without limitation:-
a. copying, distributing, transmitting, displaying, performing, framing, linking, hosting, caching, reproducing, publishing, licensing, or creating derivative works from any information, software, products or services obtained from us;
b. providing unauthorized means through which others may use Services;
c. taking actions that impose an unreasonable or disproportionately large load on our or our suppliers’ network infrastructure, or that could damage, disable, overburden, or impair our Site or Services;
d. interfering with any other party’s use and enjoyment of Services and/or Software (including cheating) or the Site; and/or
e. attempting to gain unauthorized access to third party accounts, the Service, or Software.
11. PROPRIETARY RIGHTS

11.1 Your Content
Subject to these Terms, you grant to us a worldwide, perpetual, unrestricted, royalty-free license to use, copy, modify, distribute, publish, perform, transmit, and display any and all communications, materials, content and information that you submit to us, whether directly or through the Site, Services or Software (“Content”), and waive any moral rights you may have in the Content. Subject to these Terms, any communication or material you transmit to us, including any data, questions, comments, suggestions, or the like, will be treated by us as non- confidential and non-proprietary. Subject to these Terms, we may use Content for any purpose, without any compensation, accounting or other liability or obligation to you. If you use or share Content in a way that infringes others’ copyrights, trademarks, other intellectual property rights, or privacy rights, you are breaching these Terms. You represent and warrant to us that for the duration of these Terms you have (and will have) all the rights necessary for the Content you upload or share on the services and that the use of the Content, as contemplated in this Section will not violate any Applicable Laws. If your Account is cancelled or terminated, we may permanently delete your Content from our servers and we have no obligation to return Content to you except to the extent provided under applicable law.
11.2 Ownership of Services; License to Services
Excluding any open source software (as further described in Section 112.3) or third-party software that the Software or the Services incorporate, as between you and AKIVERSE, AKIVERSE owns the Software and the Services, including all technology, content and other materials used, displayed or provided in the Software or in connection with the Services (including all intellectual property rights subsisting therein), and hereby grants you a limited, revocable, non-transferable, license to access and use those portions of the Software and the Services that are proprietary to AKIVERSE.
11.3 AKIVERSE License; Open Source Software License; Limitations
Portions of the Software and the Services are governed by applicable licensing terms for the Software and the Services in these Terms (collectively, the “AKIVERSE License”). You acknowledge that the Software or the Services may use, incorporate or link to certain open-source components and that your use of the Software or Services is subject to, and you will comply with any, applicable open-source licenses that govern any such open-source components (collectively, “Open-Source Licenses”). Without limiting the generality of the foregoing, you may not:-
a. resell, lease, lend, share, distribute or otherwise permit any third party to use the Software or the Services;
b. use the Software or the Services for time-sharing or service bureau purposes; or
c. otherwise use the Software or the Services in a manner that violates the AKIVERSE License or any other Open-Source Licenses.
11.4 Trademarks
Any of AKIVERSE’s product or service names, logos, and other marks used on the Site or as a part of the Services, including AKIVERSE’s name and logo are trademarks owned by AKIVERSE, its affiliates or its applicable licensors. You may not copy, imitate or use them without AKIVERSE’s (or the applicable licensor’s) prior written consent.
11.5 Digital Assets
Some Services and Software may allow you to create digital objects, such as avatars. Such digital objects, in addition to any digital or virtual objects or assets we assign to your Account are collectively referred to as “Digital Assets”. You acknowledge that because all Digital Assets are created through the Software and/or Services, we solely and exclusively own all Digital Assets. To the extent we do not automatically own any Digital Asset, you hereby irrevocably, expressly and automatically assign to us, in perpetuity, all right, title and interest in and to any such Digital Assets, including, without limitation, all copyrights, patent rights, trade secrets, trademarks, moral rights and all other applicable proprietary and intellectual property rights throughout the world. If you have any rights to Digital Assets that cannot (as a matter of law) be assigned to us in accordance with the foregoing, you unconditionally and irrevocably:-
i. waive the enforcement of such rights against us; and
ii. grant to us an exclusive, irrevocable, perpetual, worldwide, royalty-free license (a) to reproduce, create derivative works of, distribute, publicly perform, publicly display, digitally perform and otherwise use and exploit such Digital Assets, (b) to use, make, have made, sell, offer to sell, import and otherwise exploit any product or service based on, embodying, incorporating or derived from Digital Assets, and (c) to exercise any and all other present or future rights not yet known in Digital Assets. Subject to these Terms, we grant you a limited license to use Digital Assets through your own Account solely for purposes and in furtherance of your use of Services.
12. THIRD-PARTY LINKS & ADS; OTHER USERS

The Site or Service may provide links to third-party websites and services, and/or display advertisements for third parties (collectively, “Third-Party Links & Ads”). Such Third-Party Links & Ads are not under the control of AKIVERSE, and AKIVERSE is not responsible for any Third-Party Links & Ads. AKIVERSE provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads.
13. TERM AND TERMINATION

These Terms apply to you and to us from the date that you accept them as provided above, until termination of your use of the Services (whether by deactivation, cancellation, closure, expiration or termination of your Account by you or us or otherwise). You may terminate these Terms at any time and for any reason by going to your Account webpage and following the account closure process. Upon termination of your Account, you must immediately discontinue use of the Services and the Software and your Account. Immediately upon termination of your Account, all license and rights granted to you under these Terms automatically terminate. Any and all terms and conditions within these Terms which should, by their nature, survive termination of these Terms, will survive such termination (including without limitation Sections 6-17).
14. ASSUMPTION OF RISK

You accept and acknowledge each of the following:
a. There are risks associated with using cryptocurrencies, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorised access to information stored within your wallet. You accept and acknowledge that The Company will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Ethereum network, however caused.
b. The prices of blockchain assets are very volatile. Fluctuations in the price of other digital assets could materially and adversely affect the value of your AKI NFTs, which may also be subject to significant price volatility. We cannot guarantee that any purchasers of AKI NFTs will not lose money.
c. Risk of loss of private key or passphrase(s): The company is not responsible for your private keys, which you need in order to access the AKI NFTs. It is your sole responsibility to protect your private keys. In case of lost, the company takes no responsibility.
d. The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the AKI NFTs ecosystem, and therefore the potential utility or value of AKI NFTs.
e. Upgrades by Ethereum to the Ethereum platform, a hard fork in the Ethereum platform, or a change in how transactions are confirmed on the Ethereum platform may have unintended, adverse effects on all blockchains using the ERC-721 standard, including the AKIVERSE ecosystem.
15. DISCLOSURES; DISCLAIMERS

15.1 GENERAL DISCLAIMER
TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ALL SERVICES, PRODUCTS, INFORMATION AND DATA PROVIDED OR MADE AVAILABLE BY US (INCLUDING WITHOUT LIMITATION SOFTWARE) ARE “AS IS” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON- INFRINGEMENT, AND YOU ASSUME THE ENTIRE RISK WITH RESPECT THERETO. WE MAKE NO REPRESENTATION, WARRANTY OR GUARANTEE THAT SERVICES, YOUR ACCOUNT, SOFTWARE, VIRTUAL GOODS AND/OR THE SITE WILL BE SECURE, VIRUS-FREE, UNINTERRUPTED OR ERROR-FREE, OR THAT THE SAME WILL FUNCTION PROPERLY IN COMBINATION WITH ANY THIRD-PARTY COMPONENT, TECHNOLOGY, HARDWARE, SOFTWARE, OR SYSTEM.
15.2 Disruptions and Outages
We strive to keep Services up and running; however, all online services suffer occasional disruptions and outages, we are not responsible or liable for any disruption or loss you may suffer as a result. You should regularly backup content that you store on the Services.
16. LIMITATIONS OF LIABILITY

TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, NEITHER WE, NOR OUR SUPPLIERS OR LICENSORS, WILL BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST DATA OR LOSS OF GOODWILL), OR INCIDENTAL DAMAGES, ARISING OUT OF OR RELATING TO THESE TERMS, THE WEBSITE, OR ANY INFORMATION, SERVICES, PRODUCTS OR SOFTWARE MADE AVAILABLE OR ACCESSIBLE TO YOU, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF WE OR OUR THIRD PARTY SUPPLIERS OR LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITY.
TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, OUR MAXIMUM LIABILITY TO YOU ARISING OUT OF OR IN ANY WAY CONNECTED TO THESE TERMS SHALL NOT EXCEED SGD$50.00. THE EXISTENCE OF ONE OR MORE CLAIMS BY YOU WILL NOT INCREASE OUR LIABILITY. IN NO EVENT SHALL OUR SUPPLIERS OR LICENSORS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO OUR PRODUCTS, INFORMATION OR SERVICES.
Certain jurisdictions do not allow limitations of liability for incidental, consequential or certain other types of damages; as such, the limitations and exclusions set forth in this Section may not apply to you.
17. MISCELLANEOUS

17.1 Entire Agreement
These Terms constitute the entire agreement between you and us pertaining to the subject matter hereof and supersede all prior or other arrangements, understandings, negotiations and discussions, whether oral or written. Our failure to require or enforce strict performance by you of any provision of these Terms or to exercise any right under them shall not be construed as a waiver or relinquishment of our right to assert or rely upon any such provision or right in that or any other instance. Section titles in these Terms are for reference only and have no legal effect.
17.2 Severability
The provisions of these Terms are intended to be severable. If for any reason any provision of these Terms shall be held invalid or unenforceable in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the Terms, which shall continue to be in full force and effect.
17.3 Amendment and Modification
These Terms cannot be modified by you and may only be modified by us as provided above.
17.4 Remedies
No right or remedy of ours shall be exclusive of any other, whether at law or in equity, including without limitation damages injunctive relief, attorneys’ fees and expenses.
17.5 Assignability
We may assign these Terms or legal status, rights and obligations based on them, in whole or in part, at any time without notice to you. You may not assign these Terms or transfer any rights to use the Services.
17.6 Successors and Assigns
These Terms are solely for your and our benefit, and not for the benefit of any other person, except for our successors and assigns.
17.7 Governing Law
These Terms are governed by and must be construed in accordance with the law of Singapore.
17.8 Jurisdiction
Any dispute, controversy, difference or claim arising out of or relating to these Terms, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to these Terms will be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) under the SIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The law of this clause is Singapore law. The seat of arbitration will be Singapore. The number of arbitrators will be one and that arbitrator must have relevant legal and technological expertise. If the parties do not agree on the arbitrator to be appointed within 15 days of the dispute proceeding to arbitration, the arbitrator is to be appointed by SIAC. The arbitration proceedings shall be conducted in English. Notwithstanding any other clause in these Terms, the Purchaser agrees the Company has the right to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.
A printed version of these Terms and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
Please send any questions or comments (including all inquiries unrelated to copyright infringement) to: general_corp@vict.sg . Please print these Terms for your records.`}

                        </p>
                        <p css={TEXT}>
                        {`This Privacy Policy (the “Privacy Policy”) sets forth VICT PTE. LTD (“We,” “Us,” or “Our”) privacy practices in accordance with the Personal Data Protection Act 2012 (the “PDPA”) and the possible uses of the Personal Data (as defined below) that we gather from You (“You,” or “Your”) pertaining to the use of the “AKIVERSE” platform service [akiverse.game] , distributing, or planning to distribute web games and video games and the website for the NFT Marketplace for the digital art auction (collectively, the “Personal Data”), as well as any other media forms, media channels, mobile websites or mobile applications related, linked, or otherwise connected to this game and NFT Marketplace (collectively, the “Services”).

Please read the entirety of this Privacy Policy carefully before using the Services. By using or accessing any of our Services through any channel or method, and/or by accepting the Terms of Use, You agree and consent to the use, collection, disclosure and other processing of Your Personal Data by us under this Privacy Policy. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT USE OUR SERVICES OR ACCESS OUR GAMES OR WEBSITES.

The Services may contain links to or information about third party websites that are not within our control. We are not responsible for the privacy practices of any external website or mobile application.

We may engage third-party service providers to assist with the provision of the Services. Accordingly, Your Personal Data may also be collected, used and processed by our third party service providers. This Privacy Policy therefore applies to Your Personal Data collected directly by these service providers in connection with Services. However, this Privacy Policy does not apply to Personal Data collected by third party vendors for their own purposes that is not also shared with us. For example, if You submit Personal Data to create an account to access a website operated by the service provider, Your Personal Data will be subject to the privacy policy of that particular service provider and not this Privacy Policy.

B. COLLECTION OF PERSONAL DATA
"Personal Data" or "Personal Data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access.

We may collect and receive information from You when You register for an Account with us, when You use the features and functions on our Services, when You connect Your Account to an external third party service/application or when You contact us. The following information may be collected and processed:

・Name
・Gender
・Date of birth
・Address
・Email address
・Phone number
・Transaction information (e.g. credit or debit card details, bank account information)
・Account information, such as username, password, and other identifiers or credentials used to access our Services
・Username and password
・Any other information required by us as determined in our sole discretion.
・Any information provided by You when contact the Company or otherwise share with the Company before, during or after the termination of Your Account.
Please note that in using our Services we may also automatically collect the following information from You:

・Your Internet Protocol (“IP”) address;
・Your locations;
・information about Your browsing activity such as Your browser type, Internet service provider, referring/exit pages, the files viewed on the Site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream datal
・device information (such as hardware model and serial number); and
・any communications between You and another user of our Services
Please note that we may also may collect information through the use of cookies. If you do not wish for your Personal Data to be collected via cookies on the Websites, you may adjust your internet browser settings to disable, block or deactivate cookies, by deleting your browsing history and clearing the cache from your internet browser.

In the event that You provide information relating to a third party to us, You represent and warrant that You have validly obtained the consent, license and permission of the third party to share and transfer his/her Personal Data to us, and for us to collect, store, use and disclose that data in accordance with this Privacy Policy.

C. USE OF PERSONAL DATA
We may use Your Personal Data for any and all of the following purposes:

・to verify Your identity, update our records and generally maintain Your Account with us;
・to facilitate, confirm and/or provide updates or notifications in relation to any transactions;
・to verify and process transactions (including payment, collecting and managing the proceeds of the Services) and to arrange for delivery of items;
・to contact You in cases of emergency;
・to carry out additional services for billing and settlement for the provision of the Services;
・to outsource some operations including the provision of the Services and the collection of Service fees;
・to update and inform You on new products, features, services and after-sales follow-up;
・to update and inform You on marketing and promotional activities - if You wish to unsubscribe to the processing of Your Personal Data for marketing and promotions, please click on the unsubscribe link in the relevant email or message;
・to conduct polls, surveys, analysis and research on our Services to improve and personalize our Services;
・to notify You on updates or changes to this Privacy Policy or the Terms of Use, or any aspect of the Service (including to inform You of any additional terms and conditions applicable to the Service);
・to respond to, handle, and process queries, requests, applications, complaints, and feedback from You;
・to protect and ensure the technical functionality, security and integrity of the Services including conducting data analysis, testing, monitoring and analyzing usage and activities and performing network or service enhancement;
・for accounting, risk management and record keeping purposes;;
・for internal business purposes, including marketing, sales promotion, research, analysis, testing and auditing;
・to comply with applicable legal obligations and other rules, regulations, codes of practice and orders and directions of the relevant governmental authorities;
・to prevent, detect, investigate or monitor any frauds, threats, misconduct or other prohibited content, businesses or activities;;
・to process or resolve any disputes arising from or in connection with our Services;
・to protect our rights and property and the rights, property and safety of others;
・any other purposes which we notify You of at the time of obtaining Your consent.
・transmitting to any unaffiliated third parties including our third party service providers and agents, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes; and
・any other incidental business purposes related to or in connection with the above.
(collectively, the “Purposes”);
The Purposes listed may continue to apply even in situations where Your relationship with us has been terminated or altered in any way, for a reasonable period thereafter (including, where applicable, a period to enable us to enforce our rights under any contract with You).

We may use and disclose automatically collected information (including aggregated information), which is not Personal Data, for any purpose, except where we are restricted by applicable law. If we combine any automatically collected information with Personal Data or other information which may be used to identify an individual, the combined information will be treated by us as Personal Data in accordance with this Privacy Policy.

D. DISCLOSURE OF PERSONAL DATA
We may share and permit the sharing of Your Personal Data with third parties and our affiliates for any of the above mentioned Purposes. In sharing Your Personal Data with them, we endeavour to ensure that the third parties and our affiliates keep Your Personal Data secure from unauthorised access, collection, use, disclosure, processing or similar risks and retain Your Personal Data only for as long as they need Your Personal Data to achieve the above mentioned purposes.

We may share your Personal Data with the following categories of third parties:

・Our business partners, service providers, professional advisers, vendors and agents who assist us in performing certain aspects of our Services (including processing transactions, carrying out compliance check, data storage, management and analysis, maintenance of the Services, legal, audit, insurance and marketing services). These parties will only have access to Your Personal Data to the extent necessary to perform their functions.
・Our potential investors, purchasers and commercial counterparties. We may share Your Personal Data with any person who wishes to invest in Company or whom we intend to enter into a business combination with (such as a purchase, sale, lease, merger or amalgamation, or any other acquisition, disposal or financing).
・Any third party claimants or potential third party claimants.
・Financial institutions, your card issuing bank or payment service provider in respect of any issues arising from or in connection with a transaction through our Services.
・Governmental and regulatory authorities: We may share Your Personal Data with competent governmental and regulatory agencies in Singapore or other countries, including investigating officers and judicial authorities in the relevant jurisdictions.
We may also share Your Personal Data with other third parties if You have separately consented to such disclosure. In such a case, any information which we share with such third parties shall be used for the purposes described when we obtained Your consent.

We may disclose Your Personal Data if required to do so by law or if we believe that such action is necessary to prevent fraud or crime or to protect our Services or the rights, property or personal safety of any person. We may be prevented from obtaining Your consent for, or informing You of, such disclosure. Any information which we share in this circumstance shall be used for the purposes required or permitted under the applicable laws or regulations

E. TRANSFER OF PERSONAL DATA
Your Personal Data may be transferred to other countries for processing, and by using any part of the Services, You consent to the transfer, use and processing of Your Personal Data to countries outside of Singapore which may have different Personal Data protection rules than in Singapore. However, the level of protection that will be applied to the transferred Personal Data will be at least comparable to the protection provided under this Privacy Policy and the PDPA and its regulations.

F. RETENTION OF PERSONAL DATA
We may retain Your Personal Data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We will cease to retain Your Personal Data, or remove the means by which the data can be associated with You, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Personal Data was collected, and is no longer necessary for legal or business purposes.

G. PROTECTION OF PERSONAL DATA
All information collected in connection with any of our Services (including information generated by cookies about Your use of the Websites) will be stored electronically on the server of our service provider in Singapore with appropriate safeguards and security measures. However, there is no guarantee that information stored by us or on our behalf may not be accessed, collected, used, disclosed, copied, modified or deleted by unauthorised parties by breach of any technical or operational safeguards in place. We will not be keeping physical files of any Personal Data.

No data transmitted via the internet can be guaranteed to be wholly secure during transmission. As such, we cannot warrant the security of any information You transmit to us or which we transmit to You before its receipt and any transmission is at Your own risk. It is important that You protect against unauthorised access of Your Account and information by choosing Your password carefully, and keeping Your password and Account secure (e.g. by signing out after using our Services).

You are at all times responsible for Your own internet privacy settings. We are not responsible for the functionality, privacy or security measures of any other organisation or third-party websites, applications or services.

H. WITHDRAWAL OF CONSENT
The consent that You provide for the collection, use and disclosure of Your Personal Data will remain valid until such time it is being withdrawn by You in writing. You may withdraw consent and request us to stop using and/or disclosing Your Personal Data for any or all of the purposes listed above by submitting Your request in writing or via email to our Data Protection Officer at the contact details provided below. However, please note that any withdrawal of Your consent may have consequences, including Your inability to use the Services.

Upon receipt of Your written request to withdraw Your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with You) for Your request to be processed and for us to notify You of the consequences of us acceding to the same, including any legal consequences which may affect Your rights and liabilities to us. In general, we shall seek to process Your request within ten (10) business days of receiving it.

Whilst we respect Your decision to withdraw Your consent, please note that depending on the nature and scope of Your request, we may not be in a position to continue providing our goods or services to You and we shall, in such circumstances, notify You before completing the processing of Your request. Should You decide to cancel Your withdrawal of consent, please inform us in writing in the manner described above.

Please note that withdrawing consent does not affect our right to continue to collect, use and disclose Personal Data where such collection, use and disclosure without consent is permitted or required under applicable laws.

I. ACCESS TO AND CORRECTION OF PERSONAL DATA
You can manage available communication preferences when You access the Website by updating Your account preferences, or, where applicable, by using the “opt-out” or unsubscribe mechanism or other means provided within the communications that You receive from us.

Subject to exceptions under applicable law and regulations, You may request access to Your Personal Data that we collected in connection with the Services and information about how we have used and disclosed Your Personal Data within a year before Your request. Subject to exceptions under applicable law and regulations, You may also request to review, correct or delete any of that Personal Data. Additional rights may also apply under applicable laws. We reserve the right to charge a nominal fee in respect of our costs in processing Your request for access to Personal Data.

If You wish to make (a) an access request for access to a copy of the Personal Data which we hold about You or information about the ways in which we use or disclose Your Personal Data, or (b) a correction request to correct or update any of Your Personal Data which we hold about You, You may submit Your request in writing or via email to our Data Protection Officer at the contact details provided below. In order to facilitate processing of Your request, it may be necessary for us to request further information relating to Your request. To protect Your privacy and security, we will take reasonable steps to verify Your identity before processing any access or correction request or withdrawal notice under this section.

Please note that a reasonable fee may be charged for an access request. If so, we will inform You of the fee before processing Your request.

We will respond to Your request as soon as reasonably possible. Should we not be able to respond to Your request within thirty (30) days after receiving Your request, we will inform You in writing within thirty (30) days of the time by which we will be able to respond to Your request. If we are unable to provide You with any Personal Data or to make a correction requested by You, we shall generally inform You of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).

J. UPDATING YOUR PERSONAL DATA
We generally rely on Personal Data provided by You (or Your authorised representative). It is important that the Personal Data You provide to us is accurate, complete and up to date. In order to ensure that Your Personal Data is complete, accurate and up to date, please update us if there are changes to Your Personal Data by informing our Data Protection Officer in writing or via email at the contact details provided below. In the event You believe that the Personal Data we have about You is inaccurate, incomplete, misleading or out of date, please also inform us.

K. AMENDMENTS AND UPDATES TO THIS PRIVACY POLICY
We reserve the right to update, modify, delete or otherwise make changes to this Privacy Policy at any time. Any changes to this Privacy Policy will be posted to the Website. A notification may also be sent to Your email address.

Any changes to this Privacy Policy will take effect and be binding on You from the time of posting the changes to the Website. If You continue to use in any Service following any changes to this Privacy Policy, You will be deemed to have accepted the changes and the updated version of this Privacy Policy.

If You do not agree with the changes, You are not allowed to use any part of our Services. Please ensure that You refer to this Privacy Policy on a regular basis to check for any changes. It is Your responsibility to regularly review this Privacy Policy (and the applicable conditions).

If any provision of this Privacy Policy shall be held to be invalid, illegal or unenforceable for any reason whatsoever, the validity, illegality and enforceability of the remaining provision of this Privacy Policy shall not in any way be affected or impaired thereby.

L. CONTACT INFORMATION
You may contact our Data Protection Officer if You have any enquiries or feedback on our Personal Data protection policies and procedures, or if You wish to make any request, in the following manner:

VICT PTE. LTD.
Email: general_corp@vict.sg
FORMULATED ON: 2021.9.29
LAST UPDATED ON: 2021.11.15`}
</p>
                    </div>
                </div>
                <div css={BOTTOMCONTAINER}>
                    <label>
                        <input
                            type="checkbox"
                            id="terms"
                            name="termsCheckbox"
                            value="terms"
                            onChange={toggleCheckboxTerms}
                        />
                        <span css={LABELTEXT}>{t(termsText)}</span>
                    </label>
                    <br />
                    <br />
                    <label>
                        <input
                            type="checkbox"
                            id="privacy"
                            name="privacyCheckbox"
                            value="privacy"
                            onChange={toggleCheckboxPrivacy}
                        />
                        <span css={LABELTEXT}>{t(privacyText)}</span>
                    </label>
                    <div css={BUTTONCONTAINER}>
                        <Button styles={BUTTON} label={agree} onPress={agreeTerms} disabled={!buttonState}></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
