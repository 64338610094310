/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import maskImage from "../../assets/images/hex1.png";
import profileImage from "../../assets/images/profileImage.jpg";
import crownGold from "../../assets/images/CrownGold.png";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";
import defaultIcon2 from "../../assets/images/profile/DefaultUserIcon2.png";
import { spacing } from "../../config";
import { PROFILE_IMAGES } from "../../models";
import { Image } from "../image/image";

const CONTAINERSTYLE = css({
    display: "flex",
    width: 105,
    height: 105,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
});

const MASKEDPROFILEPICTURE = css({
    backgroundImage: `url(${profileImage})`,
    backgroundSize: "cover",
    maskImage: `url(${maskImage})`,
    maskSize: "100%",
    maskRepeat: "no-repeat",
    maskPosition: "center",
    display: "flex",
    width: 105,
    height: 105,
    margin: spacing[1],
});

const CROWNIMAGE = css({
    position: "absolute",
    width: 25,
    height: "auto",
    left: 10,
    top: 0,
    transform: "rotate(-30deg)",
});

export interface ProfilePhotoProps {
    image?: string;
    styles?: SerializedStyles[];
    crownImage?: boolean;
    profileImageBackground?: string;
    onPress?: () => void;
}

const getImage = (src?: string) => {
    if (src === PROFILE_IMAGES.PROFILE1) {
        return defaultIcon1;
    }
    if (src === PROFILE_IMAGES.PROFILE2) {
        return defaultIcon2;
    }
    return src;
};

export const ProfilePhoto = ({
    image,
    styles,
    crownImage,
    profileImageBackground,
    onPress,
}: ProfilePhotoProps) => {
    if (!image || image === "") {
        image = PROFILE_IMAGES.PROFILE1
    }
    const profileImage = css({
        backgroundImage: `url(${getImage(image)})`,
    });

    const profileImageBackgroundImage = css({
        backgroundImage: profileImageBackground
            ? `url(${profileImageBackground})`
            : `url(${maskImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: spacing[2],
        paddingBottom: spacing[2]
    });

    return (
        <div
            css={[CONTAINERSTYLE, styles, profileImageBackgroundImage]}
            onClick={onPress}
        >
            <div css={[MASKEDPROFILEPICTURE, profileImage]}></div>
            {crownImage ? (
                <Image src={crownGold} styles={CROWNIMAGE} />
            ) : null}
        </div>
    );
};
