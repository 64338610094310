import { GetState, SetState } from "zustand";
import { fetchBundle, Battle, ShopItem, BattleType, Game, currency } from "../services";
import { AppStore } from './appstore';
import { DateTime } from 'luxon';

export interface InfoState {
    infoLoading: boolean
    infoError: Error | null
    battles: Battle[]
    shopItems: ShopItem[]
    games: Game[]
    refreshBattles: () => void
    fetchInfo: () => Promise<void>
    currency: string
}

export const fetchInfoSelector = (state: InfoState) => state.fetchInfo;
export const battlesSelector = (state: InfoState) => state.battles;
export const infoLoadingSelector = (state: InfoState) => state.infoLoading;
export const infoErrorSelector = (state: InfoState) => state.infoError;
export const shopListSelector = (state: InfoState) => state.shopItems;
export const gamesSelector = (state: InfoState) => state.games;
export const currencySelector = (state: InfoState) => state.currency;
export const refreshBattleSelector = (state: InfoState) => state.refreshBattles;

const parseTime = (time: number) => {
    return DateTime.utc().set({ hour: time / 100, minute: time % 100 });
};

const sortBattles = (battles: Battle[]) => {
    const current = DateTime.utc();

    const freeItems = battles.filter(b => b.battleType === BattleType.Practise);
    let costItems = battles.filter(b => b.battleType === BattleType.Normal);

    const enabledItemIndex = costItems.findIndex(b => {
        const startTime = parseTime(b.startTime);
        const endTime = parseTime(b.endTime);
        return current >= startTime && current < endTime;
    });

    if (enabledItemIndex > -1) {
        costItems = costItems.splice(enabledItemIndex).concat(costItems);
    }

    return [...freeItems, ...costItems];
};

export const createInfoState = (set: SetState<AppStore>, get: GetState<AppStore>): InfoState => {
    return {
        infoLoading: true,
        infoError: null,
        battles: [],
        games: [],
        shopItems: [],
        currency,
        refreshBattles: () => {
            const { battles } = get();
            set({ battles: sortBattles(battles) })
        },
        fetchInfo: async () => {
            try {
                set({ infoLoading: true });
                const { battles, shopItems, games } = await fetchBundle();
                set({ battles: sortBattles(battles), shopItems, games, infoLoading: false });
            }
            catch (ex: any) {
                set({ infoError: ex, infoLoading: false });
                throw ex;
            }
        }
    };
};
