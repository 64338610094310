import { GetState, SetState } from "zustand";
import { fetchWallet, paypalLinked, trackEvent, transferCoins, unlinkPaypal } from "../services";
import { AppStore } from './appstore';

const minAmount = 100;

export interface WalletState {
    walletLoading: boolean
    walletError: Error | null
    currentLevel: number
    currentCoins: number
    currentTickets: number
    linked: boolean,
    fetchWallet: () => Promise<void>
    refreshWallet: () => Promise<void>
    linkWallet: (code: string) => Promise<void>
    unlinkWallet: () => Promise<void>
    transferMoney: (amount: number) => Promise<void>
}

export const levelSelector = (state: WalletState) => state.currentLevel;
export const coinSelector = (state: WalletState) => state.currentCoins;
export const ticketSelector = (state: WalletState) => state.currentTickets;
export const walletLoadingSelector = (state: WalletState) => state.walletLoading;
export const walletErrorSelector = (state: WalletState) => state.walletError;
export const fetchWalletSelector = (state: WalletState) => state.fetchWallet;
export const refreshWalletSelector = (state: WalletState) => state.refreshWallet;
export const linkWalletSelector = (state: WalletState) => state.linkWallet;
export const unlinkWalletSelector = (state: WalletState) => state.unlinkWallet;
export const linkedSelector = (state: WalletState) => state.linked;
export const withdrawSelector = (state: WalletState) => state.transferMoney;

export const createWalletState = (set: SetState<AppStore>, get: GetState<AppStore>): WalletState => {
    return {
        walletLoading: true,
        walletError: null,
        currentLevel: 0,
        currentCoins: 0,
        currentTickets: 0,
        linked: false,
        refreshWallet: async () => {
            const wallet = await fetchWallet();
            set({
                linked: wallet.linked,
                currentLevel: wallet.level,
                currentCoins: wallet.coins,
                currentTickets: wallet.tickets,
                walletLoading: false
            });
        },
        fetchWallet: async () => {
            set({ walletLoading: true });
            try {
                const wallet = await fetchWallet();
                set({
                    currentLevel: wallet.level,
                    currentCoins: wallet.coins,
                    currentTickets: wallet.tickets,
                    linked: wallet.linked,
                    walletLoading: false
                });
            } catch (ex: unknown) {
                set({ walletLoading: false });
                if (ex instanceof Error) {
                    set({ walletError: ex });
                }
                throw ex;
            }
        },

        unlinkWallet: async () => {
            try {
                await unlinkPaypal();
                set({ linked: false });
                trackEvent("wallet_unlinked");
            } catch (ex) {
                throw ex;
            }
        },

        linkWallet: async (code: string) => {
            try {
                await paypalLinked(code);
                set({ linked: true });
                trackEvent("wallet_linked");
            } catch (ex) {
                throw ex;
            }
        },

        transferMoney: async (amount: number) => {
            const { currentCoins } = get();
            if (currentCoins < amount || amount < minAmount) {
                throw Error("Insufficient amount, or amount too low!");
            }
            try {
                await transferCoins(amount);
                const wallet = await fetchWallet();
                set({
                    linked: wallet.linked,
                    currentLevel: wallet.level,
                    currentCoins: wallet.coins,
                    currentTickets: wallet.tickets,
                    walletLoading: false
                });
                trackEvent("spend_virtual_currency", { value: amount, virtual_currency_name: "coins" });
            } catch (ex) {
                throw ex;
            }
        }
    }
};
