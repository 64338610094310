/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { ProfilePhoto } from "../profile-photo/profile-photo";
import selectedFrame from "../../assets/images/profile/SelectedFrame.png";
import photoPromptIcon from "../../assets/images/profile/PhotoImportIcon.png";
import { colors, spacing } from "../../config";
import { fetchProfileImagesSelector, profileImagesSelector, PROFILE_IMAGES, useStore } from "../../models";

const CONTAINER = css({
  position: 'relative'
});

const DOT = css({
  height: spacing[6],
  width: spacing[6],
  backgroundColor: colors.redText,
  borderRadius: 50,
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.lightText
});

export interface PhotoPickerProps {
  selectedImage: string | File
  onSelect: (n: string | File) => void
}

export const PhotoPicker = ({
  onSelect,
  selectedImage
}: PhotoPickerProps) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const profileImage = useStore(profileImagesSelector);
  const fetchProfileImage = useStore(fetchProfileImagesSelector);
  const [image, setImage] = useState<string | File>("");
  const [displayImage, setDisplayImage] = useState<string>("");

  const processImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      return;
    }
    setImage(file);
    onSelect(file);
  };

  useEffect(() => {
    fetchProfileImage();
  }, [fetchProfileImage]);

  useEffect(() => {
    setImage(profileImage);
  }, [profileImage]);

  useEffect(() => {
    if (typeof image === "string") {
      setDisplayImage(image);
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setDisplayImage(reader.result as string);
      }, false);

      reader.readAsDataURL(image);
    }
  }, [image]);

  const isFile = image instanceof File;

  const onCancel = () => {
    if (selectedImage === image) {
      onSelect(PROFILE_IMAGES.PROFILE1);
    }
    setImage("");
  };

  return (
    <div onClick={() => !isFile && image === "" && inputRef.current?.click()} css={CONTAINER}>
      <ProfilePhoto image={displayImage === "" || !displayImage ? photoPromptIcon : displayImage} profileImageBackground={selectedImage === image ? selectedFrame : ""} onPress={() => onSelect(image)} />
      {isFile || image !== "" ? <div css={DOT} onClick={onCancel}>x</div> : null}
      <input hidden ref={inputRef} type="file" onChange={processImage} accept="image/png, image/jpeg" />
    </div>
  );
};
