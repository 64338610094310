/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Text } from "../../components/text/text";
import { spacing, colors } from "../../config";
import { Button } from "../../components";
import { useHistory, useLocation } from 'react-router-dom';

const CONTAINERSTYLE = css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing[10],
});

const BUTTON = css({
    position: "absolute",
    bottom: 110,
});

const BUTTONTEXT = "Go Back";

export interface ErrorScreenProps {
    errorMessage?: string;
}

type ErrorScreenState = { error: string, hideBack?: boolean };

export const ErrorScreen = ({ errorMessage = "" }: ErrorScreenProps) => {
    const history = useHistory();
    const location = useLocation<ErrorScreenState>();

    const msg = location?.state?.error ?? errorMessage;

    return (
        <div css={CONTAINERSTYLE}>
            <Text color={colors.redText} size={20}>
                {msg}
            </Text>
            {!location?.state?.hideBack ? <Button
                label={BUTTONTEXT}
                styles={BUTTON}
                onPress={() => history.goBack()}
            /> : null}
        </div>
    );
};
