/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../../config";
import ticketImage from "../../../assets/images/info/Ticket.png";
import ticketBackgroundImage from "../../../assets/images/TicketTournament.png";
import { TimeBox } from "./timeBox";
import { Members } from "./members";
import { WinnerReward } from "./WinnerReward";
import { TicketBox } from "./ticketBox";
import { Text } from "../../text/text";

const CONTAINER = css({
    height: 69,
    borderRadius: 4,
    flex: 1,
});

const BACKGROUNDCONTAINER = css({
    background: colors.ticketBackgroundGradient,
    flex: 1,
    height: "100%",
    width: "100%",
    borderRadius: 4,
});

const TICKETCONTAINER = css({
    height: 63,
    background: colors.buttonGradient,
    borderRadius: 4,
});

const TICKETBACKGROUNDCONTAINER = css({
    height: "100%",
    width: "100%",
    backgroundImage: `url(${ticketBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50px",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    color: colors.lightText,
    zIndex: 10,
});

const TOPPANEL = css({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
});

const CENTERPANEL = css({
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
});

const BOTTOMPANEL = css({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
});

const TIMEBOX = css({
    width: 'auto',
    height: 10,
    padding: 1,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    fontSize: 6,
});

const WINNERREWARD = css({});

const WINNERTEXT = css({
    fontSize: 8,
    marginRight: 6,
});

const COINTEXT = css({
    fontSize: 12,
    color: colors.yellowText,
    marginLeft: spacing[1],
});

const TICKETBOX = css({
    width: 44,
    height: 12,
    borderRadius: 6,
    background: colors.darkText,
    marginLeft: spacing[3],
    fontSize: 6,
    color: colors.yellowText,
    paddingRight: spacing[1],
});

const TICKETIMAGE = css({
    marginLeft: -6,
    width: 22,
    height: 15,
});

const MEMBER = css({
    fontSize: 6,
});

const PLACEHOLDERTICKET = css({
    background: "rgba(175, 55, 251, 0.2)",
    width: "100%",
    height: "100%",
    borderRadius: 4,
});

const COMINGSOON = css({
    position: "relative",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    bottom: 63,
    borderRadius: 4,
    color: colors.lightText,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const COMINGSOONTEXT = css({
    transform: "rotate(-15deg)",
    fontSize: 12,
    fontWeight: "bold",
});

export interface TicketProps {
    styles?: SerializedStyles;
    time?: string;
    winnerIcon?: string;
    winnerCoins?: string;
    memberCount?: string;
    ticketCost?: number;
    isPlaceholder?: boolean;
    isComingSoon?: boolean;
}

const comingSoon = "BATTLE_COMING_SOON"

export const Ticket = ({
    styles,
    time,
    winnerCoins,
    memberCount,
    ticketCost = 0,
    isPlaceholder,
    isComingSoon,
}: TicketProps) => {
    let comingSoonBox = (
        <div css={COMINGSOON}>
            <Text styles={COMINGSOONTEXT}>{comingSoon}</Text>
        </div>
    );

    let ticketContainer;
    if (isPlaceholder) {
        ticketContainer = (
            <div css={[BACKGROUNDCONTAINER]}>
                <div css={TICKETCONTAINER}>
                    <div css={TICKETBACKGROUNDCONTAINER}>
                        <div css={TOPPANEL}>
                            <TimeBox time={time} styles={TIMEBOX} />
                        </div>
                        <div css={CENTERPANEL}>
                            <WinnerReward
                                imgHeight="15"
                                imgWidth="15"
                                winnerCoins={winnerCoins}
                                styles={WINNERREWARD}
                                winnerTextStyles={WINNERTEXT}
                                coinTextStyle={COINTEXT}
                            />
                        </div>
                        <div css={BOTTOMPANEL}>
                            <Members
                                styles={MEMBER}
                                memberCount={memberCount}
                                imgHeight="7"
                                imgWidth="12"
                            />
                            <TicketBox
                                ticketImage={ticketImage}
                                ticketCost={ticketCost}
                                styles={TICKETBOX}
                                ticketImageStyle={TICKETIMAGE}
                            />
                        </div>
                    </div>
                </div>
                {isComingSoon ? comingSoonBox : null}
            </div>
        );
    } else {
        ticketContainer = <div css={PLACEHOLDERTICKET}></div>;
    }

    return <div css={[CONTAINER, styles]}>{ticketContainer}</div>;
};
