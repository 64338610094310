/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../../config";
import { Text } from "../../components/text/text";
import { BirdTutorialScreen } from "../../components/onboarding/bird-tutorial-text";
import GetText from "../../../src/assets/images/getItem/get_tex.png";
import GetLight from "../../../src/assets/images/getItem/get_light.png";
import BonusCoins from "../../../src/assets/images/getItem/BadollarBonus.png";
import { useHistory } from "react-router-dom";
import { Image } from "../../components/image/image";

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: 'center'
});

const BIRD = css({
    bottom: 50,
});

const BONUSCONTAINER = css({
    width: 375,
    height: 375,
    background: `url(${GetLight})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
    zIndex: 20,
    marginTop: 102
});

const OVERLAY = css({
    background: colors.darkText,
    opacity: 0.5,
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 10,
    marginTop: -60,
});

const GETTEXT = css({
    width: 143,
});

const BONUSCOINS = css({
    width: 155,
})

const coinsText = "ONBOARDING_COINS_TEXT";

export const OnboardingGetCoins = () => {
    const history = useHistory();

    const login = () => {
        history.push("/login");
    };

    return (
        <div css={CONTAINER}>
            <div css={OVERLAY}></div>
            <div css={BONUSCONTAINER}>
                <Image src={GetText} alt="" styles={GETTEXT} />
                <Image src={BonusCoins} alt="" styles={BONUSCOINS} />
                <Text size={20} color={colors.yellowText}>
                    {coinsText}
                </Text>
            </div>
            <BirdTutorialScreen
                onBoardingText="ONBOARDING_RECEIVE_COINS"
                hasButton={true}
                buttonText="AUTH_BUTTON_NEXT"
                styles={BIRD}
                onClick={login}
            />
        </div>
    );
};
