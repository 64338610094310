/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import invite from "../../assets/images/invitation/invite.png";
import Modal from "react-modal";
import { Button } from "../button/button";
import { Image } from "../image/image";


const INVITEIMAGE = css({
  width: "110%",
});

const IMAGECONTAINER = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: -120,
});

const SHAREICONCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: -120,
});

const BATTLECODECONTAINER = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: spacing[2],
  marginTop: spacing[7],
});

const COPYBUTTON = css({
  background: colors.buttonGradient,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  flexDirection: "column",
  borderRadius: spacing[1],
  marginLeft: spacing[1],
  marginRight: spacing[1],
});

const inviteText = "SHARE_BUTTON_INVITE";
const closeText = "BATTLE_MATCH_MODAL_BUTTON_CLOSE";

export interface ShareProps {
  open: boolean;
  setOpen: (b: boolean) => void
}

export const Share = ({ open, setOpen }: ShareProps) => {

  const close = () => {
      setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 10,
        },
        content: {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: colors.lightText,
          bottom: "auto",
          minHeight: "10rem",
          left: "50%",
          position: "fixed",
          right: "auto",
          top: "50%",
          transform: "translate(-50%,-50%)",
          overflow: "visible",
          width: "80%",
        },
      }}
    >
        <div css={IMAGECONTAINER}>
          <Image src={invite} styles={INVITEIMAGE} />
        </div>
        <div css={SHAREICONCONTAINER}>
          <div css={BATTLECODECONTAINER}>
              <Button styles={COPYBUTTON} label={inviteText} />
              <Button styles={COPYBUTTON} label={closeText} onPress={close} />
          </div>
        </div>
      </Modal>
  );
};