import {
    getFirestore,
    loadBundle,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    doc,
    getDoc,
} from "firebase/firestore";
// import {
//     ref, getStorage
// } from "firebase/storage";
import { Battle, Game, ShopItem } from "./info";

const db = getFirestore();
// const storage = getStorage();

// const bundleRef = ref(storage, "bundle");

type ConfigContainer = { battles: Array<Battle>, shopItems: Array<ShopItem>, games: Array<Game> };

const configConverter: FirestoreDataConverter<ConfigContainer> = {
    toFirestore: (data: Partial<ConfigContainer>) => data,
    fromFirestore: (snap: QueryDocumentSnapshot<ConfigContainer>) => snap.data()
};

export const fetchBundle = async () => {
    // const url = await getDownloadURL(bundleRef);
    // const response = await fetch(url);
    // if (!response.body) {
    //     throw new Error("bundle body is empty");
    // }

    const response = await fetch("/fetchBundle");
    if (!response.body) {
        throw new Error("bundle body is empty");
    }

    await loadBundle(db, response.body);

    const configDoc = doc(db, "config", "config").withConverter(configConverter);
    const result = await getDoc(configDoc);

    const { shopItems, battles, games } = result.data() ?? { shopItems: [], battles: [], games: [] };

    return { battles, shopItems, games };
};
