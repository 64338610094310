/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { NonTranslatedText } from "../..";
import { Image } from "../../image/image";
import numeral from "numeral";

const TICKET = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 'bold'
});

export interface TicketBoxProps {
    styles?: SerializedStyles
    ticketImageStyle?: SerializedStyles
    ticketImage?: string
    ticketCost?: number
    imgWidth?: string
    imgHeight?: string
    fontStyle?: SerializedStyles
}

export const TicketBox = ({
    styles,
    ticketImageStyle,
    ticketImage,
    ticketCost = 0,
    imgWidth,
    imgHeight,
    fontStyle
}: TicketBoxProps) => {

    const amount = numeral(ticketCost).format("0.0a");

    return (
        <div css={[TICKET, styles]}>
            <Image
                src={ticketImage}
                styles={ticketImageStyle}
            />
            <NonTranslatedText styles={fontStyle}>{amount}</NonTranslatedText>
        </div>
    );
};
