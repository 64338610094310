/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import addImage from '../../assets/images/info/Add.png';
import { colors, spacing, textSizes } from '../../config';
import { NonTranslatedText } from '..';
import { Image } from '../image/image';
import numeral from "numeral";

const CONTAINERSTYLE = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: spacing[2],
    position: 'relative'
});

const BUTTONSTYLE = css({
    height: spacing[4],
    width: spacing[4],
    zIndex: 10,
    marginLeft: "-2px"
});

const TEXTSTYLE = css({
    color: colors.lightText,
    whiteSpace: 'pre',
    fontSize: textSizes.h3,
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: "black",
    minWidth: 40
});

const maxCount = 9999;

export interface InfoCountProps {
    image: string
    count: number
    width: number,
    height: number,
    onAdd: () => void
};

export const InfoCount = ({ image, count, width, height, onAdd }: InfoCountProps) => {

    let displayText = numeral(count).format('0,0');

    if (count > maxCount) {
        displayText = numeral(count).format('0.00a');
    }

    const IMAGESTYLE = css({
        zIndex: 1,
        marginRight: "-8px",
        width: width,
        height: height,
    });

    return (
        <div css={CONTAINERSTYLE}>
            <Image styles={IMAGESTYLE} src={image} />
            <NonTranslatedText styles={TEXTSTYLE}>{displayText}</NonTranslatedText>
            <Image src={addImage} styles={BUTTONSTYLE} onClick={onAdd} />
        </div>
    );
};
