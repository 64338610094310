/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Modal from "react-modal";
import { colors, spacing } from "../../config";
import { Text } from "../text/text";
import { Button } from "../button/button";

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});

const TICKETINFO = css({
    marginTop: spacing[4],
    marginBottom: spacing[3],
    display: "flex",
    flexDirection: "row",
});

const TITLETEXT = css({
    marginBottom: spacing[5],
});

const DESCRIPTION = css({
    marginBottom: spacing[6],
});

const BUTTONCONTAINER = css({
    display: "flex",
    marginTop: spacing[7],
});

const BUTTON = css({
    margin: spacing[2],
    display: "flex",
    flex: 1,
});

const noOpponentFound = "BATTLE_MODAL_NO_OPPONENT";
const backToHome = "BATTLE_MODAL_NO_MATCH";
const noTicketConsumption = "BATTLE_MODAL_NO_TICKET_CONSUMPTION";
const close = "BATTLE_MATCH_MODAL_BUTTON_CLOSE";

export interface NoOpponentFoundProps {
    show: boolean,
    onOk: () => void
}

export const NoOpponentFound = ({
    show, onOk

}: NoOpponentFoundProps) => {
    return (
        <Modal
            isOpen={show}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 10,
                },
                content: {
                    backgroundColor: colors.darkText,
                    borderRadius: spacing[3],
                    borderWidth: 2,
                    borderColor: colors.primary,
                    color: colors.lightText,
                    bottom: "auto",
                    minHeight: "10rem",
                    left: "50%",
                    position: "fixed",
                    right: "auto",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    overflow: "visible",
                    width: "80%",
                },
            }}
        >
            <div css={CONTAINER}>
                <div css={TICKETINFO}>
                    <Text size={18} styles={TITLETEXT}>
                        {noOpponentFound}
                    </Text>
                </div>
                <Text size={15} styles={DESCRIPTION}>
                    {backToHome}
                </Text>
                <Text size={15} color={colors.yellowText}>
                    {noTicketConsumption}
                </Text>
                <div css={BUTTONCONTAINER}>
                    <Button label={close} styles={BUTTON} onPress={onOk} />
                </div>
            </div>
        </Modal>
    );
};
