/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { Button } from "../button/button";
import { TextInput } from "../input/text-input";
import { spacing } from "../../config";
import { Text } from "../text/text";
import { otpCancelSelector, otpVerifySelector, useStore } from "../../models";

type VerifyOtpProps = { id: string, onCancel: () => void }

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: spacing[5],
    paddingLeft: spacing[5],
});

const BUTTON = css({
    marginTop: spacing[6],
})

const CANCELBUTTON = css({ marginTop: spacing[4] });

const INPUT = css({
    textAlign: 'center',
});

export const VerifyOtp = ({ id }: VerifyOtpProps) => {
    const [otp, setOtp] = useState<number>(-1);
    const verifyOtp = useStore(otpVerifySelector);
    const cancelOtp = useStore(otpCancelSelector);
    const [otpError, setOtpError] = useState(null);

    const onChange = (t: string) => {
        try {
            const num = parseInt(t);
            setOtp(num);
        } catch (ex: any) {
            setOtp(-1);
            console.log(ex);
        }
    };

    const otpVerification = async (otp: number) => {
        if (!id) {
            return;
        }
        try {
            await verifyOtp(id, otp);
        } catch (ex: any) {
            setOtpError(ex);
        }
    };

    return (
        <div css={CONTAINER}>
            <TextInput value={otp >= 0 ? otp.toString() : ""}
                onChangeText={onChange} placeholder="OTP" type="number" inputBoxStyle={INPUT} />
            {otpError ? <Text>Invalid OTP, Try again!</Text> : null}
            <Button label="Verify" onPress={() => otpVerification(otp)} styles={BUTTON} />
            <Button label="Cancel" onPress={cancelOtp} styles={CANCELBUTTON} />
        </div>
    );
};
