import { MemoryRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute, ProfileRoute } from "./private-route";
import {
    MaintenanceScreen,
    LoginScreen,
    FinishLoginScreen,
    BattleScreen,
    WalletScreen,
    ShopScreen,
    LayoutWithTopAndBottomBar,
    LayoutWithTopBar,
    EditProfileScreen,
    MenuScreen,
    FindBattleScreen,
    FullScreenLayout,
    GameFinderScreen,
    PlayGameScreen,
    EndBattleScreen,
} from "../screens";
import { LoadingScreen } from "../screens/loading/loading";
import {
    OnboardingGameLoading,
    BattleOnboardingScreen,
    TermsAndConditionsScreen,
    OnboardingMatchMatching,
    OnboardingResult,
    OnboardingGetCoins,
    OnboardingPlayGame,
} from "../screens/onboarding";
import { OfficialNote } from "../screens/menu/official-note";
import { ErrorScreen } from "../screens/error/error";
import { MidtransScreen } from "../screens/midtrans/midtrans-screen"
import { WithdrawScreen } from "../screens/withdraw/withdraw-screen"

import { useTrackPageView } from "../services";

const fullLayoutScreens = ["/store", "/battle", "/wallet", "/profile", "/midtrans", "/withdraw"];
const fullscreenLayout = ["/officialnote"];

const onlyTopLayoutScreens = ["/edit-profile", "/menu"];
const onboardingScreens = [
    "/termsandconditions",
    "/onboardingmatching",
    "/onboardinggameloading",
    "/onboardingresult",
    "/onboardingscore",
    "/onboardinggetcoins",
    "/onboardingbattle",
    "/onboardingplaygame",
];

const battleScreens = [
    "/play-battle",
    "/play-game",
    "/game-selection",
    "/end-battle",
];

const combined = [
    ...fullLayoutScreens,
    ...onlyTopLayoutScreens,
    ...battleScreens,
    ...fullscreenLayout,
];

const AnalyticsWrapper = ({ children }: { children: React.ReactNode }) => {
    useTrackPageView();
    return <>{children}</>;
};

export const MainNavigation = () => {
    return (
        <Router>
            <AnalyticsWrapper>
                <Switch>
                    <Route exact path="/finish-login">
                        <FinishLoginScreen />
                    </Route>
                    <Route exact path="/error">
                        <ErrorScreen />
                    </Route>
                    <Route path="/login">
                        <LoginScreen />
                    </Route>
                    <Route path="/maintenance">
                        <MaintenanceScreen />
                    </Route>
                    <PrivateRoute path={combined}>
                        <Route path={onlyTopLayoutScreens}>
                            <LayoutWithTopBar>
                                <Route exact path="/edit-profile">
                                    <EditProfileScreen />
                                </Route>
                                <Route exact path="/menu">
                                    <MenuScreen />
                                </Route>
                            </LayoutWithTopBar>
                        </Route>
                        <Route path={fullscreenLayout}>
                            <FullScreenLayout>
                                <Route path="/officialnote">
                                    <OfficialNote />
                                </Route>
                            </FullScreenLayout>
                        </Route>
                        <Route path={fullLayoutScreens}>
                            <LayoutWithTopAndBottomBar>
                                <ProfileRoute path="/store">
                                    <ShopScreen />
                                </ProfileRoute>
                                <ProfileRoute path="/battle">
                                    <BattleScreen />
                                </ProfileRoute>
                                <ProfileRoute path="/wallet">
                                    <WalletScreen />
                                </ProfileRoute>
                                <ProfileRoute path="/midtrans">
                                    <MidtransScreen />
                                </ProfileRoute>
                                <ProfileRoute path="/withdraw">
                                    <WithdrawScreen />
                                </ProfileRoute>
                            </LayoutWithTopAndBottomBar>
                        </Route>
                        <Route path="/play-battle/:id">
                            <FindBattleScreen />
                        </Route>
                        <Route path="/play-game/:id">
                            <PlayGameScreen />
                        </Route>
                        <Route path="/end-battle">
                            <EndBattleScreen />
                        </Route>
                        <Route path="/game-selection/:id">
                            <GameFinderScreen />
                        </Route>
                    </PrivateRoute>
                    <Route path={onboardingScreens}>
                        <FullScreenLayout>
                            <Route path="/termsandconditions">
                                <TermsAndConditionsScreen />
                            </Route>
                            <Route path="/onboardingbattle">
                                <BattleOnboardingScreen />
                            </Route>
                            <Route path="/onboardingplaygame">
                                <OnboardingPlayGame />
                            </Route>
                            <Route path="/onboardingmatching">
                                <OnboardingMatchMatching />
                            </Route>
                            <Route path="/onboardinggameloading">
                                <OnboardingGameLoading />
                            </Route>
                            <Route path="/onboardingresult">
                                <OnboardingResult />
                            </Route>
                            <Route path="/onboardinggetcoins">
                                <OnboardingGetCoins />
                            </Route>
                        </FullScreenLayout>
                    </Route>
                    <Route exact path="/">
                        <LoadingScreen />
                    </Route>
                </Switch>
            </AnalyticsWrapper>
        </Router>
    );
};
