/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import { Text } from '../text/text';
import { colors, spacing } from '../../config';
import { DialogImageTitle } from './dialog-image-title';

const TEXTTITLESTYLE = css({
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: colors.primary,
    borderStyle: 'solid',
    padding: spacing[3],
});

type TextTitleProps = {
    titleText: string
};

type ImageTitleProps = {
    image: string
    height?: number
    width?: number
};

type BannerTitleProps = {
    bannerText: string
}

const isTextTitle = (props: TextTitleProps | ImageTitleProps | BannerTitleProps): props is TextTitleProps => "titleText" in props;

const isImageTitle = (props: TextTitleProps | ImageTitleProps | BannerTitleProps): props is ImageTitleProps => "image" in props;

const isBannerTitle = (props: TextTitleProps | ImageTitleProps | BannerTitleProps): props is TextTitleProps => "bannerText" in props;

export type Title = {
    (props: TextTitleProps): JSX.Element
    (props: ImageTitleProps): JSX.Element
    (props: BannerTitleProps): JSX.Element
}

export const DialogTitle: Title = (props: TextTitleProps | ImageTitleProps | BannerTitleProps) => {

    if (isTextTitle(props)) {
        return <Text styles={TEXTTITLESTYLE} size={12}>{props.titleText}</Text>;
    }

    if (isImageTitle(props)) {
        return <DialogImageTitle image={props.image} height={props.height} width={props.width} />;
    }

    if (isBannerTitle(props)) {
        return <></>;
    }

    return <></>;
};
