/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react'

const CONTAINERSTYLE = css({
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden'
});

const VIDEOSTYLE = css({
    height: '100%',
    width: '100%',
    objectFit: 'fill',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
});

const CHILDCONTAINER = css({
    height: '100%',
    width: '100%',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
});

export interface ScreenProps {
    children?: React.ReactNode
    styles?: SerializedStyles
    backgroundColor?: string
    backgroundImage: string
}

export const Screen = ({ children, backgroundImage, backgroundColor = 'transparent', styles }: ScreenProps) => {

    const backgroundStyle = css({
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor,
    });

    return (
        <div css={[CONTAINERSTYLE, backgroundStyle, styles]}>
            {children}
        </div>
    );
};

export interface VideoScreenProps {
    children?: React.ReactNode
    styles?: SerializedStyles
    video: string
}


export const VideoScreen = ({ children, video, styles }: VideoScreenProps) => {
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <video css={VIDEOSTYLE} loop
                muted
                autoPlay >
                <source src={video} type="video/mp4" />
            </video>
            <div css={CHILDCONTAINER}>
                {children}
            </div>
        </div>
    );
};
