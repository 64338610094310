/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { spacing } from "../../config";
import { Text } from "../text/text";
import { CircleIndicator } from "../loader/loading-indicator";
import logo from "../../assets/images/Logo.png";
import { Image } from "../image/image";

const CONTAINER = css({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
});

const LOADER = css({
  width: 30,
  marginTop: spacing[8]
});

const LOGO = css({
  width: "100%"
});

const WRAPPER = css({
  position: 'relative',
  textAlign: 'center',
  marginBottom: 100
})

const PROTOTYPE = css({
  position: 'absolute',
  bottom: 30,
  fontWeight: 800,
  color: 'white',
  textAlign: 'center',
  width: '100%'
})

const waiting = "BATTLE_TEXT_OPPONENT_WAITING";

export const Waiting = () => {
  return (
    <div css={CONTAINER}>
      <div css={WRAPPER}>
        <Image src={logo} styles={LOGO} />
        <p css={PROTOTYPE}>prototype</p>
      </div>
      <Text size={18}>{waiting}</Text>
      <div css={LOADER}>
        <CircleIndicator />
      </div>
    </div>
  );
};
