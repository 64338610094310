/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { css } from "@emotion/react";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { spacing } from "../../config";
import { GameInfoBar } from "../../components/info-bar/game-info-bar";
import { BattleTicket } from "../../components/battle/battle-ticket";
import { donePurchaseTickets } from "../../services";
import { battlesSelector, useStore, refreshBattleSelector, fetchWalletSelector, userSelector, isSuccessOrderSelector, setIsSuccessOrderSelector } from "../../models";

const CONTAINERSTYLE = css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
});

const everyMinute = 60 * 1000;

export const SCROLLCONTAINER = css({
    overflow: "auto",
    paddingBottom: 140,
    paddingLeft: spacing[5],
    paddingRight: spacing[5],
});

const BATTLE_TITLE_BATTLE = "BATTLE_TITLE_BATTLE";

export const BattleScreen = () => {
    const history = useHistory();

    const user = useStore(userSelector);
    const isSuccessOrder = useStore(isSuccessOrderSelector);
    const setIsSuccessOrder = useStore(setIsSuccessOrderSelector);
    const battles = useStore(battlesSelector);
    const refresh = useStore(refreshBattleSelector);
    const fetchWallet = useStore(fetchWalletSelector);

    const items = battles.map((b) => {
        return <BattleTicket key={b.startTime} battle={b} />;
    });

    useEffect(() => {
        const timeout = setInterval(refresh, everyMinute);
        return () => clearInterval(timeout);
    }, [refresh])


    useEffect(() => {
        const parsed = queryString.parse(window.location.search)
        const orderId = `${parsed?.order_id}`

        if (!user || !history || !orderId || isSuccessOrder) return

        const fetch = async () => {
            try {
                await donePurchaseTickets(orderId)
                await fetchWallet()
                setIsSuccessOrder(true)
                history.push('/midtrans')
            } catch (ex) {
                console.log(ex)
            }
        }
        fetch()
    }, [history, fetchWallet, user, isSuccessOrder, setIsSuccessOrder])

    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar title={BATTLE_TITLE_BATTLE} />
            <div css={SCROLLCONTAINER}>{items}</div>
        </div>
    );
};
