export enum PrizeType { Coins = "coins", Tickets = "tickets " }
export enum ImageType { Free = "free", Ticket = "ticket" };
export enum BattleType { Normal = "normal", Practise = "practise" }
export type Battle = {
    startTime: number,
    endTime: number,
    prize: number,
    prizeType: PrizeType,
    cost: number,
    imageType: ImageType,
    image: string,
    battleType: BattleType,
    id: string
}

export type ShopItem = {
    id: string,
    ticketCount: number
    costUsd: number
    costIdr: number
};

export type Game = {
    id: string
    name: string
    url: string
    icon: string
}
