/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import { InfoBar, NavBar, Screen } from '../../components';
import backgroundImage from '../../assets/images/background.png';

const CHILDCONTAINER = css({
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    paddingBottom: 80
});

const FULLSCREENCHILD = css({
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
});

export interface LayoutProps {
    children?: React.ReactNode
    background?: string
}

export const FullScreenLayout = ({ children, background = backgroundImage }: LayoutProps) => {
    return (
        <Screen backgroundImage={background}>
            <div css={FULLSCREENCHILD}>
                {children}
            </div>
        </Screen>
    );
};

export const LayoutWithTopBar = ({ children, background = backgroundImage }: LayoutProps) => {
    return (
        <Screen backgroundImage={background}>
            <InfoBar />
            <div css={FULLSCREENCHILD}>
                {children}
            </div>
        </Screen>
    );
};

export const LayoutWithTopAndBottomBar = ({ children, background = backgroundImage }: LayoutProps) => {
    return (
        <Screen backgroundImage={background}>
            <InfoBar />
            <div css={CHILDCONTAINER}>
                {children}
            </div>
            <NavBar />
        </Screen>
    );
};
