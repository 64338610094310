/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import { Text, GameInfoBar, Button, PaypalLoginModal, LoadingIndicator, NonTranslatedText } from "../../components/";
import coinImage from "../../assets/images/info/Coin.png";
import { coinSelector, linkedSelector, refreshWalletSelector, unlinkWalletSelector, useStore, withdrawSelector } from "../../models";
import { Image } from "../../components/image/image";
import numeral from "numeral";
import { useHistory } from "react-router-dom";

const minAmount = 10000000;

const CONTAINERSTYLE = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
});

const AMOUNTCONTAINER = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignSelf: "stretch",
  alignItems: 'center',
  marginBottom: spacing[4],
});

const INCREMENTBUTTON = css({
  marginRight: spacing[5],
  marginLeft: spacing[5],
  paddingLeft: spacing[4],
  paddingRight: spacing[4],
  paddingTop: spacing[2],
  paddingBottom: spacing[2],
  width: 'auto',
  height: 'auto',
});

const SUCCESSCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const OVERLAY = css({
  position: "absolute",
  top: 0, right: 0, left: 0, bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.8)',
  zIndex: 1000,
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
});

const POSSESSIONTICKET = css({
  display: "flex",
  marginTop: spacing[5],
});

const TICKETCONTAINER = css({
  display: "flex",
  marginTop: spacing[2],
  flexDirection: "row",
  alignItems: "center",
  height: 58,
});

const TICKET = css({
  width: 46,
  height: 46,
  marginRight: spacing[2],
});

const PAYPALBUTTON = css({
  padding: spacing[3],
  marginBottom: spacing[3],
  marginTop: spacing[3],
  display: "flex",
  width: "auto",
});

const NEXTBUTTON = css({
  marginTop: spacing[10],
  display: "flex",
});

const DEACTIVATEBUTTON = css({
  marginBottom: spacing[3],
  display: "flex",
  width: "auto",
  paddingLeft: spacing[3],
  paddingRight: spacing[3],
  marginTop: spacing[8],
});

const PAYMENTBOX = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  paddingTop: '1rem',
  alignSelf: "stretch",
  paddingBottom: spacing[4]
});

const INPUTBOXCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const wallet = "WALLET_TITLE";
const paypalButton = "WALLET_BUTTON_PAYPAL_BUTTON";
const midtransButton = "WALLET_BUTTON_MIDTRANS_BUTTON";
const possessionCoins = "SHOP_TITLE_POSSESSION_COINS";
const next = "WALLET_BUTTON_NEXT";
const deactivate = "WALLET_BUTTON_DEACTIVATE";
const paypalError = "PAYPAL_WITHDRAW_ERROR";
const minAmountText = "MIN_AMOUNT_TEXT";
const conversionText = "CONVERSION_TEXT";

const SuccessModal = ({ show, setShow }: { show: boolean, setShow: (b: boolean) => void }) => {
  return (
    <Modal isOpen={show} ariaHideApp={false}
      onRequestClose={() => setShow(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 10,
        },
        content: {
          backgroundColor: colors.darkText,
          borderRadius: spacing[3],
          borderWidth: 2,
          borderColor: colors.primary,
          color: colors.lightText,
          bottom: "auto",
          left: "50%",
          position: "fixed",
          right: "auto",
          top: "50%",
          transform: "translate(-50%,-50%)",
          overflow: "visible",
          width: "80%",
        },
      }}>
      <div css={SUCCESSCONTAINER}>
        <NonTranslatedText>
          Success!
        </NonTranslatedText>
        <Button label="OK" styles={NEXTBUTTON} onPress={() => setShow(false)} />
      </div>
    </Modal>
  );
};

const ErrorModal = ({ show, msg, setShow }: { show: boolean, setShow: (b: boolean) => void, msg: string }) => {
  return (
    <Modal isOpen={show} ariaHideApp={false}
      onRequestClose={() => setShow(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 10,
        },
        content: {
          backgroundColor: colors.darkText,
          borderRadius: spacing[3],
          borderWidth: 2,
          borderColor: colors.primary,
          color: colors.lightText,
          bottom: "auto",
          left: "50%",
          position: "fixed",
          right: "auto",
          top: "50%",
          transform: "translate(-50%,-50%)",
          overflow: "visible",
          width: "80%",
        },
      }}>
      <div css={SUCCESSCONTAINER}>
        <Text>
          {msg}
        </Text>
        <Button label="OK" styles={NEXTBUTTON} onPress={() => setShow(false)} />
      </div>
    </Modal>
  );
};

export const WalletScreen = () => {
  const history = useHistory();
  const [showConnect, setShowConnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(minAmount);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const linked = useStore(linkedSelector);
  const withdraw = useStore(withdrawSelector);
  const currentCoins = useStore(coinSelector);
  const unlinkWallet = useStore(unlinkWalletSelector);
  const refreshWallet = useStore(refreshWalletSelector);

  useEffect(() => {
    try {
      refreshWallet();
    } catch (ex) {

    }
  }, [refreshWallet]);

  const onChangeAmount = (amount: number) => {
    setWithdrawAmount(t => {
      const final = t - amount;
      if (final >= minAmount) {
        return final;
      }
      return minAmount;
    });
  }

  const transferMoney = async () => {
    setLoading(true);
    try {
      await withdraw(withdrawAmount);
      setShowSuccess(true);
    } catch (ex: any) {
      console.log(ex);
      setError(ex);
    }
    setLoading(false);
  };

  const unlinkPaypal = async () => {
    setLoading(true);
    try {
      await unlinkWallet();
    } catch (ex: any) {
      console.log(ex);
      setError(ex);
    }
    setLoading(false);
  };

  const displayAmount = numeral(withdrawAmount).format("0.00a");
  const currentAmount = numeral(currentCoins).format("0a");

  return (
    <div css={CONTAINERSTYLE}>
      <GameInfoBar title={wallet} />
      <Text styles={POSSESSIONTICKET} size={14}>
        {possessionCoins}
      </Text>
      <div css={TICKETCONTAINER}>
        <Image styles={TICKET} src={coinImage} alt="" />
        <Text color={colors.yellowText} size={36}>
          {currentAmount}
        </Text>
      </div>
      {linked ? <div css={PAYMENTBOX}>
        <div css={INPUTBOXCONTAINER}>
          <div css={AMOUNTCONTAINER}>
            <Button label="-" onPress={() => onChangeAmount(minAmount)} styles={INCREMENTBUTTON} />
            <NonTranslatedText>{displayAmount}</NonTranslatedText>
            <Button label="+" onPress={() => onChangeAmount(-minAmount)} styles={INCREMENTBUTTON} />
          </div>
          <Text>{minAmountText}</Text>
          <Text>{conversionText}</Text>
          <Button label={next} styles={NEXTBUTTON} onPress={transferMoney} disabled={withdrawAmount > currentCoins} />
        </div>
        <Button label={deactivate} styles={DEACTIVATEBUTTON} onPress={unlinkPaypal} />
        <SuccessModal show={showSuccess} setShow={setShowSuccess} />
        <ErrorModal show={!!error} setShow={() => setError(null)} msg={paypalError} />
      </div> : <div css={PAYMENTBOX}>
          <Button
            label={paypalButton}
            styles={PAYPALBUTTON}
            onPress={() => setShowConnect(true)}
          />
          <Button
            label={midtransButton}
            styles={PAYPALBUTTON}
            onPress={() => history.push("/withdraw")}
          />
          <Text>{conversionText}</Text>
          <PaypalLoginModal show={showConnect} setShow={setShowConnect} />
        </div>}
      {loading ? <div css={OVERLAY}><LoadingIndicator /></div> : null}
    </div>
  );
};
