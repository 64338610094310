/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../../config";
import { Text } from "../../text/text";
import { Image } from "../../image/image";

const BANNER = css({
  display: "flex",
  flex: 1,
  height: 96,
  maxHeight: 96,
  width: "auto",
  backgroundColor: colors.lightText,
  borderRadius: spacing[2],
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: colors.primary,
  margin: spacing[2],
});

const BANNERIMAGE = css({
  display: "flex",
  flex: 1,
});

const BANNERTEXT = css({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 30,
  color: colors.redText,
  fontWeight: "bold",
});

export interface BannerProps {
  styles?: SerializedStyles;
  bannerImage?: string;
  bannerText: string;
}

export const Banner = ({ styles, bannerImage, bannerText }: BannerProps) => {
  return (
    <div css={BANNER}>
      {bannerImage != null ? (
        <Image styles={BANNERIMAGE} src={bannerImage} />
      ) : (
        <Text styles={BANNERTEXT}>{bannerText}</Text>
      )}
    </div>
  );
};
