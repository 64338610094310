import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";
import en from './assets/locales/en/translation.json';
// import id from './assets/locales/id/translation.json';
// import ja from './assets/locales/ja/translation.json';

const resources = {
    en,
};

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        detection: options,
        debug: false,
        ns: "translation",
        defaultNS: "translation",

        supportedLngs: ['en'],

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
