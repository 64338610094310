/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { spacing, colors } from "../../config";
import { Text } from "../../components";

const CONTAINERSTYLE = css({
  display: "flex",
  flexDirection: "column",
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.primary,
  margin: spacing[3],
  marginBottom: 0,
  padding: spacing[3],
  background: "rgba(0, 0, 0, 0.8)"
});

const BOTTOMPANEL = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "space-between",
    marginTop: spacing[2]
});

export const NotificationItem = () => {
  return (
    <div css={CONTAINERSTYLE}>
      <Text align="left" size={16}>
        Lorem Ipsum Sample Notifications, Lorem Ipsum Sample Notifications.
        Lorem Ipsum Sample Notifications
      </Text>
      <div css={BOTTOMPANEL}>
          <Text size={10} color="#c4c4c4">1 min ago</Text>
          <Text color="#02ECFB">more...</Text>
      </div>
    </div>
  );
};
