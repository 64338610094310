/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react'
import { colors, textWeight } from '../../config';
import { useTranslation } from "react-i18next";

export interface NonTranslatedTextProps {
    as?: React.ElementType<any>
    size?: number
    color?: string
    align?: 'center' | 'left' | 'right' | 'justify'
    selectable?: boolean
    children: React.ReactNode
    weight?: number
    styles?: SerializedStyles
}

export interface TextProps {
    as?: React.ElementType<any>
    size?: number
    color?: string
    align?: 'center' | 'left' | 'right' | 'justify'
    selectable?: boolean
    children: string
    weight?: number
    styles?: SerializedStyles
}

export const NonTranslatedText = ({
    as = "div",
    align = "center",
    color = colors.lightText,
    size = 14,
    selectable = false,
    children,
    weight = 700,
    styles
}: NonTranslatedTextProps) => {
    const Component = as;

    const overrides = css({
        textAlign: align,
        fontSize: size,
        fontWeight: textWeight.normal,
        color: color,
        userSelect: selectable ? 'text' : 'none',
        whiteSpace: "pre-line",
        lineHeight: "150%",
    });

    return <Component css={[overrides, styles]}>{children}</Component>
};

export const Text = ({ children, ...rest }: TextProps) => {
    const { t } = useTranslation();

    return <NonTranslatedText {...rest}>{t(children)}</NonTranslatedText>
};
