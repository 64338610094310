import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const analytics = getAnalytics();

export const trackEvent = (name: string, data?: any) => logEvent(analytics, name, data);

export const trackProperty = (name: string, value: any) => setUserProperties(analytics, { [name]: value });

export const useTrackScreenEvent = (name: string, data?: any) => {
    useEffect(() => {
        trackEvent(name, data);
    }, [name, data]);
};

export const useTrackPageView = () => {
    const location = useLocation();
    useEffect(() => {
        trackEvent("screen_view", {
            "app_name": "Akiverse",
            "firebase_screen": location.pathname,
            "screen_name": location.pathname,
            "firebase_screen_class": "app"
        });
    }, [location]);
};

type LoginMethods = "otp" | "google" | "facebook";

export const trackLogin = (method: LoginMethods) => {
    trackEvent("login", { method });
};

export const trackFailedLogin = (method: LoginMethods) => {
    trackEvent("login_failed", { method });
};
