/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import versusImage from "../../assets/images/battleModes/BattleVS.png";
import { colors, spacing } from "../../config";
import { Matching, BattlePlayer, Screen } from "../../components";
import firstUserProfileBackground from "../../assets/images/BattleModeOpponentUserIconBg.png";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";
import secondUserProfileBackground from "../../assets/images/BattleModeUserIconBg.png";
import { battleErrorSelector, battleLoadingSelector, battleOpponentSelector, cancelBattleSelector, startBattleSelector, userSelector, useStore } from "../../models";
import { useHistory, useParams } from "react-router-dom";
import { NoOpponentFound } from "../../components/modals/no-opponent-fount";
import { Image } from "../../components/image/image";
import { trackEvent } from "../../services";

const waitingTimeout = 30000;

const VERSUS = css({
    display: "flex",
    flexDirection: "column",
    height: 130,
    alignSelf: "center"
});

const SECONDPROFILEPHOTOCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    zIndex: 10,
});

const FIRSTPROFILEPHOTOCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 50,
    zIndex: 10,
});

const FIRSTUSERUSERNAMEBOX = css({
    marginTop: -50,
    justifyContent: "flex-end",
});

const SECONDUSERUSERNAMEBOX = css({
    marginTop: -130,
});

const FIRSTUSERUSERNAMETEXT = css({
    marginRight: spacing[8],
});

const SECONDUSERUSERNAMETEXT = css({
    marginLeft: spacing[8],
});

const FIRSTUSERPROFILEPHOTO = css({
    marginLeft: spacing[4],
});

const SECONDUSERPROFILEPHOTO = css({
    marginRight: spacing[4],
    marginTop: spacing[4],
});

const VERSUSIMAGE = css({
    width: 173,
    height: 130
})

const noop = () => { };

type FindBattleProps = { id: string };

export const FindBattleScreen = () => {
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const currentUser = useStore(userSelector);

    const username = currentUser?.displayName ?? "";
    const picture = currentUser?.photoURL ?? defaultIcon1;

    const battleLoading = useStore(battleLoadingSelector);
    const cancelBattle = useStore(cancelBattleSelector);
    useStore(battleErrorSelector);
    const startBattle = useStore(startBattleSelector);
    const opponent = useStore(battleOpponentSelector);
    const { id } = useParams<FindBattleProps>();


    useEffect(() => {
        let unsub = noop;
        const fn = async () => {
            if (!id) {
                return;
            }
            setLoading(true);
            try {
                unsub = await startBattle(id);
            } catch (ex) {
                console.log(ex);
            }
            setLoading(false);
        };
        fn();
        return unsub;
    }, [startBattle, id])

    useEffect(() => {
        const timeout = setTimeout(async () => {
            setLoading(true);
            try {
                await cancelBattle();
            } catch (ex) {
                console.log(ex);
            }
            setFailed(true);
            setLoading(false);
            trackEvent("find_battle_timeout", { id });
        }, waitingTimeout);
        if (opponent) {
            trackEvent("found_battle", { id });
            setTimeout(() => {
                history.replace(`/game-selection/${opponent.gameId}`);
            }, 2000);
            clearTimeout(timeout);
        }

        return () => clearTimeout(timeout);
    }, [opponent, history, cancelBattle, id]);

    const onCancel = async () => {
        setLoading(true);
        try {
            await cancelBattle();
        } catch (ex) {
            console.log(ex);
        }
        setLoading(false);
        trackEvent("cancel_battle", { id });
        history.replace(`/battle`);
    };

    const onFailOk = () => history.replace('/battle');

    return (
        <Screen backgroundImage='./matching.webp'>
            {battleLoading ? (
                <Matching onCancel={onCancel} hideCancel={loading} />
            ) : (
                    opponent ? <BattlePlayer
                        username={opponent.name}
                        profileContainerStyle={FIRSTPROFILEPHOTOCONTAINER}
                        profileImage={opponent.photo}
                        profilePhotoStyle={FIRSTUSERPROFILEPHOTO}
                        usernameBoxStyle={FIRSTUSERUSERNAMEBOX}
                        usernameTextStyle={FIRSTUSERUSERNAMETEXT}
                        name=""
                        profileImageBackground={firstUserProfileBackground}
                    /> : <Matching onCancel={onCancel} hideCancel={loading} />
                )}
            <div css={VERSUS}>
                <Image src={versusImage} styles={VERSUSIMAGE} />
            </div>
            <BattlePlayer
                name="YOU"
                username={username}
                profileContainerStyle={SECONDPROFILEPHOTOCONTAINER}
                profileImage={picture}
                profilePhotoStyle={SECONDUSERPROFILEPHOTO}
                usernameBoxStyle={SECONDUSERUSERNAMEBOX}
                usernameTextStyle={SECONDUSERUSERNAMETEXT}
                usernameColor={colors.yellowText}
                profileImageBackground={secondUserProfileBackground}
            />
            <NoOpponentFound show={failed} onOk={onFailOk} />
        </Screen>
    );
};
