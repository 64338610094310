/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import {
    GameInfoBar,
    Text,
    TextInput,
    Button,
    PhotoPicker,
    ProfilePhoto,
} from "../../components";
import { spacing, colors } from "../../config";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";
import defaultIcon2 from "../../assets/images/profile/DefaultUserIcon2.png";
import selectedFrame from "../../assets/images/profile/SelectedFrame.png";
import {
    profileStateSelector,
    updateProfileSelector,
    userSelector,
    useStore,
    ProfileState,
    PROFILE_IMAGES,
} from "../../models";
import { useHistory, useLocation } from "react-router-dom";

const CONTAINERSTYLE = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
});

const PROFILEIMAGECONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing[5],
    marginBottom: spacing[5],
});

const EDITFIELDS = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing[5],
});

const INPUTFIELD = css({
    marginTop: spacing[3],
    marginBottom: spacing[3],
});

const BUTTONCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: spacing[6],
});

const BUTTONROW2 = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: spacing[5],
    width: "100%",
    flex: 1,
});

const SAVEDISABLEBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
    background: colors.disableButtonGradient,
});

const SAVEBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
    background: colors.saveButtonGradient,
});

const CANCELBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
});

const EDITCONTAINER = css({
    display: "flex",
    flex: 1,
    alignItems: "space-around",
    flexDirection: "column",
});

const editYourProfile = "MENU_BUTTON_EDIT_PROFILE";
const profileImageUsageInfo = "profileImageUsageInfo";
const usernameLabel = "PROFILE_LABEL_USERNAME";
const AUTH_LABEL_EMAIL = "AUTH_LABEL_EMAIL";
const save = "PROFILE_BUTTON_SAVE";
const cancel = "PROFILE_BUTTON_CANCEL";

const isSelected = (val: string | File, compare: string) =>
    val === compare ? selectedFrame : "";

type ProfileImagesProps = {
    selectedImage: string | File;
    setSelectedImage: (n: string | File) => void;
};

const ProfileImages = ({
    selectedImage,
    setSelectedImage,
}: ProfileImagesProps) => {
    return (
        <>
            <ProfilePhoto
                image={defaultIcon1}
                profileImageBackground={isSelected(
                    selectedImage,
                    PROFILE_IMAGES.PROFILE1
                )}
                onPress={() => setSelectedImage(PROFILE_IMAGES.PROFILE1)}
            />
            <ProfilePhoto
                image={defaultIcon2}
                profileImageBackground={isSelected(
                    selectedImage,
                    PROFILE_IMAGES.PROFILE2
                )}
                onPress={() => setSelectedImage(PROFILE_IMAGES.PROFILE2)}
            />
            <PhotoPicker selectedImage={selectedImage} onSelect={setSelectedImage} />
        </>
    );
};

type EditProfileLocation = { back: string };

export const EditProfileScreen = () => {
    const currentUser = useStore(userSelector);
    const profileState = useStore(profileStateSelector);
    const updateProfile = useStore(updateProfileSelector);
    const history = useHistory();
    const location = useLocation<EditProfileLocation>();

    const customBack = !!location?.state?.back;

    const [username, setUsername] = useState(currentUser?.displayName || "");
    const [email, setEmail] = useState(currentUser?.email || "");
    const [profilePicture, setProfilePicture] = useState<string | File>(
        currentUser?.photoURL ?? PROFILE_IMAGES.PROFILE1
    );

    const showCancel = !!currentUser?.displayName;
    const trimmed = username.trim();
    const saveDisabled = trimmed === "";

    const onSave = async () => {
        if (
            trimmed === "" ||
            profileState === ProfileState.UPDATING
        ) {
            return;
        }
        await updateProfile(username, profilePicture);
        if (customBack) {
            history.replace(location.state.back);
        } else {
            history.goBack();
        }
    };

    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar
                title={editYourProfile}
                showBack={history.length > 0}
                onBack={() => history.goBack()}
            />
            <div css={EDITCONTAINER}>
                {/* Top Bar */}

                {/* Profile Container */}
                {/* TODO: Add UI for loading of profile images */}
                <div>
                    <div css={PROFILEIMAGECONTAINER}>
                        <ProfileImages
                            selectedImage={profilePicture}
                            setSelectedImage={setProfilePicture}
                        />
                    </div>
                    <Text size={12}>{profileImageUsageInfo}</Text>
                </div>

                {/* Edit Fields */}
                <div css={EDITFIELDS}>
                    <TextInput
                        label={usernameLabel}
                        type="text"
                        value={username}
                        placeholder="User Name"
                        onChangeText={setUsername}
                        styles={INPUTFIELD}
                    />
                    <TextInput
                        label={AUTH_LABEL_EMAIL}
                        type="email"
                        value={email}
                        disabled
                        onChangeText={setEmail}
                        styles={INPUTFIELD}
                    />
                </div>

                {/* Buttons */}
                <div css={BUTTONCONTAINER}>
                    <div css={BUTTONROW2}>
                        {showCancel ? (
                            <Button label={cancel} styles={CANCELBUTTON} onPress={() => history.goBack()}></Button>
                        ) : null}
                        <Button
                            label={save}
                            styles={saveDisabled ? SAVEDISABLEBUTTON : SAVEBUTTON}
                            onPress={onSave}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
