/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Dialog, Screen, Text } from "../../components";
import backgroundImage from "../../assets/images/background.png";
import dialogTitleImage from "../../assets/images/MaintenanceImage.png";
import { colors, spacing } from "../../config";

const CONTAINERSTYLE = css({
    justifyContent: "center",
    alignItems: "center",
});

const BUTTONCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: spacing[9],
});

const DIALOGBODYCONTAINER = css({
    marginTop: "-120px"
});

const HIGHLIGHTEDBOXCONTAINER = css({
    backgroundColor: '#FFF7E8',
    borderRadius: spacing[3],
    padding: spacing[2],
    marginTop: spacing[5],
    marginBottom: spacing[10]
  });

const DIALOGBOXTEXT = css({
    color: colors.lightText,
    fontWeight: "bold",
    lineHeight: "150%"
});

const HIGHLIGHTEDBOXTEXTTITLE = css({
    color: colors.darkText,
    fontsize: "14px",
    fontWeight: "bold",
    lineHeight: "150%"
});

const HIGHLIGHTEDBOXTEXTBODY = css({
    color: colors.darkText,
    fontsize: "14px",
    fontWeight: "bold",
    lineHeight: "150%"
});

const bodyText = "maintenanceBody";
const highlightedTextTitle = "maintenanceHighlightTextTitle";
const highlightedTextBody = "maintenanceHighlightText";

export const MaintenanceScreen = () => {
    return (
        <Screen backgroundImage={backgroundImage} styles={CONTAINERSTYLE}>
            <Dialog
                titleType="image"
                image={dialogTitleImage}
            >
                <div css={DIALOGBODYCONTAINER}>
                    <Text styles={DIALOGBOXTEXT}>{bodyText}</Text>
                    <div css={HIGHLIGHTEDBOXCONTAINER}>
                        <Text styles={HIGHLIGHTEDBOXTEXTTITLE}>{highlightedTextTitle}</Text>
                        <Text styles={HIGHLIGHTEDBOXTEXTBODY}>{highlightedTextBody}</Text>
                    </div>
                    <div css={BUTTONCONTAINER}>
                        <Button label="maintenanceButton1" />
                        <Button label="maintenanceButton2" />
                    </div>
                </div>
            </Dialog>
        </Screen>
    );
};
