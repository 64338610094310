import { unstable_batchedUpdates } from 'react-dom' // or 'react-native'
import { GetState, SetState } from "zustand";
import {
    EMAIL_REQUIRED_ERROR,
    fetchProfileImage, processLink, updateUserProfile,
    watchAuth,
    saveProfileImage,
    uploadProfileImage,
    signOutUser,
    googleSignIn,
    facebookSignIn,
    sendOTP,
    verifyOTP,
    trackEvent,
    trackLogin,
    trackFailedLogin
} from "../services";
import { AppStore } from './appstore';

export const PROFILE_IMAGES = {
    PROFILE1: "default1",
    PROFILE2: "default2",
}

export enum LoginState {
    LOGGED_OUT,
    EMAIL_SENT,
    LOGGING_IN,
    PROFILE_NEEDED,
    LOGGED_IN
}

export enum LinkProcessState {
    PROCESSING,
    ERROR,
    EMAIL_NEEDED,
    DONE,
}

export enum ProfileState {
    LOADING,
    UPDATING,
    DONE
}

export interface UserInfo {
    displayName: string | null
    email: string | null
    photoURL: string | null
    uid: string
}

export interface ImageFile {
    data: string
    file: File
}

export interface ImageInfo {
    file: ImageFile | null,
    url: string | null,
}

export interface UserState {
    currentUser: UserInfo | null
    setCurrentUser: (usr: UserInfo | null) => void

    isSuccessOrder: boolean
    setIsSuccessOrder: (isSuccessOrder: boolean) => void

    loginState: LoginState
    setLoginState: (loginState: LoginState) => void
    signInWithOtp: (subject: string, body: string, email: string) => Promise<string>
    verifyOtp: (id: string, otp: number) => Promise<void>
    cancelOtp: () => void,
    signInWithGoogle: () => Promise<void>
    signInWithFacebook: () => Promise<void>
    signOut: () => Promise<void>

    linkState: LinkProcessState
    processEmailLink: (location: string, email?: string) => Promise<void>

    profileState: ProfileState
    updateProfile: (name: string, image: string | File) => Promise<void>

    profileImage: string,
    fetchProfileImage: () => Promise<void>
}

export const userSelector = (state: UserState) => state.currentUser;
export const isSuccessOrderSelector = (state: UserState) => state.isSuccessOrder;
export const setIsSuccessOrderSelector = (state: UserState) => state.setIsSuccessOrder;
export const loginStateSelector = (state: UserState) => state.loginState;
export const signInSelector = (state: UserState) => state.signInWithOtp;
export const otpVerifySelector = (state: UserState) => state.verifyOtp;
export const otpCancelSelector = (state: UserState) => state.cancelOtp;
export const signInWithGoogleSelector = (state: UserState) => state.signInWithGoogle;
export const signInWithFacebookSelector = (state: UserState) => state.signInWithFacebook;
export const linkStateSelector = (state: UserState) => state.linkState;
export const processLinkSelector = (state: UserState) => state.processEmailLink;
export const profileStateSelector = (state: UserState) => state.profileState;
export const updateProfileSelector = (state: UserState) => state.updateProfile;
export const fetchProfileImagesSelector = (state: UserState) => state.fetchProfileImage;
export const profileImagesSelector = (state: UserState) => state.profileImage;
export const signOutSelector = (state: UserState) => state.signOut;

export const createUserState = (set: SetState<AppStore>, get: GetState<AppStore>): UserState => {

    trackUser(get);

    return {
        currentUser: null,
        setCurrentUser: (currentUser: UserInfo | null) => set({ currentUser }),

        isSuccessOrder: false,
        setIsSuccessOrder: (isSuccessOrder: boolean) => set({ isSuccessOrder }),

        loginState: LoginState.LOGGING_IN,
        setLoginState: (loginState: LoginState) => set({ loginState }),

        signInWithOtp: async (subject: string, body: string, email: string): Promise<string> => {
            try {
                set({ loginState: LoginState.LOGGING_IN });
                const id = await sendOTP(subject, body, email);
                set({ loginState: LoginState.EMAIL_SENT });
                trackEvent("otp_sent");
                return id;
            } catch (ex) {
                set({ loginState: LoginState.LOGGED_OUT });
                throw ex;
            }
        },

        cancelOtp: () => {
            set({ loginState: LoginState.LOGGED_OUT });
        },

        verifyOtp: async (id: string, otp: number): Promise<void> => {
            try {
                await verifyOTP(id, otp);
                set({ loginState: LoginState.LOGGED_IN });
                trackLogin("otp");
            } catch (ex) {
                trackFailedLogin("otp");
                throw ex;
            }
        },

        signInWithGoogle: async () => {
            try {
                set({ loginState: LoginState.LOGGING_IN });
                await googleSignIn();
                set({ loginState: LoginState.LOGGED_IN });
                trackLogin("google");
            } catch (ex) {
                set({ loginState: LoginState.LOGGED_OUT });
                trackFailedLogin("google");
                throw ex;
            }
        },

        signInWithFacebook: async () => {
            try {
                set({ loginState: LoginState.LOGGING_IN });
                await facebookSignIn();
                set({ loginState: LoginState.LOGGED_IN });
                trackLogin("facebook");
            } catch (ex) {
                set({ loginState: LoginState.LOGGED_OUT });
                trackFailedLogin("facebook");
                throw ex;
            }
        },

        signOut: async () => {
            try {
                trackEvent("logout");
                await signOutUser();
                set({ currentUser: null, loginState: LoginState.LOGGED_OUT });
            } catch (ex) {

            }
        },

        linkState: LinkProcessState.PROCESSING,
        processEmailLink: async (location: string, email?: string) => {
            try {
                set({ loginState: LoginState.LOGGING_IN, linkState: LinkProcessState.PROCESSING });
                await processLink(location, email);
                set({ loginState: LoginState.LOGGED_IN, linkState: LinkProcessState.DONE });
            } catch (ex: unknown) {
                if (ex instanceof Error) {
                    if (ex.message === EMAIL_REQUIRED_ERROR) {
                        set({ loginState: LoginState.LOGGED_OUT, linkState: LinkProcessState.EMAIL_NEEDED });
                    }
                    else {
                        throw ex;
                    }
                }
                else throw ex;
            }
        },

        profileState: ProfileState.DONE,
        updateProfile: async (name: string, image: string | File) => {
            try {
                const { currentUser } = get();
                if (!currentUser) {
                    return;
                }
                set({ profileState: ProfileState.UPDATING });
                const imageUrl = await processImage(image);
                await updateUserProfile(name, imageUrl);
                set({ profileState: ProfileState.DONE, currentUser: { ...currentUser, displayName: name, photoURL: imageUrl } });
                trackEvent("update_profile");
            } catch (ex: unknown) {
                set({ profileState: ProfileState.DONE });
                throw ex;
            }
        },

        profileImage: "",
        fetchProfileImage: async () => {
            try {
                set({ profileState: ProfileState.LOADING });
                const profileImage = await fetchProfileImage();
                console.log(profileImage);
                set({ profileImage, profileState: ProfileState.DONE });
            } catch (ex: unknown) {
                set({ profileState: ProfileState.DONE });
            }
        },
    }
};

const processImage = async (image: File | string) => {
    if (image instanceof File) {
        const url = await uploadProfileImage(image);
        await saveProfileImage(url);
        return url;
    }

    return image;
};

const trackUser = (getState: () => UserState) => {
    watchAuth(usr => {
        unstable_batchedUpdates(() => {
            let userInfo = null;
            if (usr !== null) {
                userInfo = {
                    displayName: usr.displayName,
                    email: usr.email,
                    photoURL: usr.photoURL,
                    uid: usr.uid,

                };
            }
            getState().setCurrentUser(userInfo)
            getState().setLoginState(usr !== null ? LoginState.LOGGED_IN : LoginState.LOGGED_OUT);
        });
    });
};
