/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { GameContainer } from "../../components";
import { BirdTutorialScreen } from "../../components";

const CONTAINER = css({
    display: 'flex',
    height: '100%',
    width: '100%'
});

const BIRD = css({
    bottom: 100,
});

const game = {
    id: "0",
    name: "Swipe It",
    url: "https://battle-dream-dev-swipe-it.web.app/?lang=en",
    icon: "/images/icon_game_0002_swipe-it.png"
};

const onboardingText = "ONBOARDING_RESULT_TEXT";
const onboardingButtonText = "WALLET_BUTTON_NEXT";

export const OnboardingPlayGame = () => {
    const history = useHistory();
    const [score, setScore] = useState(-1);
    const onEnd = useCallback(async (score: number) => {
        try {
            // await submitScore(score);
            setScore(score);
        } catch (ex) {
            // switch to error screen
            console.log(ex);
        }
    }, []);

    return (
        <div css={CONTAINER}>
            <GameContainer game={game} onEnd={onEnd} />
            {score > -1 ? <BirdTutorialScreen
                onBoardingText={onboardingText}
                hasButton={true}
                buttonText={onboardingButtonText}
                styles={BIRD}
                onClick={() => history.replace(`/onboardingresult`, { score })}
            /> : null}
        </div>
    );
};
