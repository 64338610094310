/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { InfoCount } from "./info-count";
import ticketImage from "../../assets/images/info/Ticket.png";
import coinImage from "../../assets/images/info/Coin.png";
import menuImage from "../../assets/images/info/Menu.png";
import { spacing } from "../../config";
import {
  coinSelector,
  ticketSelector,
  useStore,
} from "../../models";
import { useHistory } from "react-router-dom";
import { Image } from "../image/image";

const common = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const CONTAINERSTYLE = [
  common,
  css({
    paddingRight: spacing[3],
    paddingLeft: spacing[3],
    paddingTop: spacing[4],
    paddingBottom: spacing[4],
    height: spacing[6],
  }),
];

const INFOCONTAINER = [
  common,
  css({
    flex: 1,
  }),
];

const BUTTONCONTAINER = [common, css({})];

const MENUSTYLE = css({
  width: spacing[7],
  marginLeft: spacing[3],
});

export const InfoBar = () => {
  const coinCount = useStore(coinSelector);
  const ticketCount = useStore(ticketSelector);

  const history = useHistory();

  return (
    <div css={CONTAINERSTYLE}>
      <div css={INFOCONTAINER}>
        <InfoCount
          image={ticketImage}
          count={ticketCount}
          width={27}
          height={20}
          onAdd={() => history.push("/store")}
        />
        <InfoCount
          image={coinImage}
          count={coinCount}
          width={21}
          height={23}
          onAdd={() => history.push("/wallet")}
        />
      </div>
      <div css={BUTTONCONTAINER}>
        <Image
          src={menuImage}
          styles={MENUSTYLE}
          onClick={() => history.push("/menu")}
        />
      </div>
    </div>
  );
};
