/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { Text } from "../../components/text/text";
import { spacing, colors } from "../../config";
import bird from "../../assets/images/BattlyA.gif";
import chatBubble from "../../assets/images/image.png";
import { Button } from "../button/button";
import { Image } from "../image/image";

const CONTAINERSTYLE = css({
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    left: -80,
    zIndex: 30,
});

const BIRD = css({
    width: 243,
    height: 153,
});

const CHATBOX = css({
    width: 190,
    height: 68,
    background: `url(${chatBubble})`,
    backgroundSize: "cover",
    marginLeft: -70,
    padding: spacing[2],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: spacing[8],
});

const CHATBOXCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
});

const BUTTON = css({
    marginTop: spacing[8],
});

export interface BirdTutorialScreenProps {
    onBoardingText: string;
    styles?: SerializedStyles;
    hasButton?: boolean;
    buttonText?: string;
    onClick?: () => void;
}

export const BirdTutorialScreen = ({
    onBoardingText,
    styles,
    hasButton = false,
    buttonText = "",
    onClick
}: BirdTutorialScreenProps) => {
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <Image src={bird} alt="" styles={BIRD} />
            <div css={CHATBOXCONTAINER}>
                <div css={CHATBOX}>
                    <Text color={colors.darkText} size={13} align="left">
                        {onBoardingText}
                    </Text>
                </div>
                {hasButton ? (
                    <Button label={buttonText} styles={BUTTON} onPress={onClick} />
                ) : (
                        <div></div>
                    )}
            </div>
        </div>
    );
};
