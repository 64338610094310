/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { spacing } from "../../config";
import { Text } from "../text/text";
import { Button } from "../button/button";
import { CircleIndicator } from "../loader/loading-indicator";

const MATCHING = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
});

const LOADER = css({
    width: 50,
    marginTop: spacing[7],
    marginBottom: spacing[7],
    height: 50,
});

const CANCELBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
    marginTop: spacing[4],
});

const noop = () => { };

const BATTLE_MATCH_TEXT_MATCHING = "BATTLE_MATCH_TEXT_MATCHING";
const BATTLE_BUTTON_CANCEL = "PROFILE_BUTTON_CANCEL";


export const Matching = ({ hideCancel = false, onCancel = noop }: { hideCancel?: boolean, onCancel?: () => void }) => {
    return (
        <div css={MATCHING}>
            {/* Matching State */}
            <Text size={22}>{BATTLE_MATCH_TEXT_MATCHING}</Text>
            <div css={LOADER}>
                <CircleIndicator />
            </div>
            {!hideCancel ? <Button label={BATTLE_BUTTON_CANCEL} styles={CANCELBUTTON} onPress={onCancel} /> : null}
        </div>
    );
};
