/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';
import focusBg from '../../assets/images/tabs/FocusBackground.png';
import { spacing } from '../../config';
import { Image } from '../image/image';

const CONTAINERSTYLE = css({
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
});

const ICONSTYLE = css({
    width: 30,
    height: 'auto',
    zIndex: 2,
});

const SHADOWBOX = css({
    height: 30,
    width: 30,
    backgroundSize: 'cover',
    padding: spacing[4],
    paddingLeft: spacing[5],
    paddingBottom: spacing[5],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
});

export interface NavIconProps {
    icon: string
    navTo: string
}

export const NavIcon = ({ icon, navTo }: NavIconProps) => {

    const history = useHistory();
    const location = useLocation();

    const onClick = () => history.push(navTo);

    const isSelected = location.pathname === navTo;

    const bgStyle = isSelected ? css({
        backgroundImage: `url(${focusBg})`,
    }) : null;

    return (
        <div css={[CONTAINERSTYLE]} onClick={onClick}>
            <div css={[SHADOWBOX, bgStyle]} onClick={onClick}>
                <Image styles={ICONSTYLE} src={icon} />
            </div>
        </div>
    )
};
