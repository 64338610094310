/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from "react";
import { css } from "@emotion/react";
import {
  GameInfoBar,
  ProfilePhoto,
  Text,
  ProfileMenuItem,
  NonTranslatedText,
} from "../../components";
import { spacing } from "../../config";
import copyIcon from "../../assets/images/Copy.png";
import { useHistory } from "react-router-dom";
import {
  PROFILE_IMAGES,
  userSelector,
  useStore,
} from "../../models";
import toast from 'react-hot-toast';
import { LogoutPopup } from "../../components/signin/logout-popup";
import { Share } from "../../components/menu/share";
import { Image } from "../../components/image/image";
import { trackEvent } from "../../services";
import { useTranslation } from "react-i18next";
import { onShare } from "../../services/share";
const shareURL = process.env.REACT_APP_SHARE_URL ?? "";
// const facebookURL = process.env.REACT_APP_FACEBOOK_URL;
const privacyURL = process.env.REACT_APP_PRIVACY_URL;
const tosURL = process.env.REACT_APP_TOS_URL;

const CONTAINERSTYLE = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

const SCROLLCONTAINER = css({
  overflow: "auto",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const SCROLLCONTENT = css({
  display: "flex",
  flexDirection: "column",
  paddingBottom: spacing[8],
});

const PROFILEINFO = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: spacing[5],
});

const PROFILEDETAIL = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: spacing[5],
  flex: 1,
});

const NAME = css({
  lineHeight: "150%",
});

const USERID = css({
  lineHeight: "150%",
  marginLeft: spacing[2],
});

const IDCONTAINER = css({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: spacing[5],
});

const IDBOX = css({
  flex: 1,
  display: "flex",
});

const menu = "MENU_TITLE_MENU";
const userID = "userID";
const editYourProfile = "MENU_BUTTON_EDIT_PROFILE";
const dreamBattleFAQ = "MENU_BUTTON_HOW_TO_PLAY";
const inviteFriends = "MENU_BUTTON_INVITE";
// const officialFacebook = "MENU_BUTTON_FACEBOOK";
const privacyPolicy = "ABOUT_BUTTON_PRIVACY_POLICY";
const tos = "ABOUT_BUTTON_TERMS_OF_SERVICE";
const logout = "MENU_BUTTON_LOGOUT";
const shareInfo = "SHARE_CONTENT";

export interface ProfilePageProps { }

const COPYICON = css({
  height: 17,
  width: 17
})

export const MenuScreen = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentUser = useStore(userSelector);
  const [openPopup, setopenPopup] = useState(false)
  const [showModal, setShowModal] = useState(false);


  const editProfile = () => history.push("/edit-profile");
  const onPrivacy = () => window.open(privacyURL, '_blank');
  const onTos = () => window.open(tosURL, '_blank');

  const name = currentUser?.displayName;
  const uid = currentUser?.uid;
  const displayImage = currentUser?.photoURL ?? PROFILE_IMAGES.PROFILE1;

  const copyToClipboard = () => {
    if (uid !== undefined) {
      navigator.clipboard.writeText(uid);
      toast.dismiss();
      notify();
    }
  };

  const notify = () => toast.success('Copied!', {
    id: 'clipboard',
  });

  const signOutDialog = () => {
    setopenPopup(true);
  };

  // const openBDFacebook = () => {
  //   // TODO: Replace BD facebook page address
  //   let url = facebookURL;
  //   window.open(url, '_blank');
  // }

  const shareData = useMemo(() => {
    return {
      text: t(shareInfo),
      url: shareURL,
      title: "Akiverse",
    };
  }, [t]);

  const onShareClick = () => {
    onShare(shareData);
    trackEvent("share", { content_type: "app" })
  };

  return (
    <div css={CONTAINERSTYLE}>
      <LogoutPopup open={openPopup} setOpen={setopenPopup} />
      <GameInfoBar
        title={menu}
        showBack
        onBack={() => history.goBack()}
      />
      <div css={SCROLLCONTAINER}>
        <div css={SCROLLCONTENT}>
          <div css={PROFILEINFO}>
            <ProfilePhoto image={displayImage} />
            <div css={PROFILEDETAIL}>
              <NonTranslatedText align="left" size={16} styles={NAME}>
                {name}
              </NonTranslatedText>
              <div css={IDCONTAINER}>
                <div css={IDBOX}>
                  <Text align="left" size={7} styles={NAME}>
                    {userID}
                  </Text>
                  <NonTranslatedText align="left" size={7} styles={USERID}>
                    {uid}
                  </NonTranslatedText>
                </div>
                <Image
                  styles={COPYICON}
                  src={copyIcon}
                  onClick={copyToClipboard}
                />
              </div>
            </div>
          </div>

          <ProfileMenuItem menuText={editYourProfile} click={editProfile} />
          <ProfileMenuItem menuText={dreamBattleFAQ} click={() => history.replace("/onboardingbattle")} />
          <ProfileMenuItem menuText={inviteFriends} click={onShareClick} />
          {/*<ProfileMenuItem menuText={officialFacebook} click={openBDFacebook} />*/}
          <ProfileMenuItem menuText={privacyPolicy} click={onPrivacy} />
          <ProfileMenuItem menuText={tos} click={onTos} />
          <ProfileMenuItem menuText={logout} click={signOutDialog} />
        </div>
      </div>
      <Share open={showModal} setOpen={setShowModal} />
    </div>
  );
};
