/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { Text } from "../text/text";
import { colors } from "../../config";
import { Image } from "../image/image";

const noop = () => { };

const BUTTON = css({
    background: "linear-gradient(90deg, #D253FC 0%, #7B0FFB 100%)",
    height: "37px",
    width: "100px",
    borderRadius: "4px",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
    textShadow:
        "-1px 1px 0 #000000, 1px 1px 0 #000000, 1px -1px 0 #000000, -1px -1px 0 #000000",
    "&:hover": {
        transform: "scale(0.95)",
        opacity: 0.8,
    },
});

const SOCIALICONS = css({
    display: "flex",
    width: 30,
    height: 30,
});

const TEXT = css({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

export interface ButtonProps {
    as?: React.ElementType<any>;
    label: string;
    disabled?: boolean;
    onPress?: () => void;
    styles?: SerializedStyles;
    buttonBackground?: string;
    icon?: string;
}

export const Button = ({
    as = "div",
    label,
    onPress = noop,
    disabled = false,
    styles,
    buttonBackground,
    icon,
}: ButtonProps) => {
    const Component = as;

    // TODO: Replace audio file with correct audio file
    let audio = new Audio("/click.mp3")

    const buttonClick = () => {
        if (disabled) { return };
        audio.play();
        onPress();
    }

    const override = css({
        background: disabled ? colors.disableButtonGradient : buttonBackground,
    });

    return (
        <Component css={[BUTTON, styles, override]} onClick={buttonClick}>
            {icon ? <Image src={icon} styles={SOCIALICONS} /> : <span></span>}
            <Text styles={TEXT}>{label}</Text>
        </Component>
    );
};
