/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Modal from "react-modal";
import { colors, spacing } from "../../config";
import { Text } from "../text/text";
import { Button } from "../button/button";
import { signOutSelector, useStore } from "../../models";
import toast from "react-hot-toast";

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
});

const BUTTONCONTAINER = css({
    display: "flex",
    marginTop: spacing[6],
});

const BUTTON = css({
    margin: spacing[2],
    display: "flex",
    flex: 1,
});

const BUTTON2 = css({
    margin: spacing[2],
    display: "flex",
    flex: 1,
    background: colors.saveButtonGradient,
});

export interface LogoutPopupProps {
    open: boolean;
    setOpen: (b: boolean) => void
}

const logout = "MENU_BUTTON_LOGOUT";
const logoutConfirmationMessage = "MENU_BUTTON_LOGOUT_CONFIRMATION";
const cancel = "PROFILE_BUTTON_CANCEL";

export const LogoutPopup = ({ open, setOpen }: LogoutPopupProps) => {
    const signOut = useStore(signOutSelector);

    const cancelButton = () => {
        setOpen(false);
    }

    const onYes = () => {
        notify();
        signOut();
    };

    const notify = () => toast.success('Logged Out!', {
        id: 'logout',
    });

    return (
        <Modal
            isOpen={open}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 10,
                },
                content: {
                    backgroundColor: colors.darkText,
                    borderRadius: spacing[3],
                    borderWidth: 2,
                    borderColor: colors.primary,
                    color: colors.lightText,
                    bottom: "auto",
                    left: "50%",
                    position: "fixed",
                    right: "auto",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    overflow: "visible",
                },
            }}
        >
            <div css={CONTAINER}>
                <Text size={14}>
                    {logoutConfirmationMessage}
                </Text>
                <div css={BUTTONCONTAINER}>
                    <Button label={cancel} styles={BUTTON} onPress={cancelButton} />
                    <Button label={logout} styles={BUTTON2} onPress={onYes} />
                </div>
            </div>
        </Modal>
    );
};
