/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../config";
import { Text } from "../text/text";
import { ProfilePhoto } from "../profile-photo/profile-photo";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    width: "100%"
});

const PLAYER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
});

const USERNAMEBOX = css({
    display: "flex",
    flexDirection: "row",
    background: colors.darkText,
    height: 50,
    transform: "rotate(-15deg)",
    marginLeft: -15,
    marginRight: -15,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: colors.lightText,
    alignItems: "center",
});

const PROFILEPHOTO = css({
    width: 126,
    height: 126,
});

const YOUTEXT = css({
    transform: "rotate(-15deg)",
    textAlign: "left",
    marginTop: spacing[10],
    marginLeft: spacing[4],
});

export interface BattlePlayerProps {
    styles?: SerializedStyles;
    username: string;
    usernameColor?: string;
    profileImage?: string;
    profilePhotoStyle: SerializedStyles;
    usernameBoxStyle: SerializedStyles;
    usernameTextStyle: SerializedStyles;
    profileContainerStyle: SerializedStyles;
    name: string;
    profileImageBackground: string
}

export const BattlePlayer = ({
    styles,
    username,
    usernameColor,
    profileImage,
    profilePhotoStyle,
    usernameBoxStyle,
    usernameTextStyle,
    profileContainerStyle,
    name,
    profileImageBackground
}: BattlePlayerProps) => {
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <div css={PLAYER}>
                <Text size={16} styles={YOUTEXT} color={colors.yellowText}>
                    {name === "" ? "" : name}
                </Text>
                <div css={profileContainerStyle}>
                    <ProfilePhoto
                        styles={[PROFILEPHOTO, profilePhotoStyle]}
                        image={profileImage === "" ? defaultIcon1 : profileImage}
                        profileImageBackground={profileImageBackground}
                    />
                </div>
                <div css={[USERNAMEBOX, usernameBoxStyle]}>
                    <Text size={20} styles={usernameTextStyle} color={usernameColor}>
                        {username}
                    </Text>
                </div>
            </div>
        </div>
    );
};
