/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { NonTranslatedText, Text } from "../../components/text/text";
import { spacing, colors } from "../../config";
import { Button } from "../../components";
import { useHistory } from "react-router-dom";
import terms from "../../assets/terms.json";

const CONTAINERSTYLE = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  backgroundColor: colors.darkText,
  padding: spacing[5],
  alignItems: "center",
});

const TOPCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  marginTop: spacing[2],
  paddingTop: spacing[2],
  overflow: "scroll",
});

const BUTTON = css({
  display: "flex",
  marginLeft: spacing[2],
  marginRight: spacing[2],
});

const TERMS = css({
  display: "flex",
  alignSelf: "stretch",
  marginTop: spacing[2],
  marginBottom: spacing[2],
  overflow: "scroll",
  paddingRight: spacing[5],
  paddingLeft: spacing[5],
  lineHeight: "130%",
});

const officialNoteTitle = "OFFICIAL_NOTE_TITLE";

export const OfficialNote = () => {
  const history = useHistory();

  const goBack = () => {
      history.goBack();
  };

  return (
    <div css={CONTAINERSTYLE}>
        <div css={TOPCONTAINER}>
          <Text size={20}>{officialNoteTitle}</Text>
          <div css={TERMS}>
            <NonTranslatedText size={12} align="justify">
              {terms.text}
            </NonTranslatedText>
          </div>
        </div>
        <Button onPress={goBack} label="Go Back" styles={BUTTON} />
    </div>
  );
};
