/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors } from '../../config';
import storeIcon from '../../assets/images/tabs/Store.png';
import battleIcon from '../../assets/images/tabs/Battle.png';
import walletIcon from '../../assets/images/tabs/Wallet.png';
import { NavIcon } from './nav-icon';

const CONTAINERSTYLE = css({
    alignSelf: 'stretch',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    background: colors.bottomNavGradient,
    height: "72px"
});

export const NavBar = () => {
    return (
        <div css={CONTAINERSTYLE}>
            <NavIcon icon={storeIcon} navTo="/store" />
            <NavIcon icon={battleIcon} navTo="/battle" />
            <NavIcon icon={walletIcon} navTo="/wallet" />
        </div>
    );
};
