/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GameInfoBar } from "../../components/info-bar/game-info-bar";
import { useTranslation } from "react-i18next";
import { NotificationItem } from "../../components/notifications/notification-item";

const CONTAINERSTYLE = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const NOTIFICATIONS_CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    scrollable: true,
    overflowY: "auto",
});

const notificationTitle = "NOTIFICATION_TITLE";

export const NotificationsScreen = () => {
  const { t } = useTranslation();

  return (
    <div css={CONTAINERSTYLE}>
      <GameInfoBar
        title={t(notificationTitle)}
        showBack={true}
      />
      <div css={NOTIFICATIONS_CONTAINER}>
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
      </div>
    </div>
  );
};
