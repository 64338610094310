import { GetState, SetState } from "zustand";
import { BattleResult, finishBattle, initiateBattle, waitForResult, BattleState as BT, cancelBattle, trackEvent } from "../services";
import { AppStore } from './appstore';

export type BattleOpponent = {
    name: string
    photo: string
    gameId: string
    liveId: string
    battleId: string
    score: number
}

export interface BattleState {
    battleLoading: boolean
    battleError: Error | null
    opponent: BattleOpponent | null
    battleResult: BattleResult | null
    startBattle: (battleId: string) => Promise<() => void>
    submitBattleScore: (score: number, opponentScore: number, gameId: string) => Promise<void>
    watchResult: () => Promise<() => void>
    cancelBattle: () => Promise<void>
    resetBattle: () => void
}

const noop = () => { };

export const battleLoadingSelector = (state: BattleState) => state.battleLoading;
export const battleErrorSelector = (state: BattleState) => state.battleError;
export const battleOpponentSelector = (state: BattleState) => state.opponent;
export const startBattleSelector = (state: BattleState) => state.startBattle;
export const submitBattleScoreSelector = (state: BattleState) => state.submitBattleScore;
export const battleResultSelector = (state: BattleState) => state.battleResult;
export const watchResultSelector = (state: BattleState) => state.watchResult;
export const resetBattleSelector = (state: BattleState) => state.resetBattle;
export const cancelBattleSelector = (state: BattleState) => state.cancelBattle;

export const createBattleState = (set: SetState<AppStore>, get: GetState<AppStore>): BattleState => {

    return {
        battleLoading: false,
        battleError: null,
        opponent: null,
        battleResult: null,
        resetBattle: () => set({ battleLoading: false, battleError: null, opponent: null, battleResult: null }),
        cancelBattle: async (): Promise<void> => {
            await cancelBattle();
            set({ battleLoading: false, battleError: null, opponent: null, battleResult: null });
        },
        startBattle: async (battleId: string) => {
            const { currentTickets, battles } = get();

            const currentBattle = battles.find(b => b.id === battleId);
            if (!currentBattle) {
                throw new Error("failed to find battle");
            }

            if (currentTickets < currentBattle.cost) {
                throw new Error("not enough coins");
            }

            const { cost, prize, battleType } = currentBattle;

            set({ battleLoading: true, battleError: null, opponent: null });
            try {
                const { opponent } = await initiateBattle(battleId);
                set({ opponent, battleLoading: false });
                trackEvent("initiate_battle", { battleId, prize, cost, battleType });
                return () => { };
            } catch (ex: unknown) {
                set({ battleLoading: false, battleError: ex as Error });
                throw new Error("failed to start battle");
            }
        },
        submitBattleScore: async (score: number, opponentScore: number, gameId: string) => {
            const { opponent } = get();
            if (!opponent) {
                throw new Error("no ongoing battle");
            }
            try {
                const { battleId } = opponent;
                const { result } = await finishBattle(opponent.battleId, opponent.liveId, score, opponentScore, gameId);
                trackEvent("submit_battle", { battleId, score });
                if (result && result.state === BT.WIN) {
                    trackEvent("earn_virtual_currency", {
                        virtual_currency_name: result.prizeType,
                        value: result.prize
                    });
                }
                set({ battleResult: result });
            }
            catch (ex: any) {
                console.log(ex);
                set({ battleError: ex });
            }
        },

        watchResult: async () => {
            const { battleResult, opponent, refreshWallet } = get();
            if (!opponent) {
                throw new Error("no ongoing battle");
            }
            if (battleResult && battleResult.state !== BT.LIVE) {
                await refreshWallet();
                return noop;
            }
            const unsub = await waitForResult(opponent.liveId, result => {
                refreshWallet();
                set({
                    battleResult: result
                });
                trackEvent("earn_virtual_currency", {
                    virtual_currency_name: result.prizeType,
                    value: result.prize
                });
                unsub();
            });
            return unsub;
        }
    };
};
