/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TournamentCard } from '../../components/cards/tournament/tournamentCard';
import { spacing } from '../../config';
import { Banner } from '../../components/cards/banner/banner';
import gameCardBackgroundImage from "../../assets/images/4096.png";
import { AdCarousel } from '../../components/cards/banner/carousel';

const CONTAINERSTYLE = css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing[3],
    paddingRight: spacing[3],
});

export const HomeScreen = () => {

    const caurouselItems = [
        <Banner bannerImage={gameCardBackgroundImage} bannerText="" key={1} />,
        <Banner bannerText="HELLO" key={2} />,
        <Banner bannerImage={gameCardBackgroundImage} bannerText="" key={3} />,
        <Banner bannerText="HELLO" key={4} />
    ]

    return (
        <div css={CONTAINERSTYLE}>
            <AdCarousel>
                {caurouselItems}
            </AdCarousel>
            <TournamentCard />
        </div>
    )
};
