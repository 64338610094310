/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import versusImage from "../../assets/images/battleModes/BattleVS.png";
import { colors, spacing } from "../../config";
import { Matching, BattlePlayer, Screen } from "../../components";
import firstUserProfileBackground from "../../assets/images/BattleModeOpponentUserIconBg.png";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";
import secondUserProfileBackground from "../../assets/images/BattleModeUserIconBg.png";
import { useHistory } from "react-router-dom";
import { getRandomName } from "../../services/random-user";
import { userSelector, useStore } from "../../models";
import { Image } from "../../components/image/image";

const VERSUS = css({
    display: "flex",
    flexDirection: "column",
    height: 130,
    alignSelf: "center",
});

const SECONDPROFILEPHOTOCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    zIndex: 10,
});

const FIRSTPROFILEPHOTOCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    zIndex: 10,
});

const FIRSTUSERUSERNAMEBOX = css({
    marginTop: -60,
    justifyContent: "flex-end",
});

const SECONDUSERUSERNAMEBOX = css({
    marginTop: -130,
});

const FIRSTUSERUSERNAMETEXT = css({
    marginRight: spacing[8],
});

const SECONDUSERUSERNAMETEXT = css({
    marginLeft: spacing[8],
});

const FIRSTUSERPROFILEPHOTO = css({
    marginLeft: spacing[4],
});

const SECONDUSERPROFILEPHOTO = css({
    marginRight: spacing[4],
    marginTop: spacing[4],
});

const CONTAINER = css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
});

const VERSUSIMAGE = css({
    width: 173,
    height: 130,
})

export const OnboardingMatchMatching = () => {
    const history = useHistory();
    const picture = defaultIcon1;

    const [opponent, setOpponent] = useState<string | null>(null);

    const user = useStore(userSelector);
    const username = user ? user?.displayName ?? "You" : "You";

    useEffect(() => {
        let opponentTimeout = window.setTimeout(() => {
            setOpponent(getRandomName());
        }, 1000);

        let battleTimeout = window.setTimeout(() => {
            history.push("/onboardinggameloading");
        }, 5000);

        return () => {
            clearTimeout(opponentTimeout);
            clearTimeout(battleTimeout);
        };
    }, [history]);

    return (
        <Screen backgroundImage="./matching.webp">
            <div css={CONTAINER}>
                {opponent ? (
                    <BattlePlayer
                        username={opponent}
                        profileContainerStyle={FIRSTPROFILEPHOTOCONTAINER}
                        profileImage={defaultIcon1}
                        profilePhotoStyle={FIRSTUSERPROFILEPHOTO}
                        usernameBoxStyle={FIRSTUSERUSERNAMEBOX}
                        usernameTextStyle={FIRSTUSERUSERNAMETEXT}
                        name=""
                        profileImageBackground={firstUserProfileBackground}
                    />
                ) : (
                    <Matching hideCancel />
                )}
                <div css={VERSUS}>
                    <Image src={versusImage} styles={VERSUSIMAGE} />
                </div>
                <BattlePlayer
                    name="YOU"
                    username={username}
                    profileContainerStyle={SECONDPROFILEPHOTOCONTAINER}
                    profileImage={picture}
                    profilePhotoStyle={SECONDUSERPROFILEPHOTO}
                    usernameBoxStyle={SECONDUSERUSERNAMEBOX}
                    usernameTextStyle={SECONDUSERUSERNAMETEXT}
                    usernameColor={colors.yellowText}
                    profileImageBackground={secondUserProfileBackground}
                />
            </div>
        </Screen>
    );
};
