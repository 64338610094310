/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from "@emotion/react";
import { spacing } from '../../../config';
import { NonTranslatedText } from '../../text/text';

const TIMEBOX = css({
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

const TIMETEXT = css({
    paddingRight: spacing[2],
    paddingLeft: spacing[2],
});

export interface TimeBoxProps {
  styles?: SerializedStyles,
  time?: string
}

export const TimeBox = ({ styles, time }: TimeBoxProps) => {
  return (
    <div css={[TIMEBOX, styles]}>
      <NonTranslatedText styles={TIMETEXT}>{time}</NonTranslatedText>
    </div>
  );
};
