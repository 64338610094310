/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import { Image } from '../image/image';

export interface DialogImageTitleProps {
    image: string
    width?: number
    height?: number
}

export const DialogImageTitle = ({ image, width = 186, height = 186 }: DialogImageTitleProps) => {

    const style = css({
        width: width,
        height: height,
        position: "relative",
        top: "-130px",
    });

    return <Image src={image} styles={style} alt="dialog-title" />
};
