/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import { colors, spacing } from '../../config';
import { DialogTitle } from './dialog-title';

const CONTAINERSTYLE = css({
    display: 'flex',
    flexDirection: 'column',
    borderColor: colors.dialogBorder,
    borderRadius: spacing[3],
    borderWidth: 2,
    borderStyle: 'solid',
    width: '80%',
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "80px"
});

const CONTENTSTYLE = css({
    padding: spacing[4],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
});

export interface DialogProps {
    children: React.ReactNode
    titleType?: "image" | "text" | "banner"
    titleText?: string
    image?: string
}

export const Dialog = ({ children, image = "../../assets/images/MaintenanceImage.png" }: DialogProps) => {
    return (
        <div css={CONTAINERSTYLE}>
            <DialogTitle image={image} />
            <div css={CONTENTSTYLE}>
                {children}
            </div>
        </div>
    );
};
