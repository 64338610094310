/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { spacing, colors } from "../../config";
import { NonTranslatedText, Text } from "../text/text";
import { Button } from "../button/button";
import loseImage from "../../assets/images/battleModes/BattleLose.png";
import drawImage from "../../assets/images/battleModes/BattleDraw.png";
import coinImage from "../../assets/images/info/Coin.png";
import { ProfilePhoto } from "../profile-photo/profile-photo";
import { BattleResult, BattleState, Participant } from "../../services";
import { resetBattleSelector, userSelector, useStore } from "../../models";
import { useHistory } from "react-router-dom";
import { onShare } from "../../services/share";

const winImage = "./win.webp";
const shareURL = process.env.REACT_APP_SHARE_URL ?? "";

const CONTAINER = css({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "space-around",
  paddingBottom: 40,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});

const TITLEIMAGE = css({
  maxHeight: 88,
  maxWidth: 278,
});

const WINBOX = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 100,
});

const EMPTYPOINTCONTAINER = css({
  height: 50,
});

const COINIMAGE = css({
  display: "flex",
  height: 50,
  width: 50,
  marginRight: -20,
  zIndex: 2,
});

const SCOREBOX = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: 211,
  height: 38,
  background: colors.victoryBox,
});

const WINREWARD = css({
  marginLeft: spacing[8],
});

const PARTICIPANTCONTAINER = css({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
});

const PROFILEIMAGES = css({
  marginLeft: spacing[1],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 60
});

const PROFILE = css({
  display: "flex",
  flexDirection: "row",
  alignSelf: "stretch",
});

const POINTBOXES = css({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "stretch",
});

const POINTBOX = css({
  display: "flex",
  flexDirection: "column",
  height: 90,
  flex: 1,
  background: "rgba(0, 0, 0, 0.8)",
  borderRadius: spacing[2],
  borderWidth: 2,
  borderColor: colors.primary,
  borderStyle: "solid",
  marginLeft: spacing[4],
  marginRight: spacing[4],
});

const SELFPOINTBOX = css({
  display: "flex",
});

const BUTTONCONTAINER = css({
  display: "flex",
  alignSelf: "stretch",
  flexDirection: "column",
  alignItems: "center",
});

const BUTTON = css({
  display: "flex",
  width: "auto",
  paddingLeft: spacing[5],
  paddingRight: spacing[5],
  marginLeft: spacing[3],
  marginRight: spacing[3],
  marginBottom: spacing[5],
});

const USERNAME = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 30,
  borderBottomColor: colors.primary,
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
});

const POINTTEXT = css({
  display: "flex",
  marginLeft: spacing[3],
});

const CURRENTPOINTSCONTAINER = css({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
});

const SHAREBUTTON = css({
  display: "flex",
  padding: 0,
  marginLeft: spacing[3],
  marginRight: spacing[3],
  flex: 1,
  border: 'none',
  backgroundColor: 'transparent'
});


export interface ResultProps {
  result: BattleResult;
  participants: Participant[];
  battleId: string;
}

const returnHome = "BATTLE_RESULT_BUTTON_RETURN_HOME";
const point = "BATTLE_TEXT_POINT";
const share = "SHARE_BUTTON_SHARE";

const GetResultImage = (status: BattleState) => {
  switch (status) {
    case BattleState.LOSE:
      return loseImage;

    case BattleState.DRAW:
      return drawImage;

    case BattleState.WIN:
      return "";

    default:
      return "";
  }
};

const ParticipantInfo = ({
  participant,
  score,
  showCrown,
}: {
  participant: Participant;
  score: number;
  showCrown: boolean;
}) => {
  console.log(participant.photo);
  return (
    <div css={PARTICIPANTCONTAINER}>
      <div css={PROFILEIMAGES}>
        <ProfilePhoto image={participant.photo} crownImage={showCrown} />
      </div>
      <div css={POINTBOXES}>
        <div css={[SELFPOINTBOX, POINTBOX]}>
          <Text size={12} color={colors.yellowText} styles={USERNAME}>
            {participant.name}
          </Text>
          <div css={CURRENTPOINTSCONTAINER}>
            <NonTranslatedText size={28} color={colors.yellowText}>
              {score}
            </NonTranslatedText>
            <Text size={20} color={colors.yellowText} styles={POINTTEXT}>
              {point}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Result = ({ result, participants }: ResultProps) => {
  const status = result.state;
  const winPoints = result.prize;
  let resultImage = GetResultImage(status);
  const user = useStore(userSelector);
  const history = useHistory();
  const reset = useStore(resetBattleSelector);

  if (!user) {
    return null;
  }

  const pList = participants.map((p) => {
    if (p.uid === user.uid) {
      return {
        ...p,
        score: result.ownScore,
        isWinner: result.state === BattleState.WIN,
      };
    } else {
      return {
        ...p,
        score: result.opponentScore,
        isWinner: result.state === BattleState.LOSE,
      };
    }
  });

  const goToBattle = () => {
    reset();
    history.replace("/battle");
  };

  const winBg = status === BattleState.WIN ? { backgroundImage: `url(${winImage})` } : {};

  const shareWithFriends = () => onShare({
    text: "Come play Akiverse with me!",
    url: shareURL,
    title: "Akiverse",
  });

  return (
    <div css={[CONTAINER, winBg]}>
      <img src={resultImage} alt="" css={TITLEIMAGE} />
      {status === BattleState.WIN ? (
        <div css={WINBOX}>
          <img src={coinImage} alt="" css={COINIMAGE} />
          <div css={SCOREBOX}>
            <Text styles={WINREWARD} color={colors.yellowText} size={30}>
              {winPoints.toString()}
            </Text>
          </div>
        </div>
      ) : (
          <div css={EMPTYPOINTCONTAINER}></div>
        )}
      <div css={PROFILE}>
        {pList.map((p) => (
          <ParticipantInfo
            participant={p}
            score={p.score}
            showCrown={p.isWinner}
            key={p.uid}
          />
        ))}
      </div>
      <div css={BUTTONCONTAINER}>
        <Button styles={BUTTON} label={returnHome} onPress={goToBattle} />
        <Button styles={SHAREBUTTON} label={share} onPress={shareWithFriends} />
      </div>
    </div>
  );
};
