/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import { Text } from "../text/text";

const CONTAINERSTYLE = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignSelf:'stretch',
  height: 64,
  width: "auto",
  background: colors.darkText,
  margin: spacing[4],
  marginTop: spacing[0],
  borderRadius: spacing[3],
  borderColor: colors.primary,
  borderWidth: 2,
  borderStyle: "solid",
  paddingLeft: spacing[6],
  paddingRight: spacing[6],
});

export interface ProfileMenuItemProps {
  menuText: string;
  click?: () => void;
}

export const ProfileMenuItem = ({ menuText, click }: ProfileMenuItemProps) => {
  return (
    <button css={CONTAINERSTYLE} onClick={click}>
      <Text size={20} align="left">{menuText}</Text>
    </button>
  );
};
