/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import { Text, GameInfoBar, ShopTicket, NonTranslatedText } from "../../components";
import ticketImage from "../../assets/images/info/Ticket.png";
import { shopListSelector, ticketSelector, useStore } from "../../models";
import { SCROLLCONTAINER } from "../battle/battle-screen";
import { Image } from "../../components/image/image";
import numeral from "numeral";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
});

const POSSESSIONTICKET = css({
    display: "flex",
    marginTop: spacing[2],
});

const TICKETCONTAINER = css({
    display: "flex",
    marginTop: spacing[2],
    flexDirection: "row",
    alignItems: "center",
    height: 58,
});

const TICKET = css({
    width: 62,
    height: 46,
    marginRight: spacing[2],
});

const TICKETNUMBER = css({
    size: 20,
    color: colors.yellowText,
});

const TICKETBOXCONTAINER = css({
    display: "flex",
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1
});

const skipVideos = "SHOP_TITLE";
const possessionTicket = "SHOP_TITLE_POSSESSION_TICKET";

export const ShopScreen = () => {
    const shopItems = useStore(shopListSelector);
    const tickets = useStore(ticketSelector);

    const items = shopItems.map(item => <ShopTicket ticket={item.ticketCount} amount={item.costIdr} key={item.id} id={item.id} />);

    const displayTickets = numeral(tickets).format("0.00a");

    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar title={skipVideos} />
            <Text styles={POSSESSIONTICKET} size={14}>
                {possessionTicket}
            </Text>
            <div css={TICKETCONTAINER}>
                <Image src={ticketImage} styles={TICKET} />
                <NonTranslatedText styles={TICKETNUMBER}>
                    {displayTickets}
                </NonTranslatedText>
            </div>
            <div css={[TICKETBOXCONTAINER, SCROLLCONTAINER]}>
                {items}
            </div>
        </div>
    );
};
