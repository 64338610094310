/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { css } from "@emotion/react";
import { colors, spacing } from "../../config";
import { linkWalletSelector, useStore } from "../../models";
import { LoadingIndicator } from "../loader/loading-indicator";
import { trackEvent } from "../../services";

const paypal = (window as any).paypal;
const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "";
const paypalMerchantId = process.env.REACT_APP_PAYPAL_MERCHANT_ID ?? "";
const paypalEnv = process.env.REACT_APP_PAYPAL_PRODUCTION ?? "sandbox";

const clientId = { sandbox: paypalClientId, production: paypalClientId };

type LoginResponse = { body: { code: string }, error: Error };

const AACComponent = paypal.PayoutsAAC.driver('react', {
  React,
  ReactDOM
});

const CONTAINER = css({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const OVERLAY = css({
  position: "absolute",
  top: 0, right: 0, left: 0, bottom: 0,
  margin: spacing[2],
  backgroundColor: 'rgba(0,0,0,0.8)',
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
});

export const PaypalLoginModal = ({ show, setShow }: { show: boolean, setShow: (b: boolean) => void }) => {
  const linkWallet = useStore(linkWalletSelector);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      trackEvent("show_link_window");
    }
  }, [show])

  const finishLogin = async (d: LoginResponse) => {
    if (d.error) {
      console.log(d.error);
      return;
    }
    setLoading(true);
    try {
      await linkWallet(d.body.code);
      setShow(false);
    } catch (ex) {
      console.log(ex);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={show} ariaHideApp={false}
      onRequestClose={() => setShow(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 10,
        },
        content: {
          backgroundColor: colors.darkText,
          borderRadius: spacing[3],
          borderWidth: 2,
          borderColor: colors.primary,
          color: colors.lightText,
          bottom: "auto",
          left: "50%",
          position: "fixed",
          right: "auto",
          top: "50%",
          transform: "translate(-50%,-50%)",
          overflow: "visible",
          width: "80%",
        },
      }}>
      <div css={CONTAINER}>
        <AACComponent clientId={clientId}
          merchantId={paypalMerchantId}
          css={{ width: '100%' }}
          env={paypalEnv}
          pageType="login"
          onLogin={finishLogin} />
        {loading ? <div css={OVERLAY}>
          <LoadingIndicator />
        </div> : null}
      </div>
    </Modal>
  );
};
