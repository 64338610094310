/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../config";
import { NonTranslatedText, Text } from "../text/text";
import { Image } from "../image/image";
import numeral from "numeral";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    background: colors.victoryBox,
    flex: 1,
    height: 66,
    alignItems: "center",
    justifyContent: "center",
});

const VICTORYTICKETCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing[1],
});

const COINTEXT = css({
    color: colors.yellowText,
    marginLeft: spacing[1],
    fontsize: 24,
    fontWeight: "bold",
});

const WINNINGIMAGE = css({
    height: 26,
    width: 'auto'
});

export interface VictoryRewardBoxProps {
    styles?: SerializedStyles;
    winnerReward?: number;
    winningImage: string;
}

export const VictoryRewardBox = ({
    styles,
    winnerReward,
    winningImage,
}: VictoryRewardBoxProps) => {
    const amount = numeral(winnerReward).format("0.00a");
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <Text size={12}>Victory Reward</Text>
            <div css={VICTORYTICKETCONTAINER}>
                <Image src={winningImage} styles={WINNINGIMAGE} />
                <NonTranslatedText styles={COINTEXT}>{amount}</NonTranslatedText>
            </div>
        </div>
    );
};
