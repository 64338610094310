import { getAuth, updateProfile } from "firebase/auth";
import { doc, DocumentSnapshot, getDoc, getFirestore, SnapshotOptions, setDoc } from "firebase/firestore";

const auth = getAuth();
const db = getFirestore();

export const updateUserProfile = async (displayName: string, photoURL: string) => {
    const user = auth.currentUser;
    if (user) {
        return await updateProfile(user, {
            displayName, photoURL
        });
    }
};

type ProfileImage = { image: string };

var profileImageConverter = {
    toFirestore: function(image: ProfileImage) {
        return image;
    },
    fromFirestore: function(snapshot: DocumentSnapshot, options: SnapshotOptions): ProfileImage {
        const data = snapshot.data(options);
        return { image: data?.image ?? "" };
    }
};

export const fetchProfileImage = async (): Promise<string> => {
    const user = auth.currentUser;

    if (!user) {
        throw new Error("cannot fetch profile image without authentication");
    }

    const uid = user.uid;
    const docRef = doc(db, 'profile', uid).withConverter(profileImageConverter);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data().image;
    }

    return "";
};

export const saveProfileImage = async (image: string): Promise<void> => {
    const user = auth.currentUser;

    if (!user) {
        throw new Error("cannot save profile image without authentication");
    }

    const uid = user.uid;
    const docRef = doc(db, 'profile', uid).withConverter(profileImageConverter);
    await setDoc(docRef, { image });
};
