/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router-dom";
import { gamesSelector, useStore, submitBattleScoreSelector, battleOpponentSelector } from "../../models";
import { GameContainer } from "../../components";

const CONTAINER = css({
    display: 'flex',
    height: '100%',
    width: '100%'
});

const maxTime = 300;

type PlayGameProps = { id: string };

export const PlayGameScreen = () => {
    const { id } = useParams<PlayGameProps>();
    const games = useStore(gamesSelector);
    const submitScore = useStore(submitBattleScoreSelector);
    const opponent = useStore(battleOpponentSelector);
    const opponentScore = opponent?.score || 0
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const game = games.find(g => g.id === id);

    const onEnd = useCallback(async (score: number) => {
        try {
            await submitScore(score, opponentScore, id);
            history.replace(`/end-battle`);
        } catch (ex) {
            // switch to error screen
            console.log(ex);
        }
    }, [submitScore, history, opponentScore, id]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            try {
                submitScore(0, opponentScore, id);
            } catch (ex) {
                console.log(ex);
            }
            history.replace(`/end-battle`);
        }, maxTime * 1000);

        return () => clearTimeout(timeout);
    }, [submitScore, history, opponentScore, id]);

    useEffect(() => {
        const onChange = async () => {
            if (loading) {
                return;
            }
            const hidden = document.hidden;
            if (hidden) {
                setLoading(true);
                try {
                    await submitScore(0, opponentScore, id);
                }
                catch (ex) {
                    console.log(ex);
                }
                setLoading(false);
                history.replace(`/end-battle`);
            }
        };
        document.addEventListener("visibilitychange", onChange, false);
        return () => document.removeEventListener("visibilitychange", onChange, false);
    }, [submitScore, history, setLoading, loading, opponentScore, id]);

    useEffect(() => {
        const onUnload = (e: BeforeUnloadEvent) => {
            try {
                submitScore(0, opponentScore, id);
            }
            catch (ex) {
                console.log(ex);
            }
            delete e['returnValue'];
        };
        window.addEventListener("beforeunload", onUnload);
        return () => window.removeEventListener("beforeunload", onUnload);
    }, [submitScore, opponentScore, id]);

    if (!game) {
        return null;
    }

    return (
        <div css={CONTAINER}>
            <GameContainer game={game} onEnd={onEnd} />
        </div>
    );
};
