/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Text } from "../../components/text/text";
import { spacing, colors } from "../../config";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Logo.png";
import { Redirect, useHistory } from "react-router-dom";
import { fetchInfoSelector, fetchWalletSelector, infoLoadingSelector, LoginState, loginStateSelector, userSelector, useStore, walletLoadingSelector } from "../../models";
import { isAuthLink } from "../../services";
import { Image } from "../../components/image/image";

const onboardingKey = "hasDoneOnboarding";
const hasDoneOnboarding = () => localStorage.getItem(onboardingKey) === "true";
const doneOnboardingOnce = () => localStorage.setItem(onboardingKey, "true");

const CONTAINERSTYLE = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing[10],
});

const LOGO = css({
  width: "100%",
});

const LOADERCONTAINER = css({
  position: "absolute",
  bottom: 70,
});

const LOADINGBORDER = css({
  width: 300,
  height: 16,
  borderWidth: 1,
  borderColor: colors.primary,
  borderStyle: "solid",
  borderRadius: spacing[2],
  marginTop: spacing[3],
  padding: 3,
});

const LOADINGBOX = css({
  height: "100%",
  background: colors.buttonGradient,
  borderRadius: spacing[1],
});

const WRAPPER = css({
  position: 'relative',
  textAlign: 'center',
  marginBottom: 100
})

const PROTOTYPE = css({
  position: 'absolute',
  bottom: 30,
  fontWeight: 800,
  color: 'white',
  textAlign: 'center',
  width: '100%'
})

const loading = "ONBOARDING_TEXT_LOADING";

export interface LoadingScreenProps {
  errorMessage: string;
}

export const LoadingScreen = () => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const history = useHistory();

  const user = useStore(userSelector);
  const loginState = useStore(loginStateSelector);
  const infoLoading = useStore(infoLoadingSelector);
  const fetchInfo = useStore(fetchInfoSelector);
  const fetchWallet = useStore(fetchWalletSelector);
  const walletLoading = useStore(walletLoadingSelector);

  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN || loginState === LoginState.LOGGED_OUT) {
      setPercentage(p => p + 10);
    }
  }, [loginState]);

  useEffect(() => {
    if (!user && loginState !== LoginState.LOGGING_IN) {
      setPercentage(100);
      if (hasDoneOnboarding()) {
        setTimeout(() => history.replace('/login'), 1000);
      } else {
        console.log("switching to terms");
        setTimeout(() => history.replace('/termsandconditions'), 1000);
      }
    }
  }, [user, loginState, history]);

  useEffect(() => {
    if (!walletLoading) {
      setPercentage(p => p + 10);
    }
    if (!infoLoading) {
      setPercentage(p => p + 10);
    }

    if (!infoLoading && !walletLoading) {
      setPercentage(100);
      setTimeout(() => history.replace('/battle'), 1000);
      doneOnboardingOnce();
    }
  }, [infoLoading, walletLoading, history]);

  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN) {
      const fn = async () => {
        try {
          await fetchInfo();
          await fetchWallet();
        }
        catch (ex) {
          // redirect to error page
          console.log(ex);
          history.replace('/error', { error: "Network error when loading app", hideBack: true });
        }
      }
      fn();
    }
  }, [loginState, fetchInfo, fetchWallet, history]);

  useEffect(() => {
    const loadingTimer = () => {
      setPercentage((p) => p < 80 ? p + 1 : p);
    };
    const intervalId = setInterval(loadingTimer, 500);
    return () => clearInterval(intervalId);
  }, []);

  if (isAuthLink(window.location.href)) {
    return <Redirect to="/finish-login" />
  }

  const percentageDisplay = Math.min(percentage, 100);
  let loadingWidthCss = css({
    width: `${percentageDisplay}%`,
  });

  const loadingText = t(loading) + " " + percentageDisplay.toString() + "%";

  return (
    <div css={CONTAINERSTYLE}>
      <div css={WRAPPER}>
        <Image src={logo} styles={LOGO} />
        <p css={PROTOTYPE}>prototype</p>
      </div>
      <div css={LOADERCONTAINER}>
        <Text size={12}>{loadingText}</Text>
        <div css={LOADINGBORDER}>
          <div css={[LOADINGBOX, loadingWidthCss]}></div>
        </div>
      </div>
    </div>
  );
};
