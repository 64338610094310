import 'share-api-polyfill';

export const onShare = ({ title, url, text }: { title: string, url: string, text: string }) => {
  if (navigator.share !== undefined) {
    navigator.share({
      title, url, text,

      // @ts-ignore
      telegram: false,
      // @ts-ignore
      skype: false,
      // @ts-ignore
      pinterest: false,

    });
  }
};
