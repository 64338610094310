/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GameInfoBar } from "../../components";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1
});

const TEXT = css({
    padding: "100px 0",
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
})

export const MidtransScreen = () => {
    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar title={"Midtrans"} showBack={false}/>
            <p css={TEXT}>purchase success</p>
        </div>
    );
};
