/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { spacing } from "../../config";
import { Text } from "../text/text";
import leftArrow from "../../assets/images/LeftArrow.png";
import { Image } from "../image/image";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 40,
    height: 40,
    background: "rgba(0, 0, 0, 0.5)",
    paddingLeft: spacing[3],
    paddingRight: spacing[3],
    alignSelf: "stretch",
});

const TEXT = css({
    fontSize: 18,
    lineHeight: "150%",
    textAlign: "center",
    flex: 1,
});

export interface GameInfoBarProps {
    title: string;
    showBack?: boolean;
    onBack?: () => void;
    styles?: SerializedStyles;
}

const iconSize = 18;
const EMPTYDIV = css({ height: iconSize, width: iconSize });
const EmptyDiv = () => <div css={EMPTYDIV}></div>;

export const GameInfoBar = ({
    title,
    showBack = false,
    onBack,
    styles
}: GameInfoBarProps) => {

    const overrideCSS = css({
        width: iconSize,
        height: iconSize,
    })
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            {showBack ? (
                <Image
                    src={leftArrow}
                    alt=""
                    onClick={onBack}
                    styles={overrideCSS}
                />
            ) : (
                    <EmptyDiv />
                )}
            <Text styles={TEXT}>{title}</Text>
        </div>
    );
};
