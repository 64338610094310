/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { spacing } from "../../../config";

const CONTAINER = css({
    display: "flex",
    marginRight: -spacing[3],
    marginLeft: -spacing[3]
});

export interface AdCarouselProps {
    styles?: SerializedStyles;
    children: React.ReactChild[];
}

export const AdCarousel = ({ styles, children }: AdCarouselProps) => {
    return (
        <div css={CONTAINER}>
            <Carousel
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                centerMode={true}
                autoFocus={true}
                centerSlidePercentage={85}
                transitionTime={1000}
                interval={4000}
            >
                {children}
            </Carousel>
        </div>
    );
};
