/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import { GameInfoBar, TextInput, NumberInput, Button } from "../../components";
import { spacing, colors } from "../../config";

import banks from '../../assets/banks.json'
console.log(banks)

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1
});

const EDITCONTAINER = css({
    display: "flex",
    flex: 1,
    alignItems: "space-around",
    flexDirection: "column",
});

const EDITFIELDS = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing[5],
});

const INPUTFIELD = css({
    marginTop: spacing[3],
    marginBottom: spacing[3],
});

const BUTTONCONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: spacing[6],
});

const SAVEDISABLEBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
    background: colors.disableButtonGradient,
});

const SAVEBUTTON = css({
    width: 144,
    marginLeft: spacing[2],
    marginRight: spacing[2],
    background: colors.saveButtonGradient,
});

export const WithdrawScreen = () => {
    const history = useHistory();
    const [beneficiaryName, setBeneficiaryName] = useState<string>("");
    const [beneficiaryAccount, setBeneficiaryAccount] = useState<number>(0);
    const [beneficiaryEmail, setBeneficiaryEmail] = useState<string>("");
    // const [amount, setAmount] = useState<number>(0);

    const nameLabel = "WITHDRAW_NAME";
    const accountLabel = "WITHDRAW_ACCOUNT";
    const bankLabel = "WITHDRAW_BANK";
    const emailLabel = "WITHDRAW_EMAIL";
    // const amountLabel = "WITHDRAW_AMOUNT";
    // const save = "PROFILE_BUTTON_SAVE";

    const saveDisabled = true
    const onSave = () => {}

    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar title={"Withdraw"} showBack={true} onBack={() => history.push('/wallet')}/>
            <div css={EDITCONTAINER}>
                <div css={EDITFIELDS}>
                    <TextInput
                        label={nameLabel}
                        type="text"
                        value={beneficiaryName}
                        placeholder="beneficiary name"
                        onChangeText={setBeneficiaryName}
                        styles={INPUTFIELD}
                    />
                    <NumberInput
                        label={accountLabel}
                        type="number"
                        value={beneficiaryAccount}
                        placeholder="account number"
                        onChangeNumber={setBeneficiaryAccount}
                        styles={INPUTFIELD}
                    />
                    <TextInput
                        label={bankLabel}
                        type="text"
                        value={beneficiaryName}
                        placeholder="beneficiary name"
                        onChangeText={setBeneficiaryName}
                        styles={INPUTFIELD}
                    />
                    <TextInput
                        label={emailLabel}
                        type="email"
                        value={beneficiaryEmail}
                        placeholder="beneficiary email"
                        onChangeText={setBeneficiaryEmail}
                        styles={INPUTFIELD}
                    />
                    <div css={BUTTONCONTAINER}>
                        <Button
                            label={"Submit"}
                            styles={saveDisabled ? SAVEDISABLEBUTTON : SAVEBUTTON}
                            onPress={onSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
