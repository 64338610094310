import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { LoginState, loginStateSelector, userSelector, useStore } from '../models';

export interface PrivateRouteProps {
    children: React.ReactNode,
    path: string | string[]
}

export const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
    const loginState = useStore(loginStateSelector);

    return (
        <Route {...rest} render={() => {
            return loginState === LoginState.LOGGING_IN ? <>Loading</> : loginState === LoginState.LOGGED_IN
                ? children
                : <Redirect to='/login' />
        }} />
    )
}

export const ProfileRoute = ({ children, ...rest }: PrivateRouteProps) => {
    const currentUser = useStore(userSelector);

    return (
        <Route {...rest} render={() => {
            return currentUser?.displayName ? children : <Redirect to={{
                pathname: "/edit-profile",
                state: { back: '/battle' }
            }} />
        }} />
    );
};
