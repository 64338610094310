/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { spacing, colors } from "../../config";
import { GameInfoBar } from "../../components";
import { BirdTutorialScreen } from "../../components/onboarding/bird-tutorial-text";
import { OnboardingBattleTicket } from "./onboarding-battle-ticket";
import practiseBg from "../../assets/images/battle_card_background_practice.png";
import ticketBg from "../../assets/images/battle_card_background_normal.png";
import { useTrackScreenEvent } from "../../services";

const CONTAINERSTYLE = css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
});

const GAMEINFOBAR = css({
    paddingTop: 6,
    paddingBottom: 6,
});

const OVERLAY = css({
    width: "100%",
    height: "100vh",
    position: "absolute",
    background: colors.darkText,
    opacity: 0.8,
    zIndex: 10,
});

const ONBOARDINGTICKETCONTAINER = css({
    zIndex: 20,
    position: "relative",
    borderColor: "#FCFFA1",
    borderWidth: 3,
    borderStyle: "solid",
    borderRadius: spacing[3],
    margin: -8,
    padding: 8,
    marginTop: spacing[2],
});

const HIGHLIGHTEDTICKET = css({
    marginTop: 0,
    background: `url(${ticketBg})`,
});

const TICKET = css({
    background: `url(${practiseBg})`,
});

const TUTORIALBIRD = css({
    bottom: 50,
});

export const SCROLLCONTAINER = css({
    paddingLeft: spacing[5],
    paddingRight: spacing[5],
});

const BATTLE_TITLE_BATTLE = "BATTLE_TITLE_BATTLE";
const tutorialEvent = "tutorial_begin";
const battleTutorialText = "ONBOARDING_TICKETS_TEXT";

export const BattleOnboardingScreen = () => {
    useTrackScreenEvent(tutorialEvent);
    return (
        <div css={CONTAINERSTYLE}>
            <BirdTutorialScreen
                onBoardingText={battleTutorialText}
                styles={TUTORIALBIRD}
            />
            <div css={OVERLAY}></div>
            <GameInfoBar
                title={BATTLE_TITLE_BATTLE}
                styles={GAMEINFOBAR}
            />
            <div css={SCROLLCONTAINER}>
                <OnboardingBattleTicket styles={TICKET} />
                <div css={ONBOARDINGTICKETCONTAINER}>
                    <OnboardingBattleTicket styles={HIGHLIGHTEDTICKET} hasArrow={true} />
                </div>
                <OnboardingBattleTicket styles={TICKET} />
            </div>
        </div>
    );
};
