import { getAuth } from "firebase/auth";
import { doc, DocumentSnapshot, getDoc, getFirestore, SnapshotOptions } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

const auth = getAuth();
const db = getFirestore();

type BuyTicketsData = { id: string };
type BuyTicketsResponse = { ticketCount: number };

const buyTicketsCall = httpsCallable<BuyTicketsData, BuyTicketsResponse>(functions, 'buyTickets');

export type WalletInfo = { coins: number, tickets: number, level: number, linked: boolean };

export const walletConverter = {
    toFirestore: function(wallet: WalletInfo) {
        return {
            ...wallet
        }
    },
    fromFirestore: function(snapshot: DocumentSnapshot, options: SnapshotOptions) {
        const data = snapshot.data(options);
        return {
            coins: data?.coins as number ?? 0,
            tickets: data?.tickets as number ?? 0,
            level: data?.level as number ?? 0,
            linked: data?.linked ?? false
        };
    }
};

export const fetchWallet = async (): Promise<WalletInfo> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot fetch wallet without authentication");
    }

    const uid = user.uid;
    const docRef = doc(db, 'wallet', uid).withConverter(walletConverter);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    }

    return { coins: 0, tickets: 0, level: 0, linked: false };
};

export const buyTicket = async (id: string) => {
    const resp = await buyTicketsCall({ id })
    return resp.data.ticketCount;
};
