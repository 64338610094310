import create, { GetState, SetState } from "zustand";
import { createUserState } from "./user";
import { log } from './log';
import { createInfoState } from "./info";
import { AppStore } from './appstore';
import { createWalletState } from "./wallet";
import { createBattleState } from "./battle";

const isDev = process.env.NODE_ENV === 'development';



const store = (set: SetState<AppStore>, get: GetState<AppStore>) => {
    return {
        ...createUserState(set, get),
        ...createInfoState(set, get),
        ...createWalletState(set, get),
        ...createBattleState(set, get),
    };
};

const creator = isDev ? log<AppStore>(store) : store;

export const useStore = create<AppStore>(creator);
