export const textSizes = {
    h1: 20,
    h2: 16,
    h3: 12,
    p: 10
};

export const textWeight = {
    bold: 700,
    semibold: 600,
    normal: 400,
    thin: 300,
};
