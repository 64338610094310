/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../config";
import timeRibbon from "../../assets/images/battle_card_ribbon.png";
import { TicketButton } from "./ticket-button";
import { VictoryRewardBox } from "./victory-reward-box";
import { Battle, BattleType, PrizeType } from "../../services";
import { DateTime } from 'luxon';
import { BattleProceed } from "./battle-proceed";
import { useHistory } from "react-router-dom";
import { NonTranslatedText } from "../text/text";
import coinImage from "../../assets/images/info/Coin.png";
import ticketImage from "../../assets/images/info/Ticket.png";
import battleImageFree1 from "../../assets/images/BattleCard_Free_01.png";
import battleImageFree2 from "../../assets/images/BattleCard_Free_02.png";
import battleImagePaid1 from "../../assets/images/BattleCard_Paid_01.png";
import battleImagePaid2 from "../../assets/images/BattleCard_Paid_02.png";
import battleImagePaid3 from "../../assets/images/BattleCard_Paid_03.png";

const CONTAINERSTYLE = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "auto",
  height: 150,
  marginTop: spacing[5],
  borderRadius: 12,
  background: "#222222",
});

const TIMERIBBON = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundImage: `url(${timeRibbon})`,
  backgroundSize: "cover",
  boxShadow: "0px 3px 0px rgba(0, 0, 0, 0.5)",
  width: 194,
  height: 28,
  marginTop: spacing[3],
  paddingLeft: spacing[2],
  paddingRight: spacing[3],
});

const WITHOUTRIBBON = css({ flex: 1 });

const BOTTOMPANEL = css({
  display: "flex",
  flexDirection: "row",
  alignItems: 'center',
  height: 66,
  marginBottom: spacing[3],
});

const TIMESTYLE = css({
  color: colors.lightText
});

export interface BattleTicketProps {
  styles?: SerializedStyles
  battle: Battle
}

const winningImage = (prizeType: PrizeType): string => {
  switch (prizeType) {
    case PrizeType.Coins:
      return coinImage;
    case PrizeType.Tickets:
      return ticketImage;
    default:
      return coinImage;
  }
};


const ticketBackground = (imageSrc: string) => {
  switch (imageSrc) {
    case "/images/BattleCard_Free_01.png": return battleImageFree1;
    case "/images/BattleCard_Free_02.png": return battleImageFree2;
    case "/images/BattleCard_Paid_01.png": return battleImagePaid1;
    case "/images/BattleCard_Paid_02.png": return battleImagePaid2;
    case "/images/BattleCard_Paid_03.png": return battleImagePaid3;
    default: return battleImagePaid1;
  }
};


const parseTime = (time: number) => {
  return DateTime.utc().set({ hour: time / 100, minute: time % 100 });
};

const TimeRibbon = ({ battleType, startTime, endTime }: { battleType: BattleType, startTime: DateTime, endTime: DateTime }) => {
  return battleType !== BattleType.Practise ? <div css={TIMERIBBON}>
    <NonTranslatedText styles={TIMESTYLE}>{`${startTime.setZone('system').toLocaleString(DateTime.TIME_24_SIMPLE)} - ${endTime.setZone('system').toLocaleString(DateTime.TIME_24_SIMPLE)}`}</NonTranslatedText>
  </div> : <div css={WITHOUTRIBBON}></div>
};

export const BattleTicket = ({
  styles,
  battle
}: BattleTicketProps) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const onClick = () => {
    if (battle.battleType === BattleType.Practise && battle.cost === 0) {
      history.replace(`/play-battle/${battle.id}`);
    } else {
      setShowModal(true);
    }
  };

  const bgImage = ticketBackground(battle.image);
  const winImage = winningImage(battle.prizeType);

  const override = css({
    background: `url(${bgImage})`,
    backgroundSize: "cover",
  });

  const startTime = parseTime(battle.startTime);
  const endTime = parseTime(battle.endTime);

  const current = DateTime.utc();
  const enabled = battle.battleType === BattleType.Practise || (current > startTime && current < endTime);

  return (
    <div css={[CONTAINERSTYLE, styles, override]}>
      <TimeRibbon battleType={battle.battleType} startTime={startTime} endTime={endTime} />
      <div css={BOTTOMPANEL}>
        <VictoryRewardBox winnerReward={battle.prize} winningImage={winImage} />
        <TicketButton enabled={enabled} cost={battle.cost} onClick={onClick} />
      </div>
      <BattleProceed open={showModal} setOpen={setShowModal} ticketAmount={battle.cost} battleId={battle.id} />
    </div>
  );
};
