import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "";

const paypalOptions = { "client-id": paypalClientId, "data-namespace": "paypal_sdk" };

export const PaymentsProvider = ({ children }: { children?: React.ReactNode }) => {
    return (
        <PayPalScriptProvider options={paypalOptions}>
            {children}
        </PayPalScriptProvider>
    );
};
