/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { spacing } from "../../../config";
import memberImage from "../../../assets/images/TouramentEntryUsers.png";
import { NonTranslatedText } from "../..";
import { Image } from "../../image/image";

const MEMBERS = css({
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
});

const MEMBERTEXT = css({
    marginLeft: spacing[1],
    textShadow: "-1px 1px 1px rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5), -1px -1px 0 rgba(0, 0, 0, 0.5)"
});

export interface MemberProps {
  styles?: SerializedStyles;
  memberCount?: string;
  imgHeight?: string;
  imgWidth?: string;
}

export const Members = ({ styles, memberCount, imgHeight, imgWidth }: MemberProps) => {

  const overrideCSS = css({
    height: imgHeight,
    width: imgWidth
  });
  
  return (
    <div css={[MEMBERS, styles]}>
      <Image src={memberImage} styles={overrideCSS} />
      <NonTranslatedText styles={MEMBERTEXT}>{memberCount}</NonTranslatedText>
    </div>
  );
};
