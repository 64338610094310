/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react'

export interface ImageProps {
    src?: string,
    styles?: SerializedStyles
    alt?: string
    onClick?: () => void,
    id?: string
}

const IMAGE = css({
    userSelect: 'none'
})

export const Image = ({ src="", styles, alt = "", onClick, id="" }: ImageProps) => {
    return <img src={src} css={[styles, IMAGE]} alt={alt} onClick={onClick} id={id} />
};
