/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { colors, spacing } from "../../../config";
import ratingStar from "../../../assets/images/RankStar.png";
import { NonTranslatedText, Text } from "../../text/text";
import { Image } from "../../image/image";

const CONTAINER = css({
    height: 50,
    display: "flex",
    flexDirection: "row",
    color: colors.lightText,
    fontWeight: "bold",
});

const GAMELOGO = css({
    borderRadius: spacing[3],
    borderWidth: 2,
    borderColor: colors.lightText,
    borderStyle: "solid",
    marginRight: spacing[3],
});

const GAMEDETAIL = css({
    display: "flex",
    flexDirection: "column",
    flexBasis: "500px",
    flexGrow: 1,
});

const TITLETEXT = css({
    fontSize: 14,
    lineHeight: "150%",
});

const SCORETEXT = css({
    fontSize: 10,
    lineHeight: "150%",
});

const POINTTEXT = css({
    fontSize: 10,
});

const POINTS = css({
    fontSize: 14,
    lineHeight: "150%",
    fontWeight: "bold",
    color: colors.yellowText,
    marginLeft: spacing[1],
    marginRight: spacing[1],
});

const GAMEINFO = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
});

const SCORE = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const RANKSTAR = css({
    width: 10,
    height: 10,
    marginLeft: 3,
});

const RANKCONTAINER = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
});

export interface GameDetailsProps {
    url?: string;
    title: string;
    points: string;
    stars: number;
}

const highestScore = "GAME_HIGHEST_SCORE";
const gamePoint = "GAME_POINT";
const rank = "GAME_RANK";

export const GameDetails = ({
    url,
    title,
    points,
    stars,
}: GameDetailsProps) => {

    let starsComponent = [];
    for (var i = 0; i < stars; i++) {
        starsComponent.push(
            <Image src={ratingStar} styles={RANKSTAR} key={i} />
        );
    }

    return (
        <div css={CONTAINER}>
            <Image src={url} styles={GAMELOGO} />
            <div css={GAMEDETAIL}>
                <NonTranslatedText styles={TITLETEXT}>{title}</NonTranslatedText>
                <div css={GAMEINFO}>
                    <div css={SCORE}>
                        <Text styles={SCORETEXT}>{highestScore}</Text>
                        <div>
                            <NonTranslatedText styles={POINTS}>{points}</NonTranslatedText>
                            <Text styles={POINTTEXT}>{gamePoint}</Text>
                        </div>
                    </div>
                    <div css={RANKCONTAINER}>
                        <Text styles={SCORETEXT}>{rank}</Text>
                        {starsComponent}
                    </div>
                </div>
            </div>
        </div>
    );
};
