/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { spacing } from "../../config";
import { Carousel } from "react-responsive-carousel";
import { BirdTutorialScreen } from "../../components/onboarding/bird-tutorial-text";
import spotlight from "../../assets/images/SingleSpotlight.png";
import start from "../../assets/images/battleModes/BattleStart.png";
import three from "../../assets/images/battleModes/BattleCount3.png";
import two from "../../assets/images/battleModes/BattleCount2.png";
import one from "../../assets/images/battleModes/BattleCount1.png";
import { useHistory } from "react-router-dom";
import { Image } from "../../components/image/image";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
});

const CAROUSEL = css({
    display: "flex",
    height: 216,
    alignItems: "center",
    justifyItems: "center",
});

const BANNER = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "auto",
    margin: spacing[2],
});

const BANNERIMAGE = css({
    display: "flex",
    maxWidth: 216,
    transitionProperty: "transform",
    transitionDuration: "1s",
    transform: "scale(0.56)"
});

const BIRD = css({
    bottom: 100,
});

const SPOTLIGHTIMAGE = css({
    position: "absolute",
    width: 302,
    height: 541,
    // top: -20,
    transitionProperty: "opacity",
    transitionDuration: "1s",
    transitionDelay: "1s",
    opacity: 0,
    right: "auto",
    top: "35%",
    transform: "translate(-50%,-50%)",
    left: '50%'
});

const COUNTDOWNIMAGE = css({
    position: "absolute",
    top: 60,
    maxHeight: 126,
    maxWidth: 318,
    zIndex: 30
});

const icons = [
    "icon_game_0002_swipe-it.png",
    "icon_game_0000_flip_jump.png",
    "icon_game_0003_4096.png",
    "icon_game_0004_fast-math.png",
    "icon_game_0005_city-dunk .png",
    "icon_game_0006_knife-rain.png",
    "icon_game_0007_baseball.png",
    "icon_game_0008_frog-block.png",
    "icon_game_0009_bubble-woods.png"
];

const GameIcon = ({ src }: { src: string }) => {
    return (
        <div css={BANNER}>
            <Image styles={BANNERIMAGE} src={src} alt="" />
        </div>
    );
};

const onboardingText = "ONBOARDING_BATTLE_TEXT";

const caurouselItems = icons.map(i => <GameIcon key={i} src={"/images/" + i} />);

let countdownImages = [three, two, one, start];

export const OnboardingGameLoading = () => {
    const history = useHistory();

    const [checkForIdFlag, setCheckForIdFlag] = useState(false);
    const [selected, setselected] = useState(false);
    const [autoplay, setautoplay] = useState(true);

    const [showCountdown, setshowCountdown] = useState(false);
    const [countdownImageNumber, setcountdownImageNumber] = useState(0);
    const [countdownImageSource, setCountdownImageSource] = useState(start);

    const startCountdown = () => {
        setshowCountdown(true);
    };

    useEffect(() => {
        let countdown = setInterval(() => {
            if (showCountdown) {
                setcountdownImageNumber((prevCount) => prevCount + 1);
            }
        }, 1000);
        return () => {
            clearInterval(countdown);
        };
    }, [showCountdown]);

    useEffect(() => {
        if (countdownImageNumber < 4) {
            setCountdownImageSource(countdownImages[countdownImageNumber]);
        }
    }, [countdownImageNumber]);

    useEffect(() => {
        let li = document.getElementsByTagName("li");
        let spotlightImage = document.getElementById("spotlightImage");
        let selectedItem = getSelectedItem(li);
        if (selected) {
            setautoplay(false);
            if (selectedItem != null && spotlightImage != null) {
                selectedItem.style.transform = "scale(1)";
                spotlightImage.style.opacity = "1";
            }
        }
    }, [selected]);

    useEffect(() => {
        let countdownTimeout = window.setTimeout(() => {
            startCountdown();
        }, 4000);

        let gameTimeout = window.setTimeout(() => {
            history.replace("/onboardingplaygame");
        }, 9000);

        return () => {
            clearTimeout(countdownTimeout);
            clearTimeout(gameTimeout);
        };
    }, [history]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCheckForIdFlag(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const checkForId = (index: number) => {
        if (checkForIdFlag && index === 0) {
            setselected(true);
        }
    };

    return (
        <div css={CONTAINERSTYLE}>
            {showCountdown ? (
                <Image src={countdownImageSource} alt="" styles={COUNTDOWNIMAGE} />
            ) : (
                    null
                )}
            <Image src={spotlight} alt="" styles={SPOTLIGHTIMAGE} id="spotlightImage" />
            <div css={CAROUSEL}>
                <Carousel
                    showThumbs={false}
                    autoPlay={autoplay}
                    infiniteLoop={true}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    centerMode={true}
                    autoFocus={false}
                    centerSlidePercentage={60}
                    transitionTime={100}
                    interval={100}
                    swipeable={false}
                    onChange={checkForId}
                >
                    {caurouselItems}
                </Carousel>
            </div>
            {selected ? null : <BirdTutorialScreen
                onBoardingText={onboardingText}
                styles={BIRD}
            />}
        </div>
    );
};
function getSelectedItem(li: HTMLCollectionOf<HTMLLIElement>) {
    for (let index = 0; index < li.length; index++) {
        const element = li[index];
        if (element.classList.contains("selected")) {
            return element.getElementsByTagName("img")[0];
        }
    }
    return null;
}
