/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Screen, SignIn } from '../../components';
import backgroundImage from '../../assets/images/background.png';

const CONTAINERSTYLE = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const LoginScreen = () => {
    return (
        <Screen backgroundImage={backgroundImage} styles={CONTAINERSTYLE}>
            <SignIn />
        </Screen>
    );
};
