import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  User,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  fetchSignInMethodsForEmail,
  linkWithCredential
} from "firebase/auth";
import { AuthError } from "@firebase/auth-types"
import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

const EMAIL_STORAGE_KEY = 'emailForSignIn';

const url = process.env.REACT_APP_FIREBASE_FINISH_AUTH_URL;
if (!url) {
  throw new Error("Firebase url not configured, expected auth url");
}

type OTPRequest = { subject: string, body: string, email: string };
type OTPResponse = { id: string };
type VerifyOTPRequest = { id: string, receivedOtp: number };
type VerifyOTPResponse = { token: string };

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope('email');

const otpCall = httpsCallable<OTPRequest, OTPResponse>(functions, "sendOtp");
const verifyOtpCall = httpsCallable<VerifyOTPRequest, VerifyOTPResponse>(functions, "verifyOtp");

export class ExistingAccountError extends Error {
  method: string
  constructor(message: string, method: string) {
    super(message);
    this.method = method;
  }
}

const actionCodeSettings = {
  url,
  // This must be true.
  handleCodeInApp: true,
};

const auth = getAuth();

export const sendOTP = async (subject: string, body: string, email: string): Promise<string> => {
  const response = await otpCall({ subject, body, email });
  return response.data.id;
}

export const verifyOTP = async (id: string, receivedOtp: number): Promise<void> => {
  const response = await verifyOtpCall({ id, receivedOtp });
  const token = response.data.token;

  try {
    await signInWithCustomToken(auth, token);
  } catch (ex) {
    throw ex;
  }
};

export const sendSignInLink = async (email: string) => {
  await sendSignInLinkToEmail(auth, email, actionCodeSettings)
  window.localStorage.setItem(EMAIL_STORAGE_KEY, email);
}

export const EMAIL_REQUIRED_ERROR = "email missing in cache";
export const EMAIL_VERIFY_FAILED = "email verification failed";
export const EMAIL_LINK_MISSING = "not a sign in link";

export const isAuthLink = (location: string) => isSignInWithEmailLink(auth, location);

export const processLink = async (location: string, givenEmail?: string) => {
  if (isSignInWithEmailLink(auth, location)) {
    let email = givenEmail ?? window.localStorage.getItem(EMAIL_STORAGE_KEY);
    if (!email) {
      throw new Error(EMAIL_REQUIRED_ERROR);
    }

    try {
      await signInWithEmailLink(auth, email, location)
      window.localStorage.removeItem('emailForSignIn');
    } catch (ex) {
      throw new Error(`${EMAIL_VERIFY_FAILED} : ${ex}`);
    }
  } else {
    throw new Error(`${EMAIL_VERIFY_FAILED} : this is not a sign in link`);
  }
};

const getProviderForProviderId = (id: string) => {
  switch (id) {
    case "facebook": return facebookProvider;
    case "google": return googleProvider;
  }

  throw new Error("failed to find provider for login method");
};

export const googleSignIn = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    return null;
  } catch (ex) {
    const err = ex as AuthError
    if (err.code === 'auth/account-exists-with-different-credential') {
      const email = err.email || "";
      const pendingCred = err.credential ?? null;
      if (pendingCred === null) {
        throw new ExistingAccountError('auth/account-exists-with-different-credential', "OTP");
      }
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods[0] === 'password') {
        throw new ExistingAccountError('auth/account-exists-with-different-credential', "OTP");
      }

      var provider = getProviderForProviderId(methods[0]);
      const res = await signInWithPopup(auth, provider);
      await linkWithCredential(res.user, pendingCred);
    } else {
      throw ex;
    }
  }
};

export const facebookSignIn = async () => {
  try {
    await signInWithPopup(auth, facebookProvider);
    return null;
  } catch (ex) {
    const err = ex as AuthError
    if (err.code === 'auth/account-exists-with-different-credential') {
      const email = err.email || "";
      const pendingCred = err.credential ?? null;
      if (pendingCred === null) {
        throw new ExistingAccountError('auth/account-exists-with-different-credential', "OTP");
      }
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods[0] === 'password') {
        throw new ExistingAccountError('auth/account-exists-with-different-credential', "OTP");
      }

      var provider = getProviderForProviderId(methods[0]);
      const res = await signInWithPopup(auth, provider);
      await linkWithCredential(res.user, pendingCred);
    } else {
      throw ex;
    }
  }
};

export const alternateLink = async () => {

};

export const watchAuth = (cb: (arg0: User | null) => void) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      cb(user);
    } else {
      cb(null);
    }
  });
};


export const signOutUser = async () => await signOut(auth);
