/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing, textSizes } from "../../config";
import { Text } from "../text/text";

const noop = (_: string) => { };

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 20,
});

const LABELSTYLE = css({
    fontSize: textSizes.h3,
    marginBottom: spacing[2]
});

const INPUTSTYLE = css({
    padding: spacing[3],
    borderWidth: 0,
    backgroundColor: "transparent",
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
    color: colors.lightText,
    outline: "none",
    alignSelf: "stretch",
    borderRadius: spacing[1]
});

export interface TextInputProps {
    label?: string;
    placeholder?: string;
    type?: "text" | "password" | "email" | "number";
    value?: string;
    onChangeText?: (s: string) => void;
    styles?: SerializedStyles;
    inputBoxStyle?: SerializedStyles;
    disabled?: boolean,
}

export interface NumberInputProps {
    label?: string;
    value?: number;
    onChangeNumber?: (n: number) => void;
    styles?: SerializedStyles;
    inputBoxStyle?: SerializedStyles;
    disabled?: boolean,
}

export const TextInput = ({
    label = "",
    placeholder = "Email ID",
    value = "",
    onChangeText = noop,
    type = "text",
    styles,
    inputBoxStyle,
    disabled = false,
    ...rest
}: TextInputProps & React.HTMLProps<HTMLInputElement>) => {
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <Text styles={LABELSTYLE}>{label}</Text>
            <input
                {...rest}
                css={[INPUTSTYLE, inputBoxStyle]}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChangeText(event.target.value)}
                disabled={disabled}
            />
        </div>
    );
};

export const NumberInput = ({
    label = "",
    value = 0,
    onChangeNumber = (_: number) => { },
    styles,
    inputBoxStyle,
    disabled = false,
    ...rest
}: NumberInputProps & React.HTMLProps<HTMLInputElement>) => {
    return (
        <div css={[CONTAINERSTYLE, styles]}>
            <Text styles={LABELSTYLE}>{label}</Text>
            <input
                {...rest}
                css={[INPUTSTYLE, inputBoxStyle]}
                type={"number"}
                value={value}
                onChange={(event) => onChangeNumber(parseInt(event.target.value) ?? 0)}
                disabled={disabled}
            />
        </div>
    );
};
