/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import coinImage from "../../../assets/images/info/Coin.png";
import { NonTranslatedText, Text } from "../..";
import { Image } from "../../image/image";

const CONTAINER = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const COINBOX = css({
  display: "flex",
});

export interface WinnerRewardProps {
  styles?: SerializedStyles;
  winnerTextStyles?: SerializedStyles;
  coinTextStyle?: SerializedStyles;
  imgHeight?: string;
  imgWidth?: string;
  winnerCoins?: string;
}

const winner = "RESULT_TEXT_WINNER"

export const WinnerReward = ({
  styles,
  imgWidth,
  imgHeight,
  winnerCoins,
  winnerTextStyles,
  coinTextStyle,
}: WinnerRewardProps) => {

  const overrideCSS = css({
    width: imgWidth,
    height: imgHeight,
  });

  return (
    <div css={[CONTAINER, styles]}>
      <Text styles={winnerTextStyles}>{winner}</Text>
      <div css={COINBOX}>
        <Image src={coinImage} alt="" styles={overrideCSS} />
        <NonTranslatedText styles={coinTextStyle}>{winnerCoins}</NonTranslatedText>
      </div>
    </div>
  );
};
