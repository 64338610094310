/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../../config";
import { GameDetails } from "./gameDetail";
import { Ticket } from "./ticket";
import coinImage from "../../../assets/images/info/Coin.png";

const imageURL = "https://via.placeholder.com/50";
const title = "Game Title";
const points = "12345";
const numberOfStars = 3;
const time = "03:33:22";
const winningCoins = "2500";
const members = "111人";

const CARD = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxHeight: "130px",
    borderRadius: spacing[3],
    borderWidth: 1,
    borderColor: colors.dialogBorder,
    borderStyle: "solid",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    padding: spacing[3],
});

const TICKETS = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: spacing[3],
});

const CENTERTICKETMARGIN = css({
    marginLeft: 6,
    marginRight: 6,
});

export interface CardProps {
    styles?: SerializedStyles;
    cost?: number
}

export const TournamentCard = ({ styles, cost = 0 }: CardProps) => {
    return (
        <div css={CARD}>
            <GameDetails
                url={imageURL}
                title={title}
                points={points}
                stars={numberOfStars}
            />
            <div css={TICKETS}>
                <Ticket
                    time={time}
                    winnerIcon={coinImage}
                    winnerCoins={winningCoins}
                    memberCount={members}
                    ticketCost={cost}
                    isPlaceholder={true}
                />
                <Ticket
                    time={time}
                    winnerIcon={coinImage}
                    winnerCoins={winningCoins}
                    memberCount={members}
                    ticketCost={cost}
                    styles={CENTERTICKETMARGIN}
                    isPlaceholder={true}
                    isComingSoon={true}
                />
                <Ticket
                    time={time}
                    winnerIcon={coinImage}
                    winnerCoins={winningCoins}
                    memberCount={members}
                    ticketCost={cost}
                    isPlaceholder={false}
                />
            </div>
        </div>
    );
};
