/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from "react";
import { Result, Screen } from "../../components";
import backgroundImage from '../../assets/images/background.png';
import { battleOpponentSelector, battleResultSelector, userSelector, useStore, watchResultSelector } from "../../models";
import { BattleState, Participant, trackEvent } from "../../services";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { Waiting } from "../../components/battle/waiting";
import defaultIcon1 from "../../assets/images/profile/DefaultUserIcon1.png";

const noop = () => { };

const RESULTCONTAINER = css({
    display: "flex",
    flex: 1,
})

export const EndBattleScreen = () => {

    const waitForResult = useStore(watchResultSelector);
    const battleResult = useStore(battleResultSelector);
    const user = useStore(userSelector);
    const opponent = useStore(battleOpponentSelector);
    const history = useHistory();

    useEffect(() => {
        let unsub = noop;
        const fn = async () => {
            try {
                unsub = await waitForResult();
            }
            catch (ex) {
                console.log(ex);
                history.replace('/battle');
            }
        };
        fn();
        return () => unsub();
    }, [waitForResult, history]);

    const participants: Participant[] = useMemo(() => {
        if (!user || !opponent) {
            return [];
        }
        return [
            { name: user.displayName ?? "", uid: user.uid, photo: user.photoURL ?? defaultIcon1 },
            { name: opponent.name, uid: "", photo: opponent.photo === "" ? defaultIcon1 : opponent.photo }
        ];
    }, [user, opponent]);

    useEffect(() => {
        if (battleResult && battleResult.state !== BattleState.LIVE) {
            trackEvent("end_battle", { id: opponent?.battleId ?? "" });
        }
    }, [battleResult, opponent])

    if (!opponent) {
        return <Waiting />;
    }

    return (
        <Screen backgroundImage={backgroundImage}>
            {!battleResult || battleResult.state === BattleState.LIVE ? <Waiting /> : <div css={RESULTCONTAINER}>
                <Result result={battleResult} participants={participants} battleId={opponent.battleId} />
            </div>}
        </Screen>
    );
}
