import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { doc, DocumentSnapshot, getFirestore, Timestamp, onSnapshot, SnapshotOptions } from "firebase/firestore";
import { functions } from "./firebase";
import { PrizeType } from "./info";
import { BattleOpponent } from '../models'

const auth = getAuth();
const db = getFirestore();

export type Participant = { uid: string, name: string, photo: string };
export enum BattleState { LIVE = 0, WIN = 1, LOSE = 2, DRAW = 3 };
export type BattleResult = { state: BattleState, ownScore: number, opponentScore: number, prize: number, prizeType: PrizeType };
type LiveBattleUserInfo = { battleId: string, opponent: Participant, liveId: string, timestamp: Timestamp, gameId: string, result: BattleResult | null };
type FinishBattleData = { liveId: string, score: number, battleId: string, opponentScore: number, gameId: string };
type FindBattleData = { battleId: string };
type FindBattleResponse = { opponent: BattleOpponent };
type FinishBattleResponse = { result: BattleResult | null };

const finishBattleCall = httpsCallable<FinishBattleData, FinishBattleResponse>(functions, 'finishBattle');
const registerForBattleCall = httpsCallable<FindBattleData, FindBattleResponse>(functions, 'registerForBattle');
const cancelBattleCall = httpsCallable(functions, 'cancelBattle');

export const battleConverter = {
    toFirestore: function(info: LiveBattleUserInfo) {
        return {
            ...info
        }
    },
    fromFirestore: function(snapshot: DocumentSnapshot, options: SnapshotOptions): LiveBattleUserInfo {
        const data = snapshot.data(options);
        const { battleId, opponent, liveId, timestamp, gameId, result } = data ?? {};
        return {
            battleId, opponent, liveId, timestamp, gameId, result
        };
    }
};

export const initiateBattle = async (battleId: string) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot start battle without authentication");
    }
    const response = await registerForBattleCall({ battleId });
    const data = response.data;
    return { opponent: data.opponent, timestamp: Timestamp.now() };
};

export const waitForBattle = async (timestamp: Timestamp, cb: (d: LiveBattleUserInfo) => void): Promise<() => void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot wait without authentication");
    }

    const uid = user.uid;
    const docRef = doc(db, 'liveUserBattles', uid).withConverter(battleConverter);
    const unsub = onSnapshot(docRef, (doc) => {
        const data = doc.data();
        if (data && data.timestamp > timestamp) {
            cb(data);
        }
    });
    return unsub;
};

export const waitForResult = async (liveId: string, cb: (d: BattleResult) => void): Promise<() => void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot wait without authentication");
    }

    const uid = user.uid;
    const docRef = doc(db, 'liveUserBattles', uid).withConverter(battleConverter);
    const unsub = onSnapshot(docRef, (doc) => {
        const data = doc.data();
        if (data && data.liveId === liveId && data.result !== null && data.result.state !== BattleState.LIVE) {
            cb(data.result);
        }
    });
    return unsub;
};

export const cancelBattle = async (): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot wait without authentication");
    }

    await cancelBattleCall();
};

export const finishBattle = async (battleId: string, liveId: string, score: number, opponentScore: number, gameId: string) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot start battle without authentication");
    }

    const resp = await finishBattleCall({ liveId, score, battleId, opponentScore, gameId });
    return resp.data;
};
