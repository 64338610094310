/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Modal from "react-modal";
import { colors, spacing } from "../../config";
import { NonTranslatedText, Text } from "../text/text";
import { Button } from "../button/button";
import ticketImage from "../../assets/images/info/Ticket.png";
import leftArrow from "../../assets/images/LeftArrow.png";
import { ticketSelector, useStore } from "../../models";
import { useHistory } from "react-router-dom";
import { Image } from "../image/image";
import numeral from "numeral"

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});

const TICKETINFO = css({
    marginTop: spacing[4],
    marginBottom: spacing[3],
    display: "flex",
    flexDirection: "row",
});

const WINNINGINFO = css({
    marginTop: spacing[7],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const TICKETAMOUNT = css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: spacing[2],
});

const POINTSTEXT = css({
    marginLeft: spacing[1],
});

const ARROW = css({
    height: 14,
    transform: "scale(-1)",
    marginLeft: spacing[5],
    marginRight: spacing[5],
});

const BUTTONCONTAINER = css({
    display: "flex",
    marginTop: spacing[6],
});

const BUTTON = css({
    margin: spacing[2],
    display: "flex",
    flex: 1,
});

const BUTTON2 = css({
    margin: spacing[2],
    display: "flex",
    flex: 1,
    background: colors.saveButtonGradient,
});

const TICKETIMAGE = css({
    height: 26,
    width: 36
})

export interface BattleProceedProps {
    open: boolean;
    setOpen: (b: boolean) => void
    ticketAmount: number;
    battleId: string
}

const points = "TICKETS";
const participationMessage = "BATTLE_CONFIRM_MODAL_TEXT_JOIN";
const cancel = "PROFILE_BUTTON_CANCEL";
const participate = "BATTLE_CONFIRM_MODAL_BUTTON_JOIN";
const getMore = "BATTLE_MATCH_MODAL_GET_TICKETS";
const insufficientText = "INSUFFICIENT";

export const BattleProceed = ({ open, setOpen, ticketAmount, battleId }: BattleProceedProps) => {
    const cancelButton = () => setOpen(false);
    const currentTickets = useStore(ticketSelector);
    const history = useHistory();

    const remaining = currentTickets - ticketAmount;
    const displayRemaining = numeral(remaining).format("0.0a");
    const sufficient = remaining >= 0;
    const remainingText = sufficient ? displayRemaining : insufficientText;
    const displayTicket = numeral(ticketAmount).format("0.0a");
    const currentDisplay = numeral(currentTickets).format("0.0a");

    const onYes = () => {
        if (sufficient) {
            history.replace(`/play-battle/${battleId}`);
        } else {
            setOpen(false);
            history.push('/store');
        }
    };

    return (
        <Modal
            isOpen={open}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 10,
                },
                content: {
                    backgroundColor: colors.darkText,
                    borderRadius: spacing[3],
                    borderWidth: 2,
                    borderColor: colors.primary,
                    color: colors.lightText,
                    bottom: "auto",
                    minHeight: "10rem",
                    left: "50%",
                    position: "fixed",
                    right: "auto",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    overflow: "visible",
                    width: "80%",
                },
            }}
        >
            <div css={CONTAINER}>
                <div css={TICKETINFO}>
                    <Image src={ticketImage} styles={TICKETIMAGE} />
                    <div css={TICKETAMOUNT}>
                        <NonTranslatedText size={14} color={colors.yellowText}>
                            {displayTicket}
                        </NonTranslatedText>
                        <Text size={14} styles={POINTSTEXT}>
                            {points}
                        </Text>
                    </div>
                </div>
                <Text size={14}>{participationMessage}</Text>
                <div css={WINNINGINFO}>
                    <Image src={ticketImage} styles={TICKETIMAGE} />
                    <div css={TICKETAMOUNT}>
                        <NonTranslatedText size={14} color={colors.yellowText}>
                            {currentDisplay}
                        </NonTranslatedText>
                    </div>
                    <Image src={leftArrow} styles={ARROW} />
                    <NonTranslatedText size={14} color={sufficient ? colors.blueText : colors.redText}>
                        {remainingText}
                    </NonTranslatedText>
                </div>
                <div css={BUTTONCONTAINER}>
                    <Button label={cancel} styles={BUTTON} onPress={cancelButton} />
                    <Button label={sufficient ? participate : getMore} styles={BUTTON2} onPress={onYes} />
                </div>
            </div>
        </Modal>
    );
};
