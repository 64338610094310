/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { colors, spacing } from "../../config";
import timeRibbon from "../../assets/images/battle_card_ribbon.png";
import onboardingArrow from "../../assets/images/OnbordingBattleTabIndicator.png";
import coinImage from "../../assets/images/info/Coin.png";
import { TicketButton } from "../../components/battle/ticket-button";
import { VictoryRewardBox } from "../../components/battle/victory-reward-box";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { NonTranslatedText } from "../../components";
import { Image } from "../../components/image/image";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "auto",
    height: 150,
    marginTop: spacing[5],
    borderRadius: 12,
    background: "#222222",
    position: "relative",
});

const TIMERIBBON = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundImage: `url(${timeRibbon})`,
    backgroundSize: "cover",
    boxShadow: "0px 3px 0px rgba(0, 0, 0, 0.5)",
    width: 194,
    height: 28,
    marginTop: spacing[3],
    paddingLeft: spacing[2],
    paddingRight: spacing[3],
});

const BOTTOMPANEL = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 66,
    marginBottom: spacing[3],
});

const TIMESTYLE = css({
    color: colors.lightText,
});

const ARROW = css({
    position: "absolute",
    left: "50%",
    width: 38,
    marginLeft: -19,
    top: -60,
});

const TimeRibbon = ({
    startTime,
    endTime,
}: {
    startTime: DateTime;
    endTime: DateTime;
}) => {
    return (
        <div css={TIMERIBBON}>
            <NonTranslatedText styles={TIMESTYLE}>{`${startTime
                .setZone("system")
                .toLocaleString(DateTime.TIME_24_SIMPLE)} - ${endTime
                    .setZone("system")
                    .toLocaleString(DateTime.TIME_24_SIMPLE)}`}</NonTranslatedText>
        </div>
    );
};

export interface OnboardingBattleTicketProps {
    styles?: SerializedStyles;
    hasArrow?: boolean;
}

export const OnboardingBattleTicket = ({
    styles,
    hasArrow = false,
}: OnboardingBattleTicketProps) => {
    const override = css({
        backgroundSize: "cover",
    });

    const history = useHistory();

    const findOpponent = () => {
        history.push("/onboardingmatching");
    };

    return (
        <div css={[CONTAINERSTYLE, styles, override]}>
            {hasArrow ? (
                <Image src={onboardingArrow} alt="" styles={ARROW} />
            ) : (
                    <div></div>
                )}
            <TimeRibbon
                startTime={DateTime.now()}
                endTime={DateTime.now()}
            />
            <div css={BOTTOMPANEL}>
                <VictoryRewardBox winnerReward={500000} winningImage={coinImage} />
                <TicketButton enabled={true} cost={0} onClick={findOpponent} />
            </div>
        </div>
    );
};
