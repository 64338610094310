/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { GameInfoBar } from '../../components/info-bar/game-info-bar';
import { TopRank } from '../../components/leaderboard/top-rank';

const CONTAINERSTYLE = css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
});

const gameName = "Game Name"

export const LeaderboardScreen = () => {

    return (
        <div css={CONTAINERSTYLE}>
            <GameInfoBar title={gameName} />
            <TopRank />
        </div>
    )
};
