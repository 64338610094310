import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";
const auth = getAuth();

type CreateOrderRequest = { id: string };
type CreateOrderResponse = { id: string };

type ApproveOrderRequest = { id: string };
type ApproveOrderResponse = { id: string };

type PaypalCodeRequest = { code: string };
type PaypalCodeResponse = {};

type TransferRequest = { amount: number };
type TransferResponse = {};

type startPurchaseTicketsRequest = { tickets: number };
type startPurchaseTicketsResponse = { order_id: string, redirect_url: string };

type donePurchaseTicketsRequest = { order_id: string };
type donePurchaseTicketsResponse = { is_success: boolean };

const createOrderCall = httpsCallable<CreateOrderRequest, CreateOrderResponse>(functions, 'createOrder');
const approveOrderCall = httpsCallable<ApproveOrderRequest, ApproveOrderResponse>(functions, 'approveOrder');
const paypalCodeCall = httpsCallable<PaypalCodeRequest, PaypalCodeResponse>(functions, 'linkWallet');
const unlinkPaypalCall = httpsCallable(functions, 'unlinkWallet');
const transferMoneyCall = httpsCallable<TransferRequest, TransferResponse>(functions, 'transferMoney');
const startPurchaseTicketsCall = httpsCallable<startPurchaseTicketsRequest, startPurchaseTicketsResponse>(functions, 'startPurchaseTickets');
const donePurchaseTicketsCall = httpsCallable<donePurchaseTicketsRequest, donePurchaseTicketsResponse>(functions, 'donePurchaseTickets');

export const createPaymentOrder = async (id: string): Promise<string> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    const resp = await createOrderCall({ id });
    return resp.data.id;
};

export const approveOrder = async (id: string) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    const resp = await approveOrderCall({ id });
    return resp.data.id;
};

export const paypalLinked = async (code: string) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    await paypalCodeCall({ code });
};

export const unlinkPaypal = async () => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    await unlinkPaypalCall();
};

export const transferCoins = async (amount: number) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    await transferMoneyCall({ amount });
};

export const startPurchaseTickets = async (tickets: number) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    const resp = await startPurchaseTicketsCall({ tickets });
    return resp.data
}

export const donePurchaseTickets = async (order_id: string) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    const resp = await donePurchaseTicketsCall({ order_id });
    return resp.data
}
