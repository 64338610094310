/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from "react";
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router-dom";
import backgroundImage from "../../assets/images/background.png";
import { Screen, NonTranslatedText } from "../../components";
import spotlight from "../../assets/images/SingleSpotlight.png";
import { colors, spacing } from "../../config";
import start from "../../assets/images/battleModes/BattleStart.png";
import three from "../../assets/images/battleModes/BattleCount3.png";
import two from "../../assets/images/battleModes/BattleCount2.png";
import one from "../../assets/images/battleModes/BattleCount1.png";
import { gamesSelector, useStore } from "../../models";
import { Carousel } from "react-responsive-carousel";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Image } from "../../components/image/image";

const SCREENSTYLE = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
});

const SPOTLIGHT = css({
    position: "absolute",
    width: 302,
    height: 541,
    top: -20,
    transitionProperty: "opacity",
    transitionDuration: "1s",
    transitionDelay: "1s",
    opacity: 0,
});

const SWIPE = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    background: colors.darkText,
    alignSelf: "stretch",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: colors.lightText,
    borderStyle: "solid",
});

const SWIPECONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 69,
    background: "#9000B8",
    alignSelf: "stretch",
    marginTop: 90,
});

const COUNTDOWNIMAGE = css({
    position: "absolute",
    top: 100,
    maxHeight: 126,
    maxWidth: 318,
    zIndex: 30,
});

const CAROUSEL = css({
    display: "flex",
    height: 216,
    alignItems: "center",
    justifyItems: "center",
    marginTop: 100,
});

const BANNER = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "auto",
    margin: spacing[2],
});

const BANNERIMAGE = css({
    display: "flex",
    maxWidth: 216,
    transitionProperty: "transform",
    transitionDuration: "1s",
    transform: "scale(0.56)",
});

export interface GameIconProps {
    id?: string;
    name?: string;
    icon?: string;
}

const GameIcon = ({ icon }: GameIconProps) => {
    return (
        <div css={BANNER}>
            <Image styles={BANNERIMAGE} src={icon} />
        </div>
    );
};

let countdownImages = [three, two, one, start];

type GameFinderProps = { id: string };

export const GameFinderScreen = () => {
    const { id } = useParams<GameFinderProps>();
    const games = useStore(gamesSelector);
    const history = useHistory();
    const game = games.find((g) => g.id === id);
    const [checkForIdFlag, setCheckForIdFlag] = useState(false);
    const [autoplay, setautoplay] = useState(true);
    const [selected, setselected] = useState(false);
    const [showCountdown, setshowCountdown] = useState(false);
    const [countdownImageNumber, setcountdownImageNumber] = useState(0);
    const [countdownImageSource, setCountdownImageSource] = useState(start);
    const startCountdown = () => {
        setshowCountdown(true);
    };

    let caurouselItems: EmotionJSX.Element[] = useMemo(() => games.map(game => <GameIcon key={game.id} name={game.name} icon={game.icon} id={game.id} />), [games]);

    useEffect(() => {
        if (countdownImageNumber < 4) {
            setCountdownImageSource(countdownImages[countdownImageNumber]);
        }
    }, [countdownImageNumber]);

    useEffect(() => {
        let countdown = setInterval(() => {
            if (showCountdown) {
                setcountdownImageNumber((prevCount) => prevCount + 1);
            }
        }, 1000);
        return () => {
            clearInterval(countdown);
        };
    }, [showCountdown]);

    useEffect(() => {
        if (countdownImageNumber === 3) {
            setTimeout(() => {
                history.replace(`/play-game/${id}`);
            }, 1000);
        }
    }, [countdownImageNumber, history, id]);

    useEffect(() => {
        let li = document.getElementsByTagName("li");
        let spotlightImage = document.getElementById("spotlightImage");
        let selectedItem = getSelectedItem(li);
        console.log(selectedItem);
        if (selected) {
            setautoplay(false);
            window.setTimeout(() => {
                startCountdown();
            }, 2000);
            if (selectedItem != null && spotlightImage != null) {
                selectedItem.style.transform = "scale(1)";
                spotlightImage.style.opacity = "1";
            }
        }
    }, [selected]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCheckForIdFlag(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, [])

    if (!game) {
        return null;
    }

    const checkForId = (_: any, item: any) => {
        if (checkForIdFlag && item.props.id === id) {
            setselected(true);
        }
    };

    return (
        <Screen backgroundImage={backgroundImage} styles={SCREENSTYLE}>
            <Image src={spotlight} styles={SPOTLIGHT} alt="bg" id="spotlightImage" />
            {showCountdown ? (
                <Image src={countdownImageSource} alt="" styles={COUNTDOWNIMAGE} />
            ) : (
                    <span></span>
                )}
            <div css={CAROUSEL}>
                <Carousel
                    showThumbs={false}
                    autoPlay={autoplay}
                    infiniteLoop={true}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    centerMode={true}
                    autoFocus={false}
                    centerSlidePercentage={60}
                    transitionTime={100}
                    interval={100}
                    swipeable={false}
                    onChange={checkForId}
                >
                    {caurouselItems}
                </Carousel>
            </div>
            <div css={SWIPECONTAINER}>
                <div css={SWIPE}>
                    <NonTranslatedText color={colors.yellowText} size={22}>
                        {showCountdown ? game.name : ""}
                    </NonTranslatedText>
                </div>
            </div>
        </Screen>
    );
};

function getSelectedItem(li: HTMLCollectionOf<HTMLLIElement>) {
    for (let index = 0; index < li.length; index++) {
        const element = li[index];
        if (element.classList.contains("selected")) {
            return element.getElementsByTagName("img")[0];
        }
    }
    return null;
}
