import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

const auth = getAuth();

type WithdrawRequest = {
    beneficiary_name: string,
    beneficiary_account: number,
    beneficiary_bank: string,
    beneficiary_email: string,
    amount: string
};

type WithdrawResponse = { reference_no: string };

const createWithdrawCall = httpsCallable<WithdrawRequest, WithdrawResponse>(functions, 'createWithdraw');

export const createWithdraw = async (data: WithdrawRequest): Promise<WithdrawResponse> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("cannot initiate orders without authentication");
    }

    const resp = await createWithdrawCall(data);
    return resp.data;
};
