/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { spacing } from "../../config";
import { ProfilePhoto } from "../profile-photo/profile-photo";

const CONTAINERSTYLE = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing[3]
});

const CENTERPROFILE = css({
    marginLeft: spacing[3],
    marginRight: spacing[3],
});

export const TopRank = () => {
    return (
        <div css={CONTAINERSTYLE}>
            <ProfilePhoto />
            <ProfilePhoto styles={[CENTERPROFILE]} />
            <ProfilePhoto />
        </div>
    );
};
