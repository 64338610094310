/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { colors, spacing } from "../../config";
import { css } from "@emotion/react";
import Modal from "react-modal";
import { PayPalButtons } from "@paypal/react-paypal-js"
import { approveOrder, createPaymentOrder, startPurchaseTickets } from "../../services/payments";
import { refreshWalletSelector, useStore } from "../../models";
import { LoadingIndicator } from "../loader/loading-indicator";
import { trackEvent } from "../../services";

const CONTAINER = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});

const OVERLAY = css({
    position: "absolute",
    top: 0, right: 0, left: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 1000,
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
});

const MIDTRANS_BUTTON = css({
    backgroundColor: colors.darkText,
    color: colors.lightText,
    border: `1px solid ${colors.lightText}`,
    width: "80%",
    marginTop: 10,
    textAlign: "center",
    padding: "10px 0",
    fontWeight: "bold"
})

type CheckoutModalProps = { show: boolean, setShow: (b: boolean) => void, amount: number, id: string, tickets: number };

export const CheckoutModal = ({ show, setShow, id, tickets, amount }: CheckoutModalProps) => {

    const refreshWallet = useStore(refreshWalletSelector);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            trackEvent("click_shop_item", { amount, id });
        }
    }, [show, amount, id]);

    const onCreateOrder = async () => {
        try {
            trackEvent("begin_checkout", { currency: "USD", value: amount, items: [{ item_id: id, item_name: "shop-ticket" }] });
            return await createPaymentOrder(id);
        }
        catch (ex) {
            console.log(ex);
            return "";
        }
    };

    const onApproveOrder = async (approveId: string) => {
        setLoading(true);
        try {
            await approveOrder(approveId);
            await refreshWallet();
            trackEvent("purchase", {
                currency: "USD",
                value: amount,
                transaction_id: approveId,
                items: [{ item_id: id, item_name: "shop-ticket" }]
            })
            setShow(false);
        } catch (ex) {
            console.log(ex);
        }
        setLoading(false);
    };

    const onClickMidtrans = async () => {
        trackEvent("begin_midtrans", { currency: "USD", value: amount, items: [{ item_id: id, item_name: "shop-ticket" }] });
        setLoading(true);
        try {
            const res = await startPurchaseTickets(tickets)
            window.location.href = res.redirect_url
        } catch (ex) {
            console.log(ex)
        }
        setLoading(false);

    }

    return (
        <Modal isOpen={show} ariaHideApp={false}
            onRequestClose={() => setShow(false)}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 10,
                },
                content: {
                    backgroundColor: colors.darkText,
                    borderRadius: spacing[3],
                    borderWidth: 2,
                    borderColor: colors.primary,
                    color: colors.lightText,
                    bottom: "auto",
                    left: "50%",
                    position: "fixed",
                    right: "auto",
                    top: "50%",
                    maxHeight: "80%",
                    transform: "translate(-50%,-50%)",
                    overflow: "auto",
                    width: "80%",
                },
            }}>
            <div css={CONTAINER}>
                <PayPalButtons createOrder={onCreateOrder} onApprove={(data) => onApproveOrder(data.orderID)} />
                <button css={MIDTRANS_BUTTON} onClick={onClickMidtrans}>Midtrans</button>
                {loading ? <div css={OVERLAY}><LoadingIndicator /></div> : null}
            </div>
        </Modal>
    );
};
